import React, { Fragment, useContext } from 'react'
import { Delete } from '@mui/icons-material'
import { FormControl, Select, InputLabel, FormHelperText, Grid, useMediaQuery, IconButton } from '@mui/material'
import MKCurrencyTextFelidB from '../../../components/MKCurrencyTextFieldB'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const IndividualAccountsSelection = () => {
	const isSm = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'), { defaultMatches: true })
	const { accounts } = useContext(GlobalContext)
	const {
		transactionFormState: state,
		setTransactionFormState: setState,
		removeIndividualAccount
	} = useContext(NewTransactionFormContext)

	/** Updates an individual account's selection and amount when: 'custom' distribution */
	const handleIndividualAccountsChange = (event, index) => {
		const name = event.currentTarget.name
		const value = event.currentTarget.value

		if (name === 'individualAccounts') {
			//Virtual Account drop down changed
			const accountComponents = value.split('__#&&#__')
			setState({
				...state,
				individualAccounts: [
					...state.individualAccounts.slice(0, index),
					{ name: accountComponents[1], amount: state.individualAccounts[index].amount, virtualAccountID: accountComponents[0] },
					...state.individualAccounts.slice(index + 1)
				]
			})
		} else {
			//Amount changed
			setState({
				...state,
				individualAccounts: [
					...state.individualAccounts.slice(0, index),
					{
						name: state.individualAccounts[index].name,
						virtualAccountID: state.individualAccounts[index].virtualAccountID,
						amount: value
					},
					...state.individualAccounts.slice(index + 1)
				]
			})
		}
	}

	const AccountSelection = ({ index }) => {
		return (
			<Grid item sx={{ paddingTop: '20px' }}>
				{/* Delete the Individual Account */}
				<IconButton
					aria-label='delete'
					onClick={() => removeIndividualAccount(index)}
					name='delete'
					sx={{ color: 'text.secondary', paddingTop: '16px', paddingLeft: '0px' }}
					size='large'
				>
					<Delete />
				</IconButton>

				{/* Virtual Accounts drop down */}
				<FormControl
					variant='outlined'
					error={state.errors.individualAccounts ? (state.errors.individualAccounts[index] ? true : false) : false}
				>
					<InputLabel>{'Choose Account'}</InputLabel>
					<Select
						native
						name='individualAccounts'
						value={`${state.individualAccounts[index].virtualAccountID}__#&&#__${state.individualAccounts[index].name}`}
						onChange={(e) => handleIndividualAccountsChange(e, index)}
						label={'Choose Account'}
						inputProps={{ name: 'individualAccounts' }}
					>
						{/* Name of Virtual Accounts */}
						<option value=''></option>
						{accounts.map((physicalAccountArr) => {
							//Show all virtual accounts except for those unassigned
							if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
								return physicalAccountArr.map((account, accountIndex) => {
									if (accountIndex !== 0) {
										return (
											<option value={`${account.virtualAccountID}__#&&#__${account.name}`} key={account.virtualAccountID}>
												{account.name}
											</option>
										)
									} else {
										return (
											<option
												disabled
												value={`${account.physicalAccountID}__#&&#__${account.name}`}
												key={account.physicalAccountID}
											>
												{account.name}
											</option>
										)
									}
								})
							}
							return null //Hide unassigned virtual accounts
						})}
					</Select>

					{/* Shows relevant individual account errors to the user */}
					{state.errors.individualAccounts && state.errors.individualAccounts[index] && (
						<FormHelperText>{state.errors.individualAccounts[index]}</FormHelperText>
					)}
				</FormControl>
			</Grid>
		)
	}

	return (
		<Fragment>
			{state.individualAccounts.map((accountAmount, index) => {
				return (
					<Grid
						container
						direction='row'
						alignItems='flex-end'
						justifyContent='space-between'
						spacing={2}
						key={index}
						sx={{ paddingTop: '5px', paddingBottom: '10px' }}
					>
						<Fragment>
							{/* Account Drop-down */}
							<AccountSelection index={index} />

							{/* Amount Input */}
							<Grid item sx={!isSm ? { paddingLeft: '45px', paddingTop: '5px', paddingBottom: '10px' } : {}}>
								<MKCurrencyTextFelidB
									name='amount'
									max={9999999999} //Max 100 million
									value={state.individualAccounts[index].amount}
									onValueChange={(e) => handleIndividualAccountsChange(e, index)}
									helperText={
										state.errors.individualAccounts
											? state.errors.individualAccounts[`amountAt${index}`]
												? state.errors.individualAccounts[`amountAt${index}`]
												: undefined
											: undefined
									}
									error={
										state.errors.individualAccounts
											? state.errors.individualAccounts.amount || state.errors.individualAccounts[`amountAt${index}`]
												? true
												: false
											: false
									}
								/>
							</Grid>
						</Fragment>
					</Grid>
				)
			})}
		</Fragment>
	)
}
export default IndividualAccountsSelection
