import React, { createContext, useState, useEffect } from 'react'
import { getBudgetOverview as apiGetBudgetOverview } from '../helpers/api'
import { reassignAccount as apiReassignAccount } from '../helpers/api'

export const GlobalContext = createContext()

const GlobalContextProvider = (props) => {
	//Initial state
	const [credentials, setCredentials] = useState({
		firstName: '',
		lastName: '',
		email: '',
		username: ' ', //The space tells us when the site first loads so we will check for a user with or without an account
		userID: '',
		image: '',
		onboarding: true //Both Login and first load to getUserDetails() will overwrite this
	})

	const addCredentials = (item) => {
		setCredentials({ ...credentials, ...item }) //Only overwrites the values that are in item
	}

	const [loggedIn, setLoggedIn] = useState(false) //Is the user logged in
	const [showLogin, setShowLogin] = useState(false) //Shows the login popup
	const [useGA, setUseGA] = useState(true) //Uses Google Analytics or not
	const [siteAlert, setSiteAlert] = useState({
		//Shows a message at the top of the site
		title: 'Title',
		description: 'This is the description for the alert',
		variant: 'standard',
		severity: 'info',
		actionBtn: '',
		handleActionBtn: () => {},
		showMessage: false
	})
	const [loading, setLoadingState] = useState(false) //Shows the loading indicator in the TopBar when loadingCount > 0
	const [loadingCount, setLoadingCount] = useState(0) //Sets the number of components that are loading
	const setLoading = (value) => {
		setLoadingCount((previousLoadingCount) => previousLoadingCount + value) //Adds or subtracts from the loadingCount
	}
	useEffect(() => {
		if (loadingCount > 0) {
			setLoadingState(true)
		} else {
			setLoadingState(false)
		}
	}, [loadingCount])

	// Save all Data in Global contact so that we dont have to re-load it when switching pages in the same session
	// TODO: maybe record a date object too refresh transactions after/get any new transactions after the latest one

	/** Clears all data in Global Context - for when the user logs out */
	const clearContext = () => {
		setCredentials({ firstName: '', lastName: '', email: '', username: ' ', userID: '', image: '', onboarding: true })
		setSiteAlert({
			title: '',
			description: '',
			variant: 'standard',
			severity: 'info',
			actionBtn: '',
			handleActionBtn: () => {},
			showMessage: false
		})
		setLoadingCount(0)
		setAllBudgets({
			months: {},
			quarters: {},
			years: {},
			averages: {},
			whatIfs: {},
			typeRatios: {
				months: {},
				quarters: {},
				years: {},
				averages: {},
				whatIfs: {}
			}
		})
		setRecentTransactions([])
		setAccounts([])
		setIncomeSources({})
	}

	//Local storage for all budget data gathered from Firebase
	const [allBudgets, setAllBudgets] = useState({
		months: {},
		quarters: {},
		years: {},
		averages: {},
		whatIfs: {},
		typeRatios: {
			//Current/Next - based off the income budgeted; Past - based on spent/saved at the end of the timePeriod
			//  TO-DO:  What about for Quarter/Years ??? - maybe after each month add the new %s to it
			months: {}, //Name: {basics: 535, wants: 135, savings: 300}
			quarters: {},
			years: {},
			averages: {},
			whatIfs: {}
		}
	})

	//The budget activity shown to the user from allBudgets
	const [activeBudget, setActiveBudget] = useState({ name: '', type: '', budget: {} })

	//Local storage for Transactions data gathered from Firebase - [ {Transaction}, {Transaction} ]
	const [recentTransactions, setRecentTransactions] = useState([])

	//Local storage for Accounts data gathered from Firebase - [ [index 0: the physical account. followed by it's virtual accounts], [{Phy.Acc.}, {V-Acc.}], [...]]
	const [accounts, setAccounts] = useState([])

	//Local storage for Income Sources gathered from Firebase - { sourceID: { name: String, defaultAccount: String } }
	const [incomeSources, setIncomeSources] = useState({})

	//Control Panel for viewing a budget
	const [budgetViewControlPanel, setBudgetViewControlPanel] = useState({
		sectionProgress: true,
		categoryProgress: true,
		budgetedBW: true,
		spentBW: true,
		surplusDeficitBW: true,
		accountBalanceBW: false,
		budgetedS: true,
		savedS: true,
		spentS: false,
		goalS: true,
		accountBalanceS: true
	})

	/**
	 * Retrieve the budget for the activeView
	 */
	const loadBudget = (activeView) => {
		const today = new Date()
		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1

		switch (activeView) {
			case 'Current':
				return allBudgets.months[`${currentMonth}-${currentYear}`]
					? {
							name: `${currentMonth}-${currentYear}`,
							type: 'Current',
							budget: allBudgets.months[`${currentMonth}-${currentYear}`]
					  }
					: { name: '', type: 'Current', budget: {} }

			case 'Last':
				const lastMonth = currentMonth === 1 ? 12 : (currentMonth % 13) - 1
				const lastYear = lastMonth === 12 ? currentYear - 1 : currentYear
				return allBudgets.months[`${lastMonth}-${lastYear}`]
					? { name: `${lastMonth}-${lastYear}`, type: 'Last', budget: allBudgets.months[`${lastMonth}-${lastYear}`] }
					: { name: '', type: 'Last', budget: {} }

			case 'Next':
				const nextMonth = (currentMonth % 12) + 1
				const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear
				return allBudgets.months[`${nextMonth}-${nextYear}`]
					? { name: `${nextMonth}-${nextYear}`, type: 'Next', budget: allBudgets.months[`${nextMonth}-${nextYear}`] }
					: { name: '', type: 'Next', budget: {} }

			default:
				break
		}
	}

	/**
	 * Check that we have all the data needed to populate Overview,
	 * if not, fetch it from the API and load it into allBudgets
	 */
	const fetchData = async (activeView) => {
		//Get the names of all the budgets required
		const today = new Date()

		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1

		const nextMonth = (currentMonth % 12) + 1
		const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear

		const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1
		const lastYear = lastMonth === 12 ? currentYear - 1 : currentYear

		const past2Month = lastMonth === 1 ? 12 : lastMonth - 1
		const past2Year = currentMonth - 2 <= 0 ? currentYear - 1 : currentYear

		const past3Month = past2Month === 1 ? 12 : past2Month - 1
		const past3Year = currentMonth - 3 <= 0 ? currentYear - 1 : currentYear

		const past4Month = past3Month === 1 ? 12 : past3Month - 1
		const past4Year = currentMonth - 4 <= 0 ? currentYear - 1 : currentYear

		//Check if the user already has the data needed to populate Overview
		if (
			!(
				//The following will still be true it it was already fetched for, but doesn't exist: signified as {}
				(
					allBudgets.months[`${nextMonth}-${nextYear}`] &&
					allBudgets.months[`${currentMonth}-${currentYear}`] &&
					allBudgets.months[`${lastMonth}-${lastYear}`] &&
					allBudgets.typeRatios.months[`${nextMonth}-${nextYear}`] &&
					allBudgets.typeRatios.months[`${currentMonth}-${currentYear}`] &&
					allBudgets.typeRatios.months[`${lastMonth}-${lastYear}`] &&
					allBudgets.typeRatios.months[`${past2Month}-${past2Year}`] &&
					allBudgets.typeRatios.months[`${past3Month}-${past3Year}`] &&
					allBudgets.typeRatios.months[`${past4Month}-${past4Year}`]
				)
			)
		) {
			//If we don't already have all the data to populate the overview: fetch it from the api

			const result = await apiGetBudgetOverview()

			if (!result.errors) {
				//Set the new data from Firestore if it exists; else an empty obj:
				//Signifying that we have already fetched the data and it's not there
				setAllBudgets({
					...allBudgets,
					months: {
						...allBudgets.months,
						[`${lastMonth}-${lastYear}`]: result.data.lastMonth ? result.data.lastMonth : {},
						[`${currentMonth}-${currentYear}`]: result.data.currentMonth ? result.data.currentMonth : {},
						[`${nextMonth}-${nextYear}`]: result.data.nextMonth ? result.data.nextMonth : {}
					},
					typeRatios: {
						...allBudgets.typeRatios,
						months: {
							...allBudgets.typeRatios.months,
							[`${nextMonth}-${nextYear}`]:
								result.data.typeRatios && result.data.typeRatios.nextMonth ? result.data.typeRatios.nextMonth : {},
							[`${currentMonth}-${currentYear}`]:
								result.data.typeRatios && result.data.typeRatios.currentMonth ? result.data.typeRatios.currentMonth : {},
							[`${lastMonth}-${lastYear}`]:
								result.data.typeRatios && result.data.typeRatios.lastMonth ? result.data.typeRatios.lastMonth : {},
							[`${past2Month}-${past2Year}`]:
								result.data.typeRatios && result.data.typeRatios.past2Month ? result.data.typeRatios.past2Month : {},
							[`${past3Month}-${past3Year}`]:
								result.data.typeRatios && result.data.typeRatios.past3Month ? result.data.typeRatios.past3Month : {},
							[`${past4Month}-${past4Year}`]:
								result.data.typeRatios && result.data.typeRatios.past4Month ? result.data.typeRatios.past4Month : {}
						}
					}
				})

				//Returns the values directly when fetched from the API
				switch (activeView) {
					case 'Current':
						return result.data.currentMonth
							? { name: `${currentMonth}-${currentYear}`, type: 'Current', budget: result.data.currentMonth }
							: { name: '', type: 'Current', budget: {} }

					case 'Last':
						return result.data.lastMonth
							? { name: `${lastMonth}-${lastYear}`, type: 'Last', budget: result.data.lastMonth }
							: { name: '', type: 'Last', budget: {} }

					case 'Next':
						return result.data.nextMonth
							? { name: `${nextMonth}-${nextYear}`, type: 'Next', budget: result.data.nextMonth }
							: { name: '', type: 'Next', budget: {} }

					default:
						console.error(`Error fetching data for ${activeView} in GlobalContext`)
						return { name: '', type: '', budget: {} }
				}
			} else {
				console.error('Fetch Errors', result.errors)
			}
		} else {
			return loadBudget(activeView) //Returns the activeBudget according to the activeView with data already fetched (in allBudgets)
		}
	}

	/**
	 * Checks if we have the user's accounts and sources in local state, if not (or if forceUpdate is true) fetches them and
	 * converts the Firestore structured data to a local array and sets accounts state along with incomeSources state
	 */
	const fetchCurrentNextBudgets = async (forceUpdate) => {
		//Get the names of all the budgets required
		const today = new Date()

		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1

		const nextMonth = (currentMonth % 12) + 1
		const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear

		//Check if the user already has the data needed to populate Overview
		if (
			forceUpdate ||
			!(
				//The following will still be true it it was already fetched for, but doesn't exist: signified as {}
				(
					allBudgets.months[`${nextMonth}-${nextYear}`] &&
					allBudgets.months[`${currentMonth}-${currentYear}`] &&
					allBudgets.typeRatios.months[`${nextMonth}-${nextYear}`] &&
					allBudgets.typeRatios.months[`${currentMonth}-${currentYear}`]
				)
			)
		) {
			//If we don't already have all the data to populate the overview: fetch it from the api

			const result = await apiGetBudgetOverview()

			if (!result.errors) {
				//Set the new data from Firestore if it exists; else an empty obj:
				//Signifying that we have already fetched the data and it's not there
				setAllBudgets({
					...allBudgets,
					months: {
						...allBudgets.months,
						[`${currentMonth}-${currentYear}`]: result.data.currentMonth ? result.data.currentMonth : {},
						[`${nextMonth}-${nextYear}`]: result.data.nextMonth ? result.data.nextMonth : {}
					},
					typeRatios: {
						...allBudgets.typeRatios,
						months: {
							...allBudgets.typeRatios.months,
							[`${nextMonth}-${nextYear}`]:
								result.data.typeRatios && result.data.typeRatios.nextMonth ? result.data.typeRatios.nextMonth : {},
							[`${currentMonth}-${currentYear}`]:
								result.data.typeRatios && result.data.typeRatios.currentMonth ? result.data.typeRatios.currentMonth : {}
						}
					}
				})
			} else {
				console.error('Fetch Errors', result.errors)
			}
		}
	}

	/**
	 * Reassigns a virtual account from one physical account to another physical account
	 * Returns any errors if there were any from the api
	 */
	const reassignAccount = async (accountID, from, to) => {
		const result = await apiReassignAccount({ accountID, from, to }) //Reassign the account on the db
		if (!result.errors) {
			//Successfully reassigned in the database
			let removeIndex //Index that the V-Acc. will be removed from
			let addIndex //Index that the V-Acc. will be added to
			let accountToMove //The V-Acc. data

			//Find the to and from accounts in local state
			accounts.every((physicalAccountArr, pIndex) => {
				//For every physical account [] if the physical account is where we're removing from: search its V-Acc.s
				if (physicalAccountArr[0].physicalAccountID === from) {
					//Find the index of the virtual account from the old physical account
					physicalAccountArr.every((account, vIndex) => {
						if (account.virtualAccountID === accountID) {
							//If the V-Acc. is at this index
							removeIndex = { pIndex, vIndex } //Mark it for removal
							accountToMove = { ...account } //Copy the V-Account's data
							return false //Break out of the loop
						}
						return true //Keep searching for the virtual account's index
					})
				} else if (physicalAccountArr[0].physicalAccountID === to) {
					//If the its the P-Acc. where moving it 'to': the newPhysicalAccountID is at this index
					addIndex = pIndex
				}
				if (removeIndex !== undefined && addIndex !== undefined) {
					//Once we have both indexes
					return false //Break out of the loop
				}
				return true //Keep searching for the virtual accounts indexes to add and remove from
			})

			//Remove Virtual account from old Physical account and add it to the new one
			if (addIndex < removeIndex.pIndex) {
				//Add Before and Remove after
				setAccounts([
					...accounts.slice(0, addIndex),
					accounts[addIndex].concat(accountToMove),
					...accounts.slice(addIndex + 1, removeIndex.pIndex),
					[
						...accounts[removeIndex.pIndex].slice(0, removeIndex.vIndex),
						...accounts[removeIndex.pIndex].slice(removeIndex.vIndex + 1)
					],
					...accounts.slice(removeIndex.pIndex + 1)
				])
			} else {
				//Remove Before and Add after
				setAccounts([
					...accounts.slice(0, removeIndex.pIndex),
					[
						...accounts[removeIndex.pIndex].slice(0, removeIndex.vIndex),
						...accounts[removeIndex.pIndex].slice(removeIndex.vIndex + 1)
					],
					...accounts.slice(removeIndex.pIndex + 1, addIndex),
					accounts[addIndex].concat(accountToMove),
					...accounts.slice(addIndex + 1)
				])
			}
		}
		return result //Returns any errors (result.errors) if there were any: else just 'OK'
	}

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<GlobalContext.Provider
			value={{
				loggedIn,
				setLoggedIn,
				credentials,
				addCredentials,
				showLogin,
				setShowLogin,
				loading,
				setLoading,
				useGA,
				setUseGA,
				siteAlert,
				setSiteAlert,
				clearContext,
				allBudgets,
				setAllBudgets,
				activeBudget,
				setActiveBudget,
				fetchData,
				loadBudget,
				budgetViewControlPanel,
				setBudgetViewControlPanel,
				accounts,
				setAccounts,
				recentTransactions,
				setRecentTransactions,
				incomeSources,
				setIncomeSources,
				reassignAccount,
				fetchCurrentNextBudgets
			}}
		>
			{props.children}
		</GlobalContext.Provider>
	)
}

export default GlobalContextProvider
