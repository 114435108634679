import React, { useEffect, useContext, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, useMediaQuery } from '@mui/material'
import BudgetTile from './BudgetTile'
import { ControlPanel } from '../components'
import { BudgetView } from '@mikeint0sh/the-keeton-cloud-components/finance'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { BudgetContext } from '../BudgetContext'

const PREFIX = 'Overview'
const classes = {
	root: `${PREFIX}-root`,
	scoreBtn: `${PREFIX}-scoreBtn`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.scoreBtn}`]: {
		marginTop: 0,
		[breakpoints.down('sm')]: {
			marginTop: 35
		}
	}
}))

const Overview = () => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const isXs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'), { defaultMatches: true })
	const shortenTitle = useMediaQuery(({ breakpoints }) => breakpoints.down('lg'))
	const { fetchData, loadBudget, activeBudget, setActiveBudget, setLoading, budgetViewControlPanel, setBudgetViewControlPanel } =
		useContext(GlobalContext)
	const { activeView, setActiveView, budgetState, setBudgetState } = useContext(BudgetContext) //The budget actively shown to the user from allBudgets
	const [previousControlPanel, setPreviousControlPanel] = useState({
		sectionProgress: true,
		categoryProgress: true,
		budgetedBW: true,
		spentBW: true,
		surplusDeficitBW: true,
		accountBalanceBW: false,
		budgetedS: true,
		savedS: true,
		spentS: false,
		goalS: true,
		accountBalanceS: true
	})

	useEffect(() => {
		window.scrollTo(0, 0) //Ensures page loads at top
	}, [])

	useEffect(() => {
		//Check that we have fetched the needed data, if not, fetch it
		const fetch = async () => {
			//Past view fetches it's data separately
			if (activeView !== 'Past') {
				//Fetch data if it is not loaded
				if (Object.entries(activeBudget.budget).length === 0 && activeBudget.budget.constructor === Object) {
					setLoading(1)
					const result = await fetchData(activeView) //Check if the user has the data for the Overview; if not, fetch it from the api and set the active budget
					setActiveBudget(result)
					setLoading(-1)
				} else {
					setActiveBudget(loadBudget(activeView)) //Set the activeBudget according to the activeView with data already fetched (in allBudgets)
				}
			}
		}

		fetch()
		// eslint-disable-next-line
	}, [activeView])

	/** Automatically adjusts the controls fro the BudgetView when moving between screen sizes */
	useEffect(() => {
		if (!isDesktop) {
			const tempBudgetViewControlPanel = budgetViewControlPanel
			setPreviousControlPanel(tempBudgetViewControlPanel)
			setBudgetViewControlPanel({
				sectionProgress: true,
				categoryProgress: true,
				budgetedBW: false,
				spentBW: false,
				surplusDeficitBW: true,
				accountBalanceBW: !isXs,
				budgetedS: false,
				savedS: true,
				spentS: false,
				goalS: false,
				accountBalanceS: !isXs
			})
		} else {
			setBudgetViewControlPanel(previousControlPanel)
		}
		// eslint-disable-next-line
	}, [isDesktop, isXs])

	/** Gets the long name of the month for each view */
	const getMonth = (month) => {
		const today = new Date()
		switch (month) {
			case 'Current':
				return today.toLocaleString('en-us', { month: 'long' })

			case 'Last':
				return new Date(today.getFullYear(), today.getMonth() - 1, 1).toLocaleString('en-us', { month: 'long' })

			case 'Next':
				return new Date(today.getFullYear(), today.getMonth() + 1, 1).toLocaleString('en-us', { month: 'long' })

			default:
				break
		}
	}

	/** Loads the selected budget into local budgetState and transitioning to BudgetTool */
	const editBudget = () => {
		//Converts activeBudget to local budget state
		setBudgetState({
			...budgetState,
			editing: true,
			mainViewTransition: true
			//showTransitions: false
		})
		setTimeout(() => {
			setBudgetState({
				...budgetState,
				editing: true,
				mainViewTransition: true,
				//showTransitions: false,
				editingTransition: true
			})
		}, 25)
	}

	return (
		<Root className={classes.root}>
			<Grid container spacing={2} justifyContent='center'>
				{/* no gridItem... */}
				<Grid item sx={{ width: '100%' }}>
					{/* xs={12} */}
					<Grid container direction='row' justifyContent='center' alignItems='center'>
						<Grid item xs={6} sm={3} container direction='column'>
							<BudgetTile
								title='Past Budgets'
								active={activeView === 'Past'}
								tab='Past'
								changeActiveView={() => setActiveView('Past')}
							/>
						</Grid>

						<Grid item xs={6} sm={3} container direction='column'>
							<BudgetTile
								title={`${getMonth('Last')}${shortenTitle ? '' : ' Budget'}`}
								active={activeView === 'Last'}
								tab='Last'
								changeActiveView={() => setActiveView('Last')}
							/>
						</Grid>

						<Grid item xs={6} sm={3} container direction='column' className={classes.scoreBtn}>
							<BudgetTile
								title={`${getMonth('Current')}${shortenTitle ? '' : ' Budget'}`}
								active={activeView === 'Current'}
								tab='Current'
								changeActiveView={() => setActiveView('Current')}
							/>
						</Grid>
						<Grid item xs={6} sm={3} container direction='column' className={classes.scoreBtn}>
							<BudgetTile
								title={`${getMonth('Next')}${shortenTitle ? '' : ' Budget'}`}
								active={activeView === 'Next'}
								tab='Next'
								changeActiveView={() => setActiveView('Next')}
							/>
						</Grid>
					</Grid>
				</Grid>
				{activeView !== 'Past' && (
					// marginBottom: '-50px' eliminates odd footer gap possibly caused by the fixed TopBar
					<Grid item sx={{ height: '100%', width: '100%', marginBottom: '-50px' }}>
						<Grid item container direction='row' spacing={2} xs={12} style={{ margin: 0, width: '100%' }}>
							<Grid item style={{ width: isDesktop ? 'calc(100% - 235px)' : '100%', marginRight: 10 }}>
								<BudgetView GlobalContext={GlobalContext} editBudget={editBudget} />
							</Grid>

							<ControlPanel />
						</Grid>
					</Grid>
				)}
				{activeView === 'Past' && <Grid item container spacing={4} justifyContent='center'></Grid>}
				{/* <Grid item xs={12}></Grid>
				<Grid item xs={12} lg={4}></Grid> */}
			</Grid>
		</Root>
	)
}

export default Overview
