import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, Typography, useMediaQuery } from '@mui/material'
import { BudgetToolContext } from '../BudgetToolContext'
import MKCurrencyTextFelidA from '../../../../components/MKCurrencyTextFieldA'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { GA4 } from '../../../../helpers'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'IncomeTile'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	description: `${PREFIX}-description`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		flex: 1,
		height: '100%'
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		padding: 15,
		[breakpoints.down('sm')]: {
			padding: 0
		}
	},
	[`& .${classes.grid}`]: {
		flex: 1
	},
	[`& .${classes.description}`]: {
		textAlign: 'center',
		[breakpoints.up('xl')]: {
			fontSize: 16
		},
		[breakpoints.down('sm')]: {
			paddingBottom: 5
		}
	}
}))

const IncomeTile = () => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { useGA } = useContext(GlobalContext)
	const { incomeState, setIncomeState, budgetState } = useContext(BudgetToolContext)

	/** Updates the state when the text felids or toggleButtons change */
	const handleChange = (event, newChoice) => {
		const value = accounting.unformat(event.currentTarget.value) //Get the integer representation of the amount

		if (newChoice !== null) {
			setIncomeState({
				...incomeState,
				income: value
			})
		}
	}

	const calculateIncomeStillAvailable = () => {
		const overallPercentTotalAmount = Math.round((budgetState.overallTotalPercent / 100) * incomeState.income) //Currency value of: the all categories with the 'total' percent type
		let overallPercentRemainingAmount = Math.round(
			(incomeState.income - budgetState.finiteAmountBudgeted - overallPercentTotalAmount) *
				(budgetState.overallRemainingPercent / 100)
		) //Currency value of: the all categories with the 'remaining' percent type
		if (overallPercentRemainingAmount < 0) {
			//There can't be a negative remaining amount: if it is negative than the budget is already full
			overallPercentRemainingAmount = 0 //Set the remaining amount to 0
		}
		const totalBudgetedAmount = budgetState.finiteAmountBudgeted + overallPercentTotalAmount + overallPercentRemainingAmount //Currency value of: the total of all categories
		const incomeStillAvailable = incomeState.income - totalBudgetedAmount //Currency value of: the total amount still available

		return incomeStillAvailable
	}

	/** Returns the green balanced color and records budget */
	const getBalancedColor = () => {
		//Record each balanced budget
		GA4.event(useGA, 'budget_tool_income', incomeState.income)
		GA4.event(useGA, 'budget_tool_income_type', incomeState.incomeType)
		GA4.event(useGA, 'budget_tool_finiteAmountBudgeted', budgetState.finiteAmountBudgeted)
		GA4.event(useGA, 'budget_tool_overallTotalPercent', budgetState.overallTotalPercent)
		GA4.event(useGA, 'budget_tool_basics', budgetState.basics.length)
		GA4.event(useGA, 'budget_tool_wants', budgetState.wants.length)
		GA4.event(useGA, 'budget_tool_savings', budgetState.savings.length)
		// {
		// 	income: incomeState.income,
		// 	incomeType: incomeState.incomeType,
		// 	finiteAmountBudgeted: budgetState.finiteAmountBudgeted,
		// 	overallTotalPercent: budgetState.overallTotalPercent,
		// 	overallRemainingPercent: budgetState.overallRemainingPercent,
		// 	basics: budgetState.basics.length,
		// 	wants: budgetState.wants.length,
		// 	savings: budgetState.savings.length
		// })

		return 'secondary.main'
	}

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='column' className={classes.grid}>
					<Grid item sx={{ paddingTop: isDesktop ? '15px' : '10px' }}>
						<MKCurrencyTextFelidA
							inputProps={{
								sx: {
									fontSize: {
										xs: '40px',
										md: `${54 - incomeState.income.toString().length * 3.8}px`,
										lg: `${76 - incomeState.income.toString().length * 4.6}px`,
										xl: '58px'
									},
									textAlign: 'center',
									color: calculateIncomeStillAvailable() === 0 ? getBalancedColor() : 'text.primary'
								}
								// style: {
								// 	fontSize: isXl
								// 		? 58
								// 		: isLg
								// 		? 76 - incomeState.income.toString().length * 4.6
								// 		: !isDesktop
								// 		? 40
								// 		: 54 - incomeState.income.toString().length * 3.8,
								// 	textAlign: 'center',
								// 	color: calculateIncomeStillAvailable() === 0 ? getBalancedColor() : palette.text.primary
								// }
							}}
							maskOptions={{ placeholder: '$0.00' }}
							onChange={handleChange}
							value={incomeState.income}
							placeholder='$0.00'
							name='amount'
						/>
						<Typography variant='subtitle2' className={classes.description}>
							Net Monthly Income
						</Typography>
					</Grid>
					<Grid item sx={{ paddingTop: isDesktop ? '50px' : '0px' }}>
						<MKCurrencyTextFelidA
							maskOptions={{ allowNegative: true }}
							inputProps={{
								// style: {
								// 	fontSize: isXl
								// 		? 56
								// 		: isLg
								// 		? 76 - calculateIncomeStillAvailable().toString().length * 4.6
								// 		: !isDesktop
								// 		? 40
								// 		: 52 - calculateIncomeStillAvailable().toString().length * 3.4,
								// 	textAlign: 'center',
								// color:
								// 	calculateIncomeStillAvailable() > 0
								// 		? palette.text.primary
								// 		: calculateIncomeStillAvailable() < 0
								// 		? palette.error.main
								// 		: palette.secondary.main
								// }
								sx: {
									fontSize: {
										xs: '40px',
										md: `${54 - calculateIncomeStillAvailable().toString().length * 3.8}px`,
										lg: `${76 - calculateIncomeStillAvailable().toString().length * 4.6}px`,
										xl: '58px'
									},
									textAlign: 'center',
									color:
										calculateIncomeStillAvailable() > 0
											? 'text.primary'
											: calculateIncomeStillAvailable() < 0
											? 'error.main'
											: 'secondary.main'
								}
							}}
							disabled
							value={calculateIncomeStillAvailable()}
							name='amount'
						/>
						<Typography variant='subtitle2' className={classes.description}>
							Still Available
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</Root>
	)
}

export default IncomeTile
