import React, { useState, useContext, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import {
	Typography,
	Grid,
	useMediaQuery,
	CircularProgress,
	Button,
	FormControl,
	Select,
	InputLabel,
	FormHelperText
} from '@mui/material'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'

const PREFIX = 'AccountTransfer'
const classes = {
	form: `${PREFIX}-form`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`,
	dropDown: `${PREFIX}-dropDown`
}

const FormStyled = styled('form')(() => ({
	[`&.${classes.form}`]: {
		paddingLeft: 15,
		paddingRight: 15
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	},
	[`& .${classes.dropDown}`]: {
		paddingTop: 20,
		paddingBottom: 10
	}
}))

const TypographyErrorStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.error.main,
	textAlign: 'center',
	marginTop: '5px',
	height: 15
}))

const AccountTransfer = ({ close }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { accounts } = useContext(GlobalContext)
	const { accountState } = useContext(AccountsContext)
	const [state, setState] = useState({
		from: '',
		to: '',
		loading: false,
		errors: {}
	})

	useEffect(() => {
		if (accountState.activeAccount.vIndex) {
			//Active account is a Virtual Account
			setState({
				from: accounts[accountState.activeAccount.pIndex][accountState.activeAccount.vIndex].virtualAccountID,
				to: '',
				loading: false,
				errors: {}
			})
		} else {
			//Active account is a Physical Account
			setState({
				from: accounts[accountState.activeAccount.pIndex][0].physicalAccountID,
				to: '',
				loading: false,
				errors: {}
			})
		}
		// eslint-disable-next-line
	}, [])

	/** Closes Popup gracefully */
	const closePopup = () => {
		setTimeout(() => {
			setState({
				from: '',
				to: '',
				loading: false,
				errors: {}
			}) //Set state back to defaults for next time create Transfer is clicked
		}, 200) //After the alert disappearing animation finishes then clear
		//This prevents showing cleared data on the alert as it disappears

		close()
	}

	/** Updates the state when either the name or type felids change */
	const handleChange = (event) => {
		setState({
			...state,
			[event.currentTarget.name]: event.currentTarget.value
		})
	}

	/**
	 * Validates to and from accounts, then sends an api call to create the transfer and move the money if successful
	 * else it will set relevant errors to display to the user
	 */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, errors: {}, loading: true }) //To display spinner
		let result = {}

		if (state.from !== '' && state.to !== '' && state.from !== state.to) {
			// result = await apiUpdatePhysicalAccount({ name: state.name, type: state.type })
		} else {
			let errors = {}
			if (state.from === '') {
				errors = { from: 'Please choose an account' }
			}
			if (state.to === '') {
				errors = { ...errors, to: 'Please choose an account' }
			}
			if (state.from === state.to) {
				errors = { ...errors, to: 'Please choose a different account' }
			}
			result.errors = errors
		}

		if (!result.errors) {
			//Update local state account's balances
			// const newPhysicalAccountID = Object.keys(result.data.data)[0] //Get the new Physical Account's ID
			// //Add New Physical Account to local state
			// setAccounts(
			// 	accounts.concat([[{ physicalAccountID: newPhysicalAccountID, ...result.data.data[Object.keys(result.data.data)[0]] }]])
			// )
			//Then reassign the account After the state is set
			closePopup()
		} else {
			setState({
				...state,
				errors: result.errors, //Set errors in local state
				loading: false //Stop loading spin weal
			})
		}
	}

	function DropDown({ toFrom }) {
		return (
			//To/From account selection
			<div className={classes.dropDown}>
				<FormControl variant='outlined' error={state.errors[toFrom] ? true : false}>
					<InputLabel>{`Transfer ${toFrom === 'to' ? 'To' : 'From'}`}</InputLabel>
					<Select
						native
						name={toFrom}
						value={state[toFrom]}
						onChange={handleChange}
						label={`Transfer ${toFrom} account`}
						inputProps={{ name: toFrom }}
					>
						{/* Title */}
						<option value={''}></option>
						{accountState.activeAccount.vIndex === undefined &&
							accounts.map((physicalAccountArr, i) => {
								//If the physical account is not the current one ie. unChosen:
								if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
									return (
										<option value={physicalAccountArr[0].physicalAccountID} key={i}>
											{physicalAccountArr[0].name}
										</option>
									)
								}
								return null
							})}
						{accountState.activeAccount.vIndex !== undefined &&
							accounts.map((physicalAccountArr, i) => {
								//If the physical account is not the current one ie. unChosen:
								if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
									return physicalAccountArr.map((account, accountIndex) => {
										if (accountIndex !== 0) {
											return (
												<option value={account.virtualAccountID} key={account.virtualAccountID}>
													{account.name}
												</option>
											)
										} else {
											return (
												<option disabled value={account.physicalAccountID} key={account.physicalAccountID}>
													{account.name}
												</option>
											)
										}
									})
								}
								return null
							})}
					</Select>

					{/* Shows relevant To/From errors to the user */}
					{state.errors[toFrom] && <FormHelperText>{state.errors[toFrom]}</FormHelperText>}
				</FormControl>
			</div>
		)
	}

	return (
		<Fragment>
			<FormStyled noValidate onSubmit={handleSubmit} className={classes.form}>
				{/* To/From selections */}
				<DropDown toFrom='from' />
				<DropDown toFrom='to' />

				{/* Shows general unknown errors to the user */}
				{state.errors.general && (
					<TypographyErrorStyled variant='body2' align='center'>
						{state.errors.general}
					</TypographyErrorStyled>
				)}

				<Grid
					container
					direction={isDesktop ? 'row' : 'column'}
					alignItems='center'
					justifyContent='center'
					spacing={1}
					style={{ paddingTop: 30 }}
				>
					<Grid item xs={isDesktop ? 6 : 12} style={{ width: '100%' }} />

					<Grid item xs={isDesktop ? 6 : 12} style={{ width: '100%' }}>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							disabled={state.loading}
							className={classes.submitBtn}
							style={{ fontSize: '16px', color: 'white' }}
						>
							{!state.loading && 'Create Transfer'}
							{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
						</Button>
					</Grid>
				</Grid>
			</FormStyled>
		</Fragment>
	)
}

export default AccountTransfer
