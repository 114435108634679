import React, { useContext } from 'react'
import { FormControl, Select, InputLabel, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const TimeSelection = () => {
	const {
		transactionFormState: state,
		setTransactionFormState: setState,
		handleFormChange
	} = useContext(NewTransactionFormContext)

	/** Changes just the time of the date object in state */
	const handleTimeChange = (type, value) => {
		if (state.date === null) {
			setState({ ...state, errors: { ...state.errors, date: 'Please enter a valid date' } })
			return //Fix the date before allow time to change
		}
		let newDate = new Date(state.date.getTime()) //NewDate === oldDate
		if (type === 'hour') {
			//If AM
			if (newDate.getHours() < 12) {
				newDate.setHours(value === 12 ? 0 : value)
			} else {
				//PM
				newDate.setHours(value === 12 ? 12 : value + 12)
			}
		} else if (type === 'minute') {
			newDate.setMinutes(value)
		} else {
			//AM PM Toggle
			if (value === 'AM' && newDate.getHours() > 11) {
				newDate.setHours(newDate.getHours() - 12) //Switch to AM
			} else if (value === 'PM' && newDate.getHours() < 12) {
				newDate.setHours(newDate.getHours() + 12) //Switch to PM
			}
		}
		newDate.setSeconds(0) //If we're not using the current time than set seconds to 0
		handleFormChange({ currentTarget: { name: 'date', value: newDate } })
	}

	return (
		<Grid item container direction='row' alignItems='flex-end' justifyContent='space-between'>
			{/* Hour Selection */}
			<Grid item>
				<FormControl variant='standard' name='date' error={state.errors.time ? true : false}>
					<InputLabel>Hour</InputLabel>
					<Select
						native
						name='date'
						//If date object is not null convert military time to 12hr else display the current 12hr time
						value={
							state.date && !isNaN(state.date.getTime())
								? state.date.getHours() < 12
									? state.date.getHours() === 0
										? 12
										: state.date.getHours()
									: state.date.getHours() === 12
									? 12
									: state.date.getHours() - 12
								: new Date().getHours() < 12
								? new Date().getHours() === 0
									? 12
									: new Date().getHours()
								: new Date().getHours() === 12
								? 12
								: new Date().getHours() - 12
						}
						onChange={(e) => handleTimeChange('hour', parseInt(e.currentTarget.value))}
						label={'Select Hour'}
						inputProps={{ name: 'Hour' }}
					>
						{[...Array(12)].map((_, index) => {
							return (
								<option value={index + 1} key={index}>
									{index + 1}
								</option>
							)
						})}
					</Select>
				</FormControl>
			</Grid>

			{/* Minute Selection */}
			<Grid item>
				<FormControl variant='standard' name='date' error={state.errors.time ? true : false}>
					<InputLabel>Minute</InputLabel>
					<Select
						native
						name='date'
						//If date is not null than display it's min else the current time
						value={state.date && !isNaN(state.date.getTime()) ? state.date.getMinutes() : new Date().getMinutes()}
						onChange={(e) => handleTimeChange('minute', parseInt(e.currentTarget.value))}
						label={'Select Minute'}
						inputProps={{ name: 'Minute' }}
					>
						{[...Array(60)].map((_, index) => {
							return (
								<option value={index} key={index}>
									{/* Display time properly ie. (9:00 or 3:04) */}
									{`${index}`.length === 1 ? `0${index}` : index}
								</option>
							)
						})}
					</Select>
				</FormControl>
			</Grid>

			{/* AM/PM Toggle */}
			<Grid item sx={{ paddingLeft: '10px' }}>
				<ToggleButtonGroup
					size='small'
					name='date'
					//If date is not null than display it's value else the current time
					value={
						state.date && !isNaN(state.date.getTime())
							? state.date.getHours() < 12
								? 'AM'
								: 'PM'
							: new Date().getHours() < 12
							? 'AM'
							: 'PM'
					}
					exclusive
					onChange={(e) => handleTimeChange('AM/PM', e.currentTarget.value)}
				>
					<ToggleButton name='date' value='AM'>
						AM
					</ToggleButton>
					<ToggleButton name='date' value='PM'>
						PM
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
		</Grid>
	)
}
export default TimeSelection
