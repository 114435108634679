import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Card, useMediaQuery } from '@mui/material'
import { ControlItem } from './'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'ControlPanel'
const classes = {
	gridItem: `${PREFIX}-gridItem`,
	card: `${PREFIX}-card`,
	controlRow: `${PREFIX}-controlRow`
}

const GridStyled = styled(Grid)(() => ({
	[`&.${classes.gridItem}`]: {
		margin: 0
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		paddingLeft: 10,
		paddingRight: 5,
		width: '100%'
	},
	[`& .${classes.controlRow}`]: {
		margin: 0,
		paddingTop: 5,
		paddingBottom: 5
	}
}))

const ControlPanel = () => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { activeBudget } = useContext(GlobalContext)

	return (
		<GridStyled item className={classes.gridItem} sx={isDesktop ? { width: '210px' } : { width: 'calc(100% - 10px)' }}>
			<Card className={classes.card}>
				<Grid
					container
					direction='column'
					alignItems={isDesktop ? 'center' : 'flex-start'}
					style={{ width: '100%', paddingRight: 5, paddingTop: 15, paddingBottom: 15 }}
				>
					<Grid item className={classes.controlRow}>
						<Typography variant='h3'>Controls</Typography>
					</Grid>

					<ControlItem name={['Section', 'Progress']} property='sectionProgress' />
					<ControlItem name={['Category', 'Progress']} property='categoryProgress' />

					<Grid item className={classes.controlRow} style={{ paddingTop: 30 }}>
						<Typography style={{ fontWeight: 300, fontSize: 22 }}>{'Basics & Wants'}</Typography>
					</Grid>

					<ControlItem name={['Budgeted']} property='budgetedBW' />
					<ControlItem name={activeBudget.type === 'Next' ? ['Funded'] : ['Spent']} property='spentBW' />
					<ControlItem name={['Surplus/', 'Deficit']} property='surplusDeficitBW' />
					<ControlItem name={['Account', 'Balance']} property='accountBalanceBW' />

					<Grid item className={classes.controlRow} style={{ paddingTop: 30 }}>
						<Typography style={{ fontWeight: 300, fontSize: 22 }}>{'Savings'}</Typography>
					</Grid>

					<ControlItem name={['Budgeted']} property='budgetedS' />
					<ControlItem name={['Saved']} property='savedS' />
					<ControlItem name={activeBudget.type === 'Next' ? ['Funded'] : ['Spent']} property='spentS' />
					<ControlItem name={['Goal']} property='goalS' />
					<ControlItem name={['Account', 'Balance']} property='accountBalanceS' />
				</Grid>
			</Card>
		</GridStyled>
	)
}

export default ControlPanel
