import React, { useContext, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { AccountSummary } from './'
import { Popup } from '../../../components'
import AccountGraphView from './AccountGraphView'
import AccountTransfer from './AccountTransfer'
import AccountImbalance from './AccountImbalance'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'

const GridStyled = styled(Grid)(() => ({ display: 'flex' }))

const AccountView = () => {
	const { accounts } = useContext(GlobalContext)
	const { accountState, setAccountState } = useContext(AccountsContext)

	useEffect(() => {
		//If there is an account imbalance
		if (accountState.activeAccount.vIndex === undefined && accounts[accountState.activeAccount.pIndex][0].balance !== 0) {
			setAccountState({
				...accountState,
				showAccountImbalancePopup: true //Show Account Imbalance popup to fix imbalance
			})
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Popup
				isOpen={accountState.showAccountPopup}
				close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountPopup: false })}
				title='Account Transfer'
			>
				<AccountTransfer
					close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountPopup: false })}
				/>
			</Popup>

			<Popup
				isOpen={accountState.showAccountImbalancePopup}
				close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountImbalancePopup: false })}
				title='Fix Account Imbalance'
			>
				<AccountImbalance
					close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountImbalancePopup: false })}
				/>
			</Popup>

			<GridStyled item xs={12}>
				<AccountSummary />
			</GridStyled>

			{/* Account imbalance */}

			<GridStyled item xs={12}>
				<AccountGraphView />
			</GridStyled>

			{/* Graph for Account */}
		</Fragment>
	)
}

export default AccountView
