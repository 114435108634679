import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import { TopBar } from './components'
import { LoginPopup } from '../../components'
import { SiteAlert } from '@mikeint0sh/the-keeton-cloud-components/components'
import { GlobalContext } from '../../helpers/GlobalContext'
import { getUserDetails as apiGetUserDetails } from '../../helpers/api'

const PREFIX = 'Minimal'
const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`,
	siteAlert: `${PREFIX}-siteAlert`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		paddingTop: 80, //With large menu on xs and sm
		height: '100%',
		// marginBottom: -18,
		[breakpoints.up('md')]: {
			paddingTop: 64
		}
	},
	[`& .${classes.content}`]: {
		height: '100%'
	},
	[`& .${classes.siteAlert}`]: {
		//Site message fills the width of the content (excluding the sidebar: open or closed)
		width: `100%`,
		display: 'flex',
		position: 'fixed',
		top: 0,
		paddingTop: 64,
		paddingLeft: 10,
		paddingRight: 10,
		flexGrow: 1,
		zIndex: 999
	}
}))

const Minimal = (props) => {
	const { palette, transitions } = useTheme()
	const { credentials, addCredentials, siteAlert, showLogin, setShowLogin } = useContext(GlobalContext)
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	//Runs whenever the component mounts to see if we have the user's details to fill in
	useEffect(() => {
		if (credentials.username === ' ') {
			async function updateCredentials() {
				const result = await apiGetUserDetails() //Second function allows for async function

				if (result.errors && result.errors.status === 401) {
					addCredentials({ username: '' }) //Signifies that we've checked for a user with an account and their isn't one (or not logged in)
				} else {
					addCredentials(result.data)
				}
			}
			updateCredentials() //Called as a function for async
		}
		// eslint-disable-next-line
	}, []) //Empty array: run once on mount

	return (
		<Root className={classes.root}>
			<LoginPopup open={showLogin} handlePopup={() => setShowLogin(false)} />

			<Box
				className={classes.siteAlert}
				sx={{ backgroundColor: siteAlert.showMessage ? palette.menu : 'transparent', paddingTop: isDesktop ? 64 : 95 }}
			>
				<SiteAlert GlobalContext={GlobalContext} />
			</Box>

			<TopBar toggleDarkMode={props.toggleDarkMode} />

			<main
				className={classes.content}
				style={{
					//Adds padding if there is a Site Message shown, extra padding if the message has a description too
					paddingTop: siteAlert.showMessage ? (siteAlert.description !== '' ? 73 : 52.5) : 0,
					transition: transitions.create('all', {
						easing: transitions.easing.sharp,
						duration: transitions.duration.enteringScreen
					})
				}}
			>
				{props.children}
			</main>
		</Root>
	)
}

Minimal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
}

export default Minimal
