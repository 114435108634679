import { typography } from '@mikeint0sh/the-keeton-cloud-components/theme'

export const globalTypography = {
	h1: {
		fontFamily: typography.h1.fontFamily,
		colorDark: typography.h1.colorDark,
		colorLight: typography.h1.colorLight,
		fontWeight: typography.h1.fontWeight,
		fontSize: typography.h1.fontSize,
		letterSpacing: typography.h1.letterSpacing,
		lineHeight: typography.h1.lineHeight
	},
	h2: {
		fontFamily: typography.h2.fontFamily,
		colorDark: typography.h2.colorDark,
		colorLight: typography.h2.colorLight,
		fontWeight: typography.h2.fontWeight,
		fontSize: typography.h2.fontSize,
		letterSpacing: typography.h2.letterSpacing,
		lineHeight: typography.h2.lineHeight
	},
	h3: {
		fontFamily: typography.h3.fontFamily,
		colorDark: typography.h3.colorDark,
		colorLight: typography.h3.colorLight,
		fontWeight: typography.h3.fontWeight,
		fontSize: typography.h3.fontSize,
		letterSpacing: typography.h3.letterSpacing,
		lineHeight: typography.h3.lineHeight
	},
	h4: {
		fontFamily: typography.h4.fontFamily,
		colorDark: typography.h4.colorDark,
		colorLight: typography.h4.colorLight,
		fontWeight: typography.h4.fontWeight,
		fontSize: typography.h4.fontSize,
		letterSpacing: typography.h4.letterSpacing,
		lineHeight: typography.h4.lineHeight
	},
	h5: {
		fontFamily: typography.h5.fontFamily,
		colorDark: typography.h5.colorDark,
		colorLight: typography.h5.colorLight,
		fontWeight: typography.h5.fontWeight,
		fontSize: typography.h5.fontSize,
		letterSpacing: typography.h5.letterSpacing,
		lineHeight: typography.h5.lineHeight
	},
	h6: {
		fontFamily: typography.h6.fontFamily,
		colorDark: typography.h6.colorDark,
		colorLight: typography.h6.colorLight,
		fontWeight: typography.h6.fontWeight,
		fontSize: typography.h6.fontSize,
		letterSpacing: typography.h6.letterSpacing,
		lineHeight: typography.h6.lineHeight
	},
	subtitle1: {
		fontFamily: typography.subtitle1.fontFamily,
		colorDark: typography.subtitle1.colorDark,
		colorLight: typography.subtitle1.colorLight,
		fontWeight: typography.subtitle1.fontWeight,
		fontSize: typography.subtitle1.fontSize,
		letterSpacing: typography.subtitle1.letterSpacing,
		lineHeight: typography.subtitle1.lineHeight
	},
	subtitle2: {
		fontFamily: typography.subtitle2.fontFamily,
		colorDark: typography.subtitle2.colorDark,
		colorLight: typography.subtitle2.colorLight,
		fontWeight: typography.subtitle2.fontWeight,
		fontSize: typography.subtitle2.fontSize,
		letterSpacing: typography.subtitle2.letterSpacing,
		lineHeight: typography.subtitle2.lineHeight
	},
	body1: {
		fontFamily: typography.body1.fontFamily,
		colorDark: typography.body1.colorDark,
		colorLight: typography.body1.colorLight,
		fontWeight: typography.body1.fontWeight,
		fontSize: typography.body1.fontSize,
		letterSpacing: typography.body1.letterSpacing,
		lineHeight: typography.body1.lineHeight
	},
	body2: {
		fontFamily: typography.body2.fontFamily,
		colorDark: typography.body2.colorDark,
		colorLight: typography.body2.colorLight,
		fontWeight: typography.body2.fontWeight,
		fontSize: typography.body2.fontSize,
		letterSpacing: typography.body2.letterSpacing,
		lineHeight: typography.body2.lineHeight
	},
	button: {
		fontFamily: typography.button.fontFamily,
		colorDark: typography.button.colorDark,
		colorLight: typography.button.colorLight,
		fontSize: typography.button.fontSize
	},
	caption: {
		fontFamily: typography.caption.fontFamily,
		colorDark: typography.caption.colorDark,
		colorLight: typography.caption.colorLight,
		fontSize: typography.caption.fontSize,
		letterSpacing: typography.caption.letterSpacing,
		lineHeight: typography.caption.lineHeight
	},
	overline: {
		fontFamily: typography.overline.fontFamily,
		colorDark: typography.overline.colorDark,
		colorLight: typography.overline.colorLight,
		fontSize: typography.overline.fontSize,
		fontWeight: typography.overline.fontWeight,
		letterSpacing: typography.overline.letterSpacing,
		lineHeight: typography.overline.lineHeight,
		textTransform: typography.overline.textTransform
	}
}
