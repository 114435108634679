import React, { createContext, useState } from 'react'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

export const AccountsContext = createContext()

const AccountsContextProvider = (props) => {
	const [accountState, setAccountState] = useState({
		showAccountImbalancePopup: false, //Shows/Hides the Pop-up to settle an account imbalance
		showAccountPopup: false, //Shows/Hides the Add Physical account Pop-up
		popupTriggeredData: undefined, //Contains the V-Acc.'s ID and current location that triggered the pop-up (to also reassign to if applicable)
		activeView: 'Overview', //Overview: all Accounts; AccountView: specific account
		activeAccount: { pIndex: undefined, vIndex: undefined }, //If AccountView is active: will contain the pIndex and vIndex of the Account in 'accounts' that is shown
		accountSummary: { in: undefined, out: undefined }
	})

	const changeActiveView = (activeView, pIndex, vIndex) => {
		if (activeView === 'AccountView') {
			//Switching to Account View
			setAccountState({
				...accountState,
				activeView: 'AccountView',
				activeAccount: { pIndex, vIndex }
			})
		} else {
			//Switching to Overview
			setAccountState({
				...accountState,
				activeView: 'Overview',
				activeAccount: { pIndex: undefined, vIndex: undefined }
			})
		}
	}

	const [chartData, setChartData] = useState({
		series: [
			{
				name: 'Balance',
				data: []
			},
			{
				name: 'Income',
				data: []
			},
			{
				name: 'Expense',
				data: []
			}
		],
		options: {
			chart: {
				id: 'balanceLineChart',
				height: 700,
				type: 'line',
				fontFamily: 'Roboto, Helvetica, Arial',
				background: 'transparent',
				zoom: {
					enabled: true,
					autoScaleYaxis: true
				}

				// 	width: '100%',
				// 	animations: {
				// 		enabled: false
				// 	}
			},
			dataLabels: {
				enabled: false
			},
			colors: ['#10A0FF', '#02E8BB', '#F22838'],
			stroke: {
				curve: 'smooth',
				width: [3, 3, 3]
			},
			theme: {
				mode: 'dark' //   to-do:  tie the theme
			},
			legend: {
				show: true,
				showForSingleSeries: true,
				showForNullSeries: true,
				showForZeroSeries: true,
				position: 'bottom',
				horizontalAlign: 'center',
				fontSize: '14px',
				fontFamily: 'Roboto, Helvetica, Arial',
				fontWeight: 400,
				tooltipHoverFormatter: undefined,
				labels: {
					useSeriesColors: true
				},
				itemMargin: {
					vertical: 10
				}
			},
			tooltip: {
				enabled: true,
				style: {
					fontSize: '12px',
					fontFamily: 'Roboto, Helvetica, Arial'
				},
				x: {
					show: true,
					format: 'MMM d, yy',
					formatter: undefined
				},
				y: {
					formatter: (price) => accounting.format(price),
					title: {
						formatter: (seriesName) => seriesName
					}
				}
			},
			xaxis: {
				type: 'datetime',
				labels: {
					show: true,
					style: {
						fontSize: '12px',
						fontFamily: 'Roboto, Helvetica, Arial',
						fontWeight: 400
					},
					format: 'MMM d, yy'
				},
				tooltip: {
					enabled: false
				}
			},
			yaxis: {
				axisTicks: {
					show: true
				},
				axisBorder: {
					show: true
				},
				title: {
					text: 'Amount',
					style: {
						fontSize: '14px',
						fontWeight: 400,
						fontFamily: 'Roboto, Helvetica, Arial'
					}
				},
				labels: {
					show: true,
					style: {
						fontSize: '12px',
						fontFamily: 'Roboto, Helvetica, Arial',
						fontWeight: 400
					},
					formatter: (price) => accounting.format(price)
				}
			},
			selection: '6M'
		}
	})

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<AccountsContext.Provider
			value={{
				accountState,
				setAccountState,
				changeActiveView,
				chartData,
				setChartData
			}}
		>
			{props.children}
		</AccountsContext.Provider>
	)
}

export default AccountsContextProvider
