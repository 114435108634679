import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../helpers/GlobalContext'
import { getRecentTransactions as apiGetRecentTransactions } from '../../helpers/api'

const useDashboard = () => {
	const navigate = useNavigate()
	const { fetchData, loadBudget, activeBudget, setActiveBudget, setLoading, setSiteAlert, setRecentTransactions } =
		useContext(GlobalContext)

	const handelCreateTransaction = () => {
		navigate('/Transactions?create=true')
	}

	const handleError = (error) => {
		setTimeout(() => {
			setSiteAlert({
				//Shows a message at the top of the site
				title: 'Error Loading Current Budget',
				description: `${error}`,
				variant: 'standard',
				severity: 'error',
				actionBtn: '',
				handleActionBtn: () => {},
				showMessage: true
			})
		}, 500)
	}

	const fetchRecentTransactions = async () => {
		setLoading(1)
		const result = await apiGetRecentTransactions()

		if (result.errors) {
			console.error('Error hooks/useFinance fetchRecentTransactions():', result.errors)
			handleError(result.errors) //TODO: Format error
		} else {
			setRecentTransactions(result.data)
		}
		setLoading(-1)
	}

	const fetchCurrentBudget = async () => {
		//Past view fetches it's data separately
		//Fetch data if it is not loaded
		if (Object.entries(activeBudget.budget).length === 0 && activeBudget.budget.constructor === Object) {
			setLoading(1)
			const result = await fetchData('Current') //Check if the user has the data for the Overview; if not, fetch it from the api and set the active budget
			setActiveBudget(result)
			setLoading(-1)
		} else {
			setActiveBudget(loadBudget('Current')) //Set the activeBudget according to the activeView with data already fetched (in allBudgets)
		}
	}

	/** Runs on first load of the Hook to load budget Overview data and recent transactions */
	useEffect(() => {
		fetchCurrentBudget()
		fetchRecentTransactions()

		// eslint-disable-next-line
	}, [])

	return { handelCreateTransaction }
}

export default useDashboard
