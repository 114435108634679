import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { AddCircleSharp } from '@mui/icons-material'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import { IndividualAccountsSelection } from '../'
import { NewTransactionFormContext } from '../NewTransactionFormContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const GridStyled = styled(Grid)(() => ({
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5
}))

const LeftoverView = () => {
	const { palette } = useTheme()
	const { transactionFormState: state, addIndividualAccount } = useContext(NewTransactionFormContext)

	return (
		<Fragment>
			<GridStyled item container direction='column' alignItems='flex-start' justifyContent='space-between'>
				{/* Title */}
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400, color: 'warning.main' }}>
						Assign Leftover Income:
					</Typography>
				</Grid>

				{/* Amount leftover in AccountName */}
				<Grid container direction='row' spacing={0} alignItems='center' justifyContent='flex-start' sx={{ paddingTop: '10px' }}>
					<Grid item sx={{ paddingRight: '5px' }}>
						<Typography variant='body1' sx={{ fontSize: '18px' }}>
							{accounting.format(state.leftover.amount)}
						</Typography>
					</Grid>

					<Grid item sx={{ paddingRight: '5px' }}>
						<Grid container direction='row' sx={{ flex: 1, paddingLeft: '0px' }}>
							<Typography variant='body1' sx={{ fontSize: '18px', color: palette.text.secondary, paddingRight: '5px' }}>
								{'leftover in'}
							</Typography>
							<Typography variant='body1' sx={{ fontSize: '18px' }}>
								{state.leftover.destination.name}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</GridStyled>

			{/* Select Individual account */}
			<Grid item container sx={{ paddingTop: '0px', paddingBottom: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
				<Grid item>
					<IndividualAccountsSelection />
				</Grid>

				<Grid item sx={{ flex: 1 }} />
			</Grid>

			{/* Add Individual Account */}
			<Grid item sx={{ padding: '0px' }}>
				<IconButton sx={{ paddingLeft: '5px', paddingTop: '10px' }} onClick={addIndividualAccount} size='large'>
					<AddCircleSharp color='primary' />
				</IconButton>
			</Grid>
		</Fragment>
	)
}

export default LeftoverView
