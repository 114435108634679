import React, { useContext, Fragment, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, TextField, Autocomplete } from '@mui/material'
import { ExpenseAccountsDropDown, DateSelection, TimeSelection } from './'
import MKCurrencyTextFelidB from '../../../components/MKCurrencyTextFieldB'
import { NewTransactionFormContext } from './NewTransactionFormContext'
import { searchExpenseNames as apiSearchExpenseNames } from '../../../helpers/api'

const PREFIX = 'ExpenseForm'
const classes = {
	listbox: `${PREFIX}-listbox`,
	option: `${PREFIX}-option`
}
const GridStyled = styled(Grid)(({ theme: { palette } }) => ({
	[`&.${classes.listbox}`]: {
		padding: 0,
		backgroundColor: palette.tile,
		marginBottom: palette.mode === 'dark' ? 10 : 0,
		marginLeft: palette.mode === 'dark' ? 5 : 0,
		marginRight: palette.mode === 'dark' ? 8 : 0,
		boxShadow: `0px 2px 2px 2px rgba(0,0,0,${palette.mode === 'dark' ? 0.2 : 0.0})`
	},
	[`&.${classes.option}`]: {
		// borderBottom: `1px solid ${palette.text.secondary}44`,
		// // Hover
		// '&[data-focus="true"]': {
		// 	backgroundColor: `${palette.text.secondary}`,
		// 	borderColor: 'transparent'
		// },
		// // Selected
		// '&[aria-selected="true"]': {
		// 	backgroundColor: palette.text.secondary,
		// 	borderColor: 'transparent'
		// }
	}
}))

const GridItemStyled = styled(Grid)(() => ({
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5
}))
const TypographyWarningStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.warning.main,
	textAlign: 'center',
	marginTop: '5px'
}))

const ExpenseForm = () => {
	const { transactionFormState: state, handleFormChange } = useContext(NewTransactionFormContext)
	const [nameOptions, setNameOptions] = useState([])

	const handleNameChange = async (event) => {
		handleFormChange(event)
		if (event.currentTarget.value.length !== 0) {
			const searchResults = await apiSearchExpenseNames('searchableIndex', event.currentTarget.value.toLowerCase())
			if (!searchResults.errors) {
				setNameOptions(searchResults.data)
			} else {
				setNameOptions([]) //No matching results
			}
		} else {
			setNameOptions([]) //Name felid === ''
		}
	}

	return (
		<Fragment>
			{/* Name for Expense */}
			<GridStyled item sx={{ paddingBottom: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
				<Autocomplete
					freeSolo
					options={nameOptions} //{top100Films.map((option) => option.title)}
					name='name'
					value={state.name}
					//onChange={(e) => handleNameChange(e)}
					onChange={(e, newValue) => {
						const event = { currentTarget: { name: 'name', value: newValue === null ? '' : newValue } }
						handleNameChange(event)
					}}
					// inputValue={state.name}
					// onInputChange={(e, newVal) => {
					// 	const event = { currentTarget: { name: 'name', value: newVal } }
					// 	handleNameChange(event)
					// }}
					classes={{
						// option: classes.option,
						listbox: classes.listbox
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							variant='standard'
							id='name'
							type='name'
							name='name'
							label='Name of Expense'
							value={state.name}
							onChange={(e) => handleNameChange(e)}
							helperText={state.errors.name}
							error={state.errors.name ? true : false}
							autoComplete='off'
							margin='normal'
							required
							fullWidth
							autoFocus
						/>
					)}
				/>
			</GridStyled>

			{/* Amount of Expense */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Amount:
					</Typography>
				</Grid>
				<Grid item>
					<MKCurrencyTextFelidB
						name='amount'
						max={9999999999} //Max 100 million
						value={state.amount}
						onValueChange={handleFormChange}
						helperText={state.errors.amount}
						error={state.errors.amount ? true : false}
					/>
				</Grid>
			</GridItemStyled>

			{/* Virtual Account to assign expense to */}
			<GridItemStyled
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '25px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Account:
					</Typography>
				</Grid>
				<Grid item>
					<ExpenseAccountsDropDown />
				</Grid>
			</GridItemStyled>

			{/* Date of Expense */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '20px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Date:
					</Typography>
				</Grid>
				<Grid item>
					<DateSelection />
				</Grid>
			</GridItemStyled>

			{/* Time of Expense */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '15px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Time:
					</Typography>
				</Grid>
				<Grid item>
					<TimeSelection />
				</Grid>
			</GridItemStyled>

			{/* Optional Details for Expense */}
			<Grid item sx={{ paddingBottom: '15px', paddingLeft: '5px', paddingRight: '5px' }}>
				<TextField
					variant='standard'
					id='details'
					type='details'
					name='details'
					label='Expense Details (optional)'
					value={state.details}
					onChange={handleFormChange}
					helperText={state.errors.details}
					error={state.errors.details ? true : false}
					autoComplete='off'
					margin='normal'
					fullWidth
				/>
			</Grid>

			{/* Warning Message(s) */}
			{/* Amount Warning */}
			{state.showWarnings && state.errors.amountWarning && (
				<Fragment>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.amountWarning.slice(0, 50)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center' sx={{ paddingBottom: '10px' }}>
						{state.errors.amountWarning.slice(50)}
					</TypographyWarningStyled>
				</Fragment>
			)}

			{/* Time Warning */}
			{state.showWarnings && state.errors.timeWarning && (
				<Fragment>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(0, 49)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(49, 91)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center' sx={{ paddingBottom: '10px' }}>
						{state.errors.timeWarning.slice(91)}
					</TypographyWarningStyled>
				</Fragment>
			)}
		</Fragment>
	)
}

export default ExpenseForm
