import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Button, Grid, Typography, CircularProgress } from '@mui/material'
import { TransactionView, LeftoverView, TransferView } from './'
import { NewTransactionFormContext } from '../NewTransactionFormContext'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'ResultForm'
const classes = {
	item: `${PREFIX}-item`,
	title: `${PREFIX}-title`,
	error: `${PREFIX}-error`,
	warning: `${PREFIX}-warning`,
	leftover: `${PREFIX}-leftover`,
	btnProgress: `${PREFIX}-btnProgress`
}

const FormStyled = styled('form')(({ theme: { palette } }) => ({
	[`& .${classes.item}`]: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '24px',
		color: palette.secondary.main
	},
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center',
		paddingBottom: 15
	},
	[`& .${classes.warning}`]: {
		fontSize: '14px',
		color: palette.warning.main,
		textAlign: 'center',
		paddingBottom: 10
	},
	[`& .${classes.leftover}`]: {
		fontSize: '14px',
		color: palette.success.main,
		textAlign: 'center'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary'
	}
}))

const ResultForm = ({ close }) => {
	const { accounts, recentTransactions, setRecentTransactions } = useContext(GlobalContext)
	const { transactionFormState: state, submitLeftover } = useContext(NewTransactionFormContext)

	/** Either submits the leftover income assignment or closes the transaction window */
	const handleSubmit = (event) => {
		event.preventDefault()
		if (state.leftover === undefined) {
			close()
		} else {
			submitLeftover(accounts, recentTransactions, setRecentTransactions)
		}
	}

	return (
		<FormStyled noValidate onSubmit={(e) => handleSubmit(e)}>
			<Grid container direction='column'>
				{/* Title */}
				<Grid item sx={{ flex: 1 }}>
					<Typography variant='h1' className={classes.title} gutterBottom>
						Transaction Successful
					</Typography>
				</Grid>

				{/* Action Required Message */}
				{(state.leftover !== undefined || (state.transfers !== undefined && Object.keys(state.transfers).length > 0)) && (
					<Fragment>
						<Typography
							variant='body2'
							className={state.leftover !== undefined ? classes.leftover : classes.warning}
							align='center'
						>
							{state.leftover !== undefined
								? "Congratulations! Next Month's Budget is completely filled with money leftover."
								: 'To complete this transaction, please transfer the amounts shown under "Transfers" between your physical accounts.'}
						</Typography>
						{state.leftover !== undefined && (
							<Typography variant='body2' className={classes.warning} align='center'>
								{'Please select where the leftover income should go.'}
							</Typography>
						)}
					</Fragment>
				)}

				{/* Main Transaction */}
				<Grid item container direction='column' alignItems='flex-start' justifyContent='space-between' className={classes.item}>
					<Grid item>
						<Typography variant='h5' sx={{ fontWeight: 400 }}>
							Transaction:
						</Typography>
					</Grid>
					<Grid item sx={{ paddingTop: '10px' }}>
						<TransactionView />
					</Grid>
				</Grid>

				{/* Individual Transaction */}
				{state.individualTransactions && (
					<Grid item container direction='column' alignItems='flex-start' justifyContent='space-between' className={classes.item}>
						<Grid item>
							<Typography variant='h5' sx={{ fontWeight: 400 }}>
								{`${state.distribution === 'fillBudget' ? 'Budget Autofill:' : ''}`}
								{`${state.distribution === 'custom' ? 'Income Distribution:' : ''}`}
							</Typography>
						</Grid>
						{Object.keys(state.individualTransactions).map((key) => {
							return (
								<Grid item sx={{ paddingTop: '10px' }} key={key}>
									<TransactionView id={key} />
								</Grid>
							)
						})}
					</Grid>
				)}

				{/* Transfers Necessary */}
				{state.transfers !== undefined && Object.keys(state.transfers).length > 0 && (
					<Grid item container direction='column' alignItems='flex-start' justifyContent='space-between' className={classes.item}>
						<Grid item>
							<Typography variant='h5' sx={{ fontWeight: 400, color: 'warning.main' }}>
								Real World Transfers Required:
							</Typography>
						</Grid>
						{Object.keys(state.transfers).map((key) => {
							return <TransferView transferID={key} key={key} />
						})}
					</Grid>
				)}

				{/* Leftover Amount Action Necessary */}
				{state.leftover !== undefined && <LeftoverView />}

				{/* Error Message */}
				{(state.errors.general || (state.errors.individualAccounts && state.errors.individualAccounts.amount)) && (
					<Typography variant='body2' className={classes.error} align='center'>
						{state.errors.general ? state.errors.general : state.errors.individualAccounts.amount}
					</Typography>
				)}

				{/* Submit Button */}
				<Grid
					item
					container
					direction='row'
					alignItems='center'
					justifyContent='flex-end'
					sx={{ paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}
				>
					<Grid item sx={{ paddingRight: '10px' }}>
						{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
					</Grid>
					<Grid item>
						<Button
							type='submit'
							variant={state.leftover !== undefined ? 'contained' : 'text'}
							// color={state.leftover !== undefined ? 'primary' : 'default'}
							color={state.leftover !== undefined ? 'primary' : 'inherit'}
							disabled={state.loading}
							sx={state.leftover !== undefined ? { fontSize: '16px', color: 'white' } : { fontSize: '16px' }}
						>
							{state.leftover !== undefined ? 'Assign Leftover Income' : 'Close'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</FormStyled>
	)
}

export default ResultForm
