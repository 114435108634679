import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, TextField } from '@mui/material'
import { AccountsDropDown, DateSelection, TimeSelection } from './'
import MKCurrencyTextFelidB from '../../../components/MKCurrencyTextFieldB'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const GridItemStyled = styled(Grid)(() => ({
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5
}))
const TypographyWarningStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.warning.main,
	textAlign: 'center',
	marginTop: '5px'
}))

const TransferForm = () => {
	const { transactionFormState: state, handleFormChange } = useContext(NewTransactionFormContext)

	return (
		<Fragment>
			{/* Amount of Transfer */}
			<GridItemStyled
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '15px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Amount:
					</Typography>
				</Grid>
				<Grid item>
					<MKCurrencyTextFelidB
						name='amount'
						max={9999999999} //Max 100 million
						value={state.amount}
						onValueChange={handleFormChange}
						helperText={state.errors.amount}
						error={state.errors.amount ? true : false}
					/>
				</Grid>
			</GridItemStyled>

			{/* Virtual Account to transfer amount from */}
			<GridItemStyled
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '25px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						From:
					</Typography>
				</Grid>
				<Grid item>
					<AccountsDropDown sourceDest='source' label='Transfer From' />
				</Grid>
			</GridItemStyled>

			{/* Virtual Account to transfer amount to */}
			<GridItemStyled
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '25px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						To:
					</Typography>
				</Grid>
				<Grid item>
					<AccountsDropDown sourceDest='destination' label='Transfer To' />
				</Grid>
			</GridItemStyled>

			{/* Date of Transfer */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '20px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Date:
					</Typography>
				</Grid>
				<Grid item>
					<DateSelection />
				</Grid>
			</GridItemStyled>

			{/* Time of Transfer */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '15px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Time:
					</Typography>
				</Grid>
				<Grid item>
					<TimeSelection />
				</Grid>
			</GridItemStyled>

			{/* Optional Details for Transfer */}
			<Grid item sx={{ paddingBottom: '15px', paddingLeft: '5px', paddingRight: '5px' }}>
				<TextField
					variant='standard'
					id='details'
					type='details'
					name='details'
					label='Transfer Details (optional)'
					value={state.details}
					onChange={handleFormChange}
					helperText={state.errors.details}
					error={state.errors.details ? true : false}
					autoComplete='off'
					margin='normal'
					fullWidth
				/>
			</Grid>

			{/* Warning Message(s) */}
			{/* Time Warning */}
			{state.showWarnings && state.errors.timeWarning && (
				<Fragment>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(0, 49)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(49, 91)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center' sx={{ paddingBottom: '10px' }}>
						{state.errors.timeWarning.slice(91)}
					</TypographyWarningStyled>
				</Fragment>
			)}
		</Fragment>
	)
}

export default TransferForm
