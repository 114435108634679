import React, { Fragment, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
	Button,
	Dialog,
	DialogContent,
	Card,
	CardContent,
	Grid,
	Typography,
	CircularProgress,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery
} from '@mui/material'
import { ExpenseForm, ResultForm, IncomeForm, TransferForm } from './'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { NewTransactionFormContext } from './NewTransactionFormContext'
import { getAccounts as apiGetAccounts } from '../../../helpers/api'
import { useAccounts } from '@mikeint0sh/the-keeton-cloud-components/finance'

const TypographyTitleStyled = styled(Typography)(() => ({
	textAlign: 'center',
	fontSize: '24px'
}))
const TypographyErrorStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.error.main,
	textAlign: 'center',
	marginTop: '15px'
}))

const NewTransactionForm = ({ open, handleClose }) => {
	const isSm = useMediaQuery(({ breakpoints }) => breakpoints.up('sm'), { defaultMatches: true })
	const { fetchAccounts } = useAccounts('fetchAccounts', apiGetAccounts, GlobalContext)
	const { accounts, incomeSources, setIncomeSources, fetchCurrentNextBudgets, recentTransactions, setRecentTransactions } =
		useContext(GlobalContext)
	const {
		transactionFormState: state,
		setTransactionFormState: setState,
		handleFormChange,
		handleSubmit
	} = useContext(NewTransactionFormContext)

	/** Clears Form once popup closes */
	const onClose = () => {
		fetchAccounts(true, incomeSources, setIncomeSources) //Get the updated account values (and any new income sources)

		setTimeout(() => {
			fetchCurrentNextBudgets(true) //Get the updated Current and Next TimePeriod Budgets
			setState({
				type: 'income',
				source: { id: '', name: '' },
				subCategoryID: '', //Only used for expenses assigned to a subcategory
				destination: { virtualAccountID: '', name: '' },
				name: '',
				details: '',
				amount: 0,
				date: new Date(),
				distribution: 'fillBudget',
				individualAccounts: [{ amount: 0, virtualAccountID: '', name: '' }], //[{amount, virtualAccountID, name}]
				showWarnings: false, //Shown if the user tries to set a date in the future or with an amount of $0.00
				loading: false,
				warning: false,
				showResult: false,
				errors: {}
			}) //Set back to defaults for next time a new transaction is created
		}, 200) //After the alert disappearing animation finishes then clear
		//This prevents different data showing on the alert as it disappears

		handleClose()
	}

	//Sets a the current date on load
	useEffect(() => {
		if (open) {
			//Make sure you have a list of accounts in local state
			fetchAccounts(false, incomeSources, setIncomeSources) //  get an error  //Fetches accounts and incomes to load drop downs with
			setState({
				...state,
				date: new Date()
			})
		}
		// eslint-disable-next-line
	}, [open])

	return (
		<Dialog
			open={open}
			onClose={onClose}
			scroll='paper' //Enables scrolling the content within the view
			PaperProps={{
				sx: {
					backgroundColor: 'transparent',
					borderRadius: '8px' //Replicate the cards radius when scrolling (looks better)
					//boxShadow: 'none', - we do want this shadow for the popup   maybe do this for PopUp as well and delete boarder
				}
			}}
		>
			<DialogContent sx={{ margin: '0px', padding: '0px', minWidth: isSm ? '375px' : '300px' }}>
				<Card>
					<CardContent>
						{state.showResult && <ResultForm close={onClose} />}

						{!state.showResult && (
							<form
								noValidate
								onSubmit={(e) =>
									handleSubmit(e, accounts, incomeSources, setIncomeSources, recentTransactions, setRecentTransactions)
								}
							>
								<Grid container direction='column'>
									{/* Title */}
									<Grid item sx={{ flex: 1 }}>
										<TypographyTitleStyled variant='h1' gutterBottom>
											Add Transaction
										</TypographyTitleStyled>
									</Grid>

									{/* Transfer Type Toggle */}
									<Grid item container direction='row' justifyContent='center' sx={{ padding: '5px' }}>
										<Grid item>
											<ToggleButtonGroup size='small' name='type' value={state.type} exclusive onChange={handleFormChange}>
												<ToggleButton name='type' value='income'>
													Income
												</ToggleButton>
												<ToggleButton name='type' value='expense'>
													Expense
												</ToggleButton>
												<ToggleButton name='type' value='transfer'>
													Transfer
												</ToggleButton>
											</ToggleButtonGroup>
										</Grid>
									</Grid>

									{/* Form Types */}
									{state.type === 'expense' && <ExpenseForm />}
									{state.type === 'income' && <IncomeForm />}
									{state.type === 'transfer' && <TransferForm />}

									{/* Error Message */}
									{state.errors.general && (
										<Fragment>
											<TypographyErrorStyled variant='body2' align='center'>
												{state.errors.general.indexOf('There is not enough money to complete this transfer.') === -1
													? state.errors.general
													: state.errors.general.slice(0, -52)}
											</TypographyErrorStyled>

											{state.errors.general.indexOf('There is not enough money to complete this transfer.') !== -1 && (
												<TypographyErrorStyled variant='body2' align='center'>
													{state.errors.general.slice(-52)}
												</TypographyErrorStyled>
											)}
										</Fragment>
									)}

									{/* Submit Button */}
									<Grid
										item
										container
										direction='row'
										alignItems='center'
										justifyContent='flex-end'
										sx={{ paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}
									>
										<Grid item sx={{ paddingRight: '10px' }}>
											{state.loading && <CircularProgress size={24} />}
										</Grid>

										<Grid item>
											<Button
												type='submit'
												variant='contained'
												color='primary'
												disabled={state.loading}
												//className={classes.submitBtn}
												sx={{ fontSize: '16px', color: 'white' }}
											>
												{!state.showWarnings ? 'Create Transaction' : 'Confirm and Create'}
												{/* no btnProgress do i need one */}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</form>
						)}
					</CardContent>
				</Card>
			</DialogContent>
		</Dialog>
	)
}

export default NewTransactionForm
