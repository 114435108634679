import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
	Typography,
	Grid,
	TextField,
	useMediaQuery,
	CircularProgress,
	Button,
	FormControl,
	Select,
	InputLabel,
	FormHelperText
} from '@mui/material'
import { updatePhysicalAccount as apiUpdatePhysicalAccount } from '../../../helpers/api'
import { AccountsContext } from '../AccountsContext'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { Fragment } from 'react'

const PREFIX = 'AddAccount'
const classes = {
	form: `${PREFIX}-form`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`,
	error: `${PREFIX}-error`
}

const FormStyled = styled('form')(({ theme: { palette } }) => ({
	[`&.${classes.form}`]: {
		paddingLeft: 15,
		paddingRight: 15
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	},
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center',
		marginTop: '5px',
		height: 15
	}
}))

const AddAccount = ({ close }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { reassignAccount, accounts, setAccounts } = useContext(GlobalContext)
	const { accountState } = useContext(AccountsContext)
	const [state, setState] = useState({
		name: '',
		type: '',
		loading: false,
		reassign: false,
		errors: {}
	})

	/** Closes Popup gracefully */
	const closePopup = () => {
		setTimeout(() => {
			setState({
				name: '',
				type: '',
				loading: false,
				reassign: false,
				errors: {}
			}) //Set state back to defaults for next time + add account is clicked
		}, 200) //After the alert disappearing animation finishes then clear
		//This prevents showing cleared data on the alert as it disappears

		close()
	}

	/** Updates the state when either the name or type felids change */
	const handleChange = (event) => {
		setState({
			...state,
			[event.currentTarget.name]: event.currentTarget.value
		})
	}

	/**
	 * Validates name and type, then sends an api call to create the new account and move the virtual account if successful
	 * else it will set relevant errors to display to the user
	 */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, errors: {}, loading: true }) //To display spinner
		let result = {}

		if (state.type !== '' && state.name.trim() !== '') {
			result = await apiUpdatePhysicalAccount({ name: state.name, type: state.type })
		} else {
			let errors = {}
			if (state.type === '') {
				errors = { type: 'Please choose a type for the account.' }
			}
			if (state.name.trim() === '') {
				errors = { ...errors, name: 'Please enter a name for the account.' }
			}
			result.errors = errors
		}

		if (!result.errors) {
			const newPhysicalAccountID = Object.keys(result.data.data)[0] //Get the new Physical Account's ID
			//Add New Physical Account to local state
			setAccounts(
				accounts.concat([[{ physicalAccountID: newPhysicalAccountID, ...result.data.data[Object.keys(result.data.data)[0]] }]])
			)
			//Then reassign the account After the state is set
			setState({ ...state, reassign: true }) //To trigger Reassignment
			//See useEffect() for reassignment
		} else {
			setState({
				...state,
				errors: result.errors, //Set errors in local state
				loading: false //Stop loading spin weal
			})
		}
	}

	//Runs when triggered from handleSubmit to reassign the account
	useEffect(() => {
		if (state.reassign) {
			const reassign = async () => {
				//If the new Phy. Acc. pop-up was triggered by trying to reassign a category:
				if (accountState.popupTriggeredData) {
					//Reassign account, from physical account, to physical account
					const result = await reassignAccount(
						accountState.popupTriggeredData.accountID,
						accountState.popupTriggeredData.from,
						accounts[accounts.length - 1][0].physicalAccountID //Gets the ID of the physical account just added
					)
					if (result.errors) {
						console.error('Errors:', result.errors)

						// do something
					}
				}

				setState({ ...state, reassign: false, loading: false }) //Finish loading and set reassign to false to prevent inf. loop
				closePopup()
			}

			reassign()
		}

		// eslint-disable-next-line
	}, [state])

	return (
		<Fragment>
			<FormStyled noValidate onSubmit={handleSubmit} className={classes.form}>
				<TextField
					variant='standard'
					id='name'
					type='name'
					name='name'
					label='Name for account'
					value={state.name}
					onChange={handleChange}
					helperText={state.errors.name}
					error={state.errors.name ? true : false}
					autoComplete='off'
					margin='normal'
					required
					fullWidth
					autoFocus
				/>

				{/* Type selection */}
				<div sx={{ paddingTop: '20px', paddingBottom: '10px' }}>
					<FormControl variant='outlined' error={state.errors.type ? true : false}>
						<InputLabel id='demo-simple-select-outlined-label'>Account Type</InputLabel>
						<Select
							native
							name='type'
							value={state.type}
							onChange={handleChange}
							label='Type of account'
							inputProps={{
								name: 'type',
								id: 'outlined-age-native-simple'
							}}
						>
							{/* Shows all possible physical account types */}
							<option value=''></option>
							<option value='checking'>Checking</option>
							<option value='savings'>Savings</option>
							<option value='investments'>Investments</option>
							<option value='retirement'>Retirement</option>
						</Select>

						{/* Shows relevant Type errors to the user */}
						{state.errors.type && <FormHelperText>{state.errors.type}</FormHelperText>}
					</FormControl>
				</div>

				{/* Shows general unknown errors to the user */}
				{state.errors.general && (
					<Typography variant='body2' className={classes.error} align='center'>
						{state.errors.general}
					</Typography>
				)}

				<Grid
					container
					direction={isDesktop ? 'row' : 'column'}
					alignItems='center'
					justifyContent='center'
					spacing={1}
					sx={{ paddingTop: '30px' }}
				>
					<Grid item xs={isDesktop ? 6 : 12} sx={{ width: '100%' }} />

					<Grid item xs={isDesktop ? 6 : 12} sx={{ width: '100%' }}>
						<Button
							type='submit'
							fullWidth
							variant='contained'
							color='primary'
							disabled={state.loading}
							className={classes.submitBtn}
							sx={{ fontSize: '16px', color: 'white' }}
						>
							{!state.loading && 'Create Account'}
							{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
						</Button>
					</Grid>
				</Grid>
			</FormStyled>
		</Fragment>
	)
}

export default AddAccount
