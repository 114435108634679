import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Dialog, Typography, Grid, Card, CardContent, IconButton } from '@mui/material'
import { Signup, Login } from './'
import ForgotPassword from './ForgotPassword'

const PREFIX = 'LoginPopup'
const classes = {
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	closeButton: `${PREFIX}-closeButton`
	// border: `${PREFIX}-border`
}

const DialogStyled = styled(Dialog)(({ theme: { breakpoints, palette } }) => ({
	[`& .${classes.card}`]: {
		maxWidth: 400,
		// margin: 10,
		borderRadius: 8,
		transition: '0.3s',
		boxShadow: '5px 15px 40px 2px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0px 20px 50px 3px rgba(0,0,0,0.3)'
		},
		[breakpoints.up('sm')]: {
			minWidth: 400
		}
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '24px',
		marginBottom: 5,
		paddingRight: 15
	},
	[`& .${classes.closeButton}`]: {
		color: palette.text.secondary,
		paddingTop: 20,
		paddingRight: 5,
		paddingLeft: 15,
		height: 10,
		width: 10
	}
	// [`& .${classes.border}`]: {
	// 	padding: 75,
	// 	[breakpoints.down('sm')]: {
	// 		padding: 40,
	// 		paddingBottom: 50
	// 	},
	// 	[breakpoints.down('xs')]: {
	// 		padding: 0,
	// 		paddingBottom: 0
	// 	}
	// }
}))

const LoginPopup = ({ open, handlePopup }) => {
	const [state, setState] = useState({
		email: '',
		password: '',
		rememberMe: false,
		loading: false,
		errors: {},
		signup: false,
		forgotPassword: false
	})

	/** Closes Login popup gracefully */
	const closePopup = () => {
		setTimeout(() => {
			setState({
				email: '',
				password: '',
				rememberMe: false,
				loading: false,
				errors: {},
				signup: false,
				forgotPassword: false
			}) //Set back to defaults for next time login is clicked
		}, 200) //After the alert disappearing animation finishes then clear
		//This prevents different words showing on the alert as it disappears

		handlePopup()
	}

	/** Sends user back to the login view from the forgot password view */
	const handleBack = () => {
		setState({
			...state,
			errors: {},
			signup: false,
			forgotPassword: false
		})
	}

	return (
		<DialogStyled
			open={open}
			onClose={closePopup}
			style={{ height: '85%', borderRadius: '8px' }}
			PaperProps={{
				sx: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					borderRadius: '8px'
				}
			}}
			// PaperProps={{
			// 	style: {
			// 		backgroundColor: 'transparent',
			// 		boxShadow: 'none'
			// 	}
			// }}
		>
			{/* <Grid className={classes.border}> */}
			<Card className={classes.card}>
				<CardContent>
					<Grid container direction='row' spacing={1}>
						{/* Shows back button on Forgot Password View */}
						{state.forgotPassword && (
							<Grid item>
								<IconButton className={classes.closeButton} onClick={handleBack} size='large'>
									<ArrowBackIosIcon />
								</IconButton>
							</Grid>
						)}

						<Grid item style={{ flex: 1 }}>
							<Typography variant='h1' className={classes.title} gutterBottom>
								{state.forgotPassword ? 'Forgot Password' : state.signup ? 'Create Account' : 'Login'}
							</Typography>
						</Grid>
					</Grid>

					<div style={{ paddingLeft: 15, paddingRight: 15 }}>
						{state.signup && !state.forgotPassword && <Signup closePopup={closePopup} />}
						{!state.signup && !state.forgotPassword && <Login state={state} setState={setState} closePopup={handlePopup} />}
						{state.forgotPassword && <ForgotPassword state={state} setState={setState} />}
					</div>
				</CardContent>
			</Card>
			{/* </Grid> */}
		</DialogStyled>
	)
}

export default LoginPopup
