import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Typography,
	Grid
} from '@mui/material'
import { Close } from '@mui/icons-material'

const PREFIX = 'Alert'
const classes = {
	alert: `${PREFIX}-alert`,
	title: `${PREFIX}-title`,
	closeButton: `${PREFIX}-closeButton`
}

const DialogStyled = styled(Dialog)(({ theme: { palette, spacing } }) => ({
	[`& .${classes.alert}`]: {
		backgroundColor: palette.mode === 'dark' ? palette.tile : palette.background
	},
	[`& .${classes.title}`]: {
		margin: 0,
		padding: spacing(2)
	},
	[`& .${classes.closeButton}`]: {
		color: palette.text.secondary,
		padding: 0,
		height: 15,
		width: 15
	}
}))

/** Custom dialog Title with close button built in */
const MyDialogTitle = ({ children, onClose, ...other }) => {
	return (
		<DialogTitle className={classes.title} {...other}>
			<Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={3}>
				<Grid item>
					<Typography variant='h5'>{children}</Typography>
				</Grid>

				{onClose && (
					<Grid item>
						<IconButton aria-label='close' className={classes.closeButton} onClick={onClose} size='large'>
							<Close />
						</IconButton>
					</Grid>
				)}
			</Grid>
		</DialogTitle>
	)
}

/** Custom dialog popup with title, description with carriage returns, X button, close button, and custom action button. */
export const Alert = ({ open, title, description, handleAlert, actionBtn, handleActionBtn }) => {
	return (
		<DialogStyled maxWidth='sm' open={open} onClose={handleAlert} style={{ height: '85%' }}>
			<MyDialogTitle className={classes.alert} onClose={handleAlert}>
				{title}
			</MyDialogTitle>
			<DialogContent className={classes.alert}>
				<DialogContentText>
					{description.split('\n').map((i, key) => {
						return (
							<Fragment key={key}>
								<Typography style={{ paddingBottom: 8 }}>{i}</Typography>
							</Fragment>
						)
					})}
				</DialogContentText>
			</DialogContent>
			<Grid container direction='row' alignItems='center' justifyContent='flex-end' className={classes.alert}>
				<Grid item>
					<DialogActions>
						<Button onClick={handleAlert} autoFocus>
							Close
						</Button>
					</DialogActions>
				</Grid>
				<Grid item>
					{actionBtn && (
						<DialogActions>
							<Button onClick={handleActionBtn}>{actionBtn}</Button>
						</DialogActions>
					)}
				</Grid>
			</Grid>
		</DialogStyled>
	)
}
