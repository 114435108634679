import React, { useContext } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { GlobalContext } from '../helpers/GlobalContext'

const PREFIX = 'FixedSideBar'
const classes = {
	root: `${PREFIX}-root`,
	shiftContentOpen: `${PREFIX}-shiftContentOpen`,
	leftSide: `${PREFIX}-leftSide`,
	rightSide: `${PREFIX}-rightSide`,
	content: `${PREFIX}-content`
}

const Root = styled('div')(({ theme: { transitions } }) => ({
	[`&.${classes.root}`]: {
		minHeight: '100%',
		// width: '100%',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	[`&.${classes.shiftContentOpen}`]: {
		transition: transitions.create('all', {
			easing: transitions.easing.sharp,
			duration: transitions.duration.enteringScreen
		})
	},
	[`& .${classes.leftSide}`]: {
		position: 'fixed',
		top: 0,
		left: 0,
		height: '100%',
		flexGrow: 1,
		overflow: 'auto'
	},
	[`& .${classes.rightSide}`]: {
		position: 'fixed',
		top: 0,
		right: 0,
		height: '100%',
		flexGrow: 1,
		overflow: 'auto'
	},
	[`& .${classes.content}`]: {
		display: 'flex',
		minHeight: '100%',
		// minWidth: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
}))

/** Allows for a finite or percentage width left or right floating sidebars, only shown on desktops, with their own scroll views if needed */
const FixedSideBar = (props) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { transitions } = useTheme()
	const { siteAlert } = useContext(GlobalContext)

	return (
		<Root
			className={clsx(classes.root, {
				[classes.shiftContentOpen]: isDesktop
			})}
			style={isDesktop ? (props.side === 'left' ? { paddingLeft: props.width } : { paddingRight: props.width }) : {}}
			// sx={{ pl: { md: props.side === 'left' ? props.width : 0 }, pr: { md: props.side === 'right' ? props.width : 0 } }}
		>
			{isDesktop && (
				<div
					className={props.side === 'left' ? classes.leftSide : classes.rightSide}
					style={{
						width: props.width,
						//Adds padding if there is a Site Message shown, extra padding if the message has a description too
						// TODO is the needed anymore?
						paddingTop: siteAlert.showMessage ? (siteAlert.description !== '' ? 73 : 52.5) + 45 : 45,
						transition: transitions.create('all', {
							easing: transitions.easing.sharp,
							duration: transitions.duration.enteringScreen
						})
					}}
				>
					{props.children[0]}
				</div>
			)}
			<div className={classes.content}>{props.children.slice(1)}</div>
		</Root>
	)
}

export default FixedSideBar
