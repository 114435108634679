import React from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Card, Grid, Typography, Button, useMediaQuery } from '@mui/material'
import { Footer } from '@mikeint0sh/the-keeton-cloud-components/components'

const PREFIX = 'Home'
const classes = {
	content: `${PREFIX}-content`,
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	cardGrid: `${PREFIX}-cardGrid`,
	cardTypography: `${PREFIX}-cardTypography`,
	btnRoot: `${PREFIX}-btnRoot`,
	flexItem: `${PREFIX}-flexItem`
}

const Root = styled('div')(({ theme: { palette, breakpoints } }) => ({
	[`&.${classes.content}`]: {
		//Place in all minimal layouts
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	[`& .${classes.root}`]: {
		//Place in all minimal layouts
		flex: 1,
		minHeight: '100%',
		paddingTop: 10,
		paddingLeft: 10,
		paddingRight: 10
	},
	[`& .${classes.card}`]: {
		width: 386,
		height: 386,
		padding: 15,
		borderRadius: 30,
		backgroundColor: `${palette.secondary.main}b3`,
		transition: '.3s',
		transitionTimingFunction: 'easeIn',
		boxShadow: '5px 15px 40px 2px rgba(0,0,0,0.4)',
		[breakpoints.down('md')]: {
			width: 286,
			height: 286,
			padding: 10,
			borderRadius: 20
		},
		[breakpoints.down('xs')]: {
			width: 236,
			height: 236,
			padding: 5,
			borderRadius: 20
		},
		'&:hover': {
			width: 400,
			height: 400,
			marginRight: -14,
			backgroundColor: `${palette.secondary.main}aa`,
			boxShadow: '0px 20px 50px 3px rgba(0,0,0,0.35)',
			[breakpoints.down('md')]: {
				width: 300,
				height: 300
			},
			[breakpoints.down('xs')]: {
				width: 250,
				height: 250
			}
		}
	},
	[`& .${classes.cardGrid}`]: {
		width: 386,
		height: 386,
		borderRadius: 30,
		backgroundImage: 'url(images/SquareWeave.jpg)',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		transition: '.3s',
		[breakpoints.down('md')]: {
			width: 286,
			height: 286,
			borderRadius: 20,
			marginBottom: 60
		},
		[breakpoints.down('xs')]: {
			width: 236,
			height: 236,
			borderRadius: 20,
			marginBottom: 5
		},
		transitionTimingFunction: 'easeIn',
		'&:hover': {
			width: 400,
			height: 400,
			marginRight: -7,
			[breakpoints.down('md')]: {
				width: 300,
				height: 300
			},
			[breakpoints.down('xs')]: {
				width: 250,
				height: 250
			}
		}
	},
	[`& .${classes.cardTypography}`]: {
		fontSize: 42,
		color: palette.text.tertiary,
		textAlign: 'center',
		transition: '.1s',
		height: '100%',
		'&:hover': {
			fontSize: 42,

			[breakpoints.down('xs')]: {
				fontSize: 34,
				fontWeight: 400
			}
		},
		[breakpoints.down('xs')]: {
			fontSize: 34,
			fontWeight: 400
		}
	},
	[`& .${classes.btnRoot}`]: {
		border: 0,
		padding: 0,
		margin: 0,
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent'
		}
	},
	[`& .${classes.flexItem}`]: {
		flex: 1
	}
}))

const Home = () => {
	const navigate = useNavigate()
	const isMd = useMediaQuery(({ breakpoints }) => breakpoints.down('lg'), { defaultMatches: true })
	const isSm = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'), { defaultMatches: true })

	const handleBudgetCardClick = () => {
		navigate('/Tools/Budget')
	}

	return (
		<Root className={classes.content}>
			<Grid container direction='column' justifyContent='center' alignItems='center' className={classes.root}>
				<Grid item className={classes.flexItem}></Grid>
				<Grid
					container
					direction={isMd ? 'column' : 'row'}
					justifyContent='flex-start'
					alignItems='center'
					className={classes.root}
				>
					<Grid item style={{ width: '7%', flex: 1 }} />
					<Grid item style={isMd ? { paddingTop: isSm ? 0 : 30 } : { height: '50%' }}>
						<Typography variant='h1' style={{ fontSize: isSm ? 38 : isMd ? 48 : 64, marginBottom: isSm ? 5 : isMd ? 30 : 50 }}>
							Fund the future,
						</Typography>
						<Typography variant='h1' style={{ fontSize: isSm ? 38 : isMd ? 48 : 64 }}>
							don’t pay for the past.
						</Typography>
					</Grid>
					<Grid item className={classes.flexItem}></Grid>
					<Grid item className={classes.cardGrid}>
						<Button onClick={handleBudgetCardClick} className={classes.btnRoot}>
							<b>
								<Card className={classes.card}>
									<Typography variant='h1' className={classes.cardTypography}>
										<Grid
											container
											direction='column'
											justifyContent='space-around'
											spacing={2}
											style={{ height: '100%', zIndex: 0 }}
										>
											<Grid item className={classes.flexItem}></Grid>
											<Grid item style={{ margin: 0 }}>
												Create a
											</Grid>
											<Grid item>Budget</Grid>
											<Grid item>Today</Grid>
											<Grid item className={classes.flexItem}></Grid>
										</Grid>
									</Typography>
								</Card>
							</b>
						</Button>
					</Grid>
					<Grid item style={{ width: '7%', flex: 1 }} />
				</Grid>
				<Grid item className={classes.flexItem}></Grid>
			</Grid>

			{/* <div className={classes.root}>
				<Card style={{ width: 400, height: 400 }}></Card>
			</div> */}
			<Footer style={{ zIndex: 100 }} />
		</Root>
	)
}

export default Home
