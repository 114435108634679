import React from 'react'
import { styled } from '@mui/material/styles'
import { NavLink as RouterLink } from 'react-router-dom'
import { keyframes } from '@mui/system'
import { Grid, Button } from '@mui/material'

const PREFIX = 'ComingSoon'
const classes = {
	root: `${PREFIX}-root`,
	comingSoon: `${PREFIX}-comingSoon`
}

const gradientFade = keyframes`
0% {
	background-position: 0% 50%
}
50% {
	background-position: 100% 50%
}
100% {
	background-position: 0% 50%
}
`

const Root = styled('div')(({ theme: { palette, breakpoints } }) => ({
	[`&.${classes.root}`]: {
		height: '100%',
		background: `linear-gradient(-45deg, #02E8BB, #10A0FF, #F22822)`,
		backgroundSize: '400%',
		animation: `${gradientFade} 15s ease infinite`,
		[breakpoints.down('md')]: {
			animation: `${gradientFade} 12s ease infinite`
		}
	},
	[`& .${classes.comingSoon}`]: {
		height: '100%',
		backgroundImage: `${palette.mode === 'dark' ? 'url(/images/comingSoonDark.svg)' : 'url(/images/comingSoonLight.svg)'}`,
		backgroundRepeat: 'no-repeat',
		overflow: 'hidden',
		backgroundSize: 'cover',
		backgroundPosition: '50% 50%',
		[breakpoints.down('md')]: {
			backgroundImage: `${
				palette.mode === 'dark' ? 'url(/images/comingSoonDarkMobile.svg)' : 'url(/images/comingSoonLightMobile.svg)'
			}`
		}
	}
}))

const ComingSoon = () => {
	return (
		<Root className={classes.root}>
			<div className={classes.comingSoon}>
				<Grid container direction='column' justifyContent='flex-end' alignItems='center' style={{ height: '80%' }}>
					<Grid container direction='column' justifyContent='flex-end' alignItems='center'>
						<Button
							variant='contained'
							color='primary'
							component={RouterLink}
							to={'/'}
							style={{
								fontSize: '16px',
								fontWeight: 400,
								marginTop: 20,
								color: 'white'
							}}
						>
							Return Home
						</Button>
					</Grid>
				</Grid>
			</div>
		</Root>
	)
}

export default ComingSoon
