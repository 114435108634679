import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { AddCircleSharp } from '@mui/icons-material'
import { Typography, Grid, TextField, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { DateSelection, TimeSelection, IncomeSourceSelection, IndividualAccountsSelection, AccountsDropDown } from './'
import MKCurrencyTextFelidB from '../../../components/MKCurrencyTextFieldB'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const GridItemStyled = styled(Grid)(() => ({
	paddingTop: 10,
	paddingBottom: 10,
	paddingLeft: 5,
	paddingRight: 5
}))
const TypographyWarningStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.warning.main,
	textAlign: 'center',
	marginTop: '5px'
}))
const TypographyErrorStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.error.main,
	textAlign: 'center',
	marginTop: '15px'
}))

const IncomeForm = () => {
	const { transactionFormState: state, handleFormChange, addIndividualAccount } = useContext(NewTransactionFormContext)

	return (
		<Fragment>
			{/* Source of Income */}
			<GridItemStyled item container direction='row' alignItems='flex-start' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '10px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Source:
					</Typography>
				</Grid>
				<Grid item>
					<IncomeSourceSelection />
				</Grid>
			</GridItemStyled>

			{/* Amount of Income */}
			<Grid
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '10px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Amount:
					</Typography>
				</Grid>
				<Grid item>
					<MKCurrencyTextFelidB
						name='amount'
						max={9999999999} //Max 100 million
						value={state.amount}
						onValueChange={handleFormChange}
						helperText={state.errors.amount}
						error={state.errors.amount ? true : false}
					/>
				</Grid>
			</Grid>

			{/* Physical Account the income went into */}
			<GridItemStyled
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				sx={{ paddingTop: '25px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Account:
					</Typography>
				</Grid>
				<Grid item>
					<AccountsDropDown sourceDest='destination' label='Deposited to' type='physical' />
				</Grid>
			</GridItemStyled>

			{/* Income distribution Toggle */}
			<Grid
				item
				container
				direction={'row'} // {isSm ? 'row' : 'column'}
				alignItems={'center'} // {isSm ? 'center' : 'flex-start'}
				justifyContent='space-between'
				sx={{ paddingTop: '10px', paddingBottom: '0px', paddingLeft: '5px', paddingRight: '5px' }}
			>
				<Grid item>
					<Typography variant='h5' sx={{ paddingTop: '10px', fontWeight: 400, textAlign: 'left' }}>
						Distribution:
					</Typography>
				</Grid>
				<Grid item sx={{ paddingTop: '10px', paddingLeft: '10px' }}>
					{/* {isSm ? {} : { paddingTop: 10 }}> */}
					<ToggleButtonGroup size='small' name='distribution' value={state.distribution} exclusive onChange={handleFormChange}>
						<ToggleButton name='distribution' value='fillBudget' sx={{ padding: '6px', minWidth: '85px' }}>
							Fill Budget
						</ToggleButton>
						<ToggleButton name='distribution' value='custom' sx={{ padding: '6px' }}>
							Custom
						</ToggleButton>
					</ToggleButtonGroup>

					{/* Add another account to distribute funds too */}
					{state.distribution === 'custom' && (
						<IconButton sx={{ paddingLeft: '10px', paddingTop: '11px' }} onClick={addIndividualAccount} size='large'>
							<AddCircleSharp color='primary' />
						</IconButton>
					)}
				</Grid>
			</Grid>

			{/* Individual Accounts Selection */}
			{state.distribution === 'custom' && state.individualAccounts.length !== 0 && (
				<GridItemStyled item>
					<IndividualAccountsSelection />
				</GridItemStyled>
			)}

			{/* Total Amounts don't add up to income or no individualAccounts are added*/}
			{state.distribution === 'custom' && state.errors.individualAccounts && state.errors.individualAccounts.amount && (
				<Fragment>
					<TypographyErrorStyled variant='body2' align='center'>
						{state.errors.individualAccounts.amount}
					</TypographyErrorStyled>
				</Fragment>
			)}

			{/* Date of Expense */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '20px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Date:
					</Typography>
				</Grid>
				<Grid item>
					<DateSelection />
				</Grid>
			</GridItemStyled>

			{/* Time of Expense */}
			<GridItemStyled item container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sx={{ paddingTop: '15px' }}>
					<Typography variant='h5' sx={{ fontWeight: 400 }}>
						Time:
					</Typography>
				</Grid>
				<Grid item>
					<TimeSelection />
				</Grid>
			</GridItemStyled>

			{/* Optional Details for the Income */}
			<Grid item sx={{ paddingBottom: '15px', paddingLeft: '5px', paddingRight: '5px' }}>
				<TextField
					variant='standard'
					id='details'
					type='details'
					name='details'
					label='Memo (optional)'
					value={state.details}
					onChange={handleFormChange}
					helperText={state.errors.details}
					error={state.errors.details !== undefined}
					autoComplete='off'
					margin='normal'
					fullWidth
				/>
			</Grid>

			{/* Warning Message(s) */}
			{/* Time Warning */}
			{state.showWarnings && state.errors.timeWarning && (
				<Fragment>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(0, 49)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center'>
						{state.errors.timeWarning.slice(49, 91)}
					</TypographyWarningStyled>
					<TypographyWarningStyled variant='body2' align='center' sx={{ paddingBottom: '10px' }}>
						{state.errors.timeWarning.slice(91)}
					</TypographyWarningStyled>
				</Fragment>
			)}
		</Fragment>
	)
}

export default IncomeForm
