import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, CircularProgress } from '@mui/material'
import AnimatedProgressProvider from './AnimatedProgressProvider'
import { Circle } from 'rc-progress'
import { easeCubicInOut } from 'd3-ease'
import clsx from 'clsx'

const PREFIX = 'ProgressCircle'
const classes = {
	back: `${PREFIX}-back`,
	title1: `${PREFIX}-title1`,
	title2: `${PREFIX}-title2`,
	title3: `${PREFIX}-title3`
}

const Root = styled('div')(({ theme: { palette, breakpoints } }) => ({
	[`& .${classes.back}`]: {
		zIndex: 2
	},
	[`& .${classes.title1}`]: {
		//Title font when it has 1 digits
		color: palette.text.secondary,
		position: 'relative',
		marginLeft: '0%',
		marginTop: '-64%',
		zIndex: 3,
		[breakpoints.down('lg')]: {
			marginTop: '-73%'
		},
		[breakpoints.down('md')]: {
			marginLeft: '0%',
			marginTop: '-76%'
		},
		[breakpoints.down('sm')]: {
			marginLeft: '0%',
			marginTop: '-73%'
		},
		[breakpoints.down('xs')]: {
			marginLeft: '0%',
			marginTop: '-69%'
		}
	},
	[`& .${classes.title2}`]: {
		//Title font when it has 2 digits
		color: palette.text.secondary,
		position: 'relative',
		marginLeft: '0%',
		marginTop: '-64%',
		marginBottom: '47%',
		zIndex: 3,
		[breakpoints.down('lg')]: {
			marginTop: '-73%',
			marginLeft: '0%',
			marginBottom: '42%'
		},
		[breakpoints.down('md')]: {
			marginTop: '-76%',
			marginLeft: '0%',
			marginBottom: '42%'
		},
		[breakpoints.down('sm')]: {
			marginLeft: '0%',
			marginTop: '-73%',
			marginBottom: '41%'
		},
		[breakpoints.down('xs')]: {
			marginLeft: '0%',
			marginTop: '-69%',
			marginBottom: '46%'
		}
	},
	[`& .${classes.title3}`]: {
		//Title font when it has 3 digits
		color: palette.text.secondary,
		position: 'relative',
		marginLeft: '0%',
		marginTop: '-64%',
		zIndex: 3,
		[breakpoints.down('lg')]: {
			marginLeft: '0%',
			marginTop: '-73%'
		},
		[breakpoints.down('md')]: {
			marginLeft: '0%',
			marginTop: '-76%'
		},
		[breakpoints.down('sm')]: {
			marginLeft: '0%',
			marginTop: '-73%'
		},
		[breakpoints.down('xs')]: {
			marginLeft: '0%',
			marginTop: '-69%'
		}
	}
}))

const ProgressCircle = (props) => {
	return (
		<AnimatedProgressProvider
			valueStart={0}
			valueEnd={props.percent === -1 ? 0 : props.percent}
			duration={props.duration}
			easingFunction={easeCubicInOut}
		>
			{(value) => {
				const roundedValue = Math.round(value)
				let titleStyle

				if (roundedValue < 10) {
					titleStyle = 1
				} else if (roundedValue < 100) {
					titleStyle = 2
				} else {
					titleStyle = 3
				}

				return (
					<Root className={props.rootClass}>
						<div className={classes.back}>
							<Circle
								strokeWidth={props.strokeWidth}
								trailColor={props.trailColor}
								percent={value}
								strokeLinecap='round'
								strokeColor={props.strokeColor}
								trailWidth={props.trailWidth}
							/>
						</div>
						<div
							className={clsx({
								[classes.title1]: titleStyle === 1,
								[classes.title2]: titleStyle === 2,
								[classes.title3]: titleStyle === 3
							})}
						>
							<Typography variant='h2' className={props.textClass}>
								<Fragment>
									{roundedValue === 0 && props.percent !== -1 && (
										<CircularProgress size={22} sx={{ color: 'text.primary', marginLeft: 3.5, marginBottom: 5 }} />
									)}
									{roundedValue === 0 && props.percent === -1 && '0'}
									{roundedValue !== 0 && roundedValue}
								</Fragment>
							</Typography>
						</div>
					</Root>
				)
			}}
		</AnimatedProgressProvider>
	)
}

export default ProgressCircle
