import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, Typography, Button, CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Alert } from '../../../../components'
import { GA4 } from '../../../../helpers'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { BudgetToolContext } from '../BudgetToolContext'
import { BudgetContext } from '../../../Budget/BudgetContext'

const PREFIX = 'InfoTile'
const classes = {
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	root: `${PREFIX}-root`,
	description: `${PREFIX}-description`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`& .${classes.card}`]: {
		flex: 1,
		display: 'flex',
		minHeight: '100%',
		paddingTop: 20,
		paddingBottom: 20,
		paddingLeft: 15,
		paddingRight: 15,
		[breakpoints.up('lg')]: {
			padding: 20
		},
		[breakpoints.up('xl')]: {
			padding: 25
		}
	},
	[`& .${classes.grid}`]: {
		flex: 1,
		minHeight: '100%'
	},
	[`&.${classes.root}`]: {
		flex: 1,
		display: 'flex',
		minHeight: '100%',
		paddingBottom: 38
	},
	[`& .${classes.description}`]: {
		[breakpoints.down('md')]: {
			fontSize: 15
		}
	}
}))

const InfoTile = ({ console: webConsole }) => {
	const { activeBudget, allBudgets, setAllBudgets, useGA } = useContext(GlobalContext)
	const budgetContext = useContext(BudgetContext)
	const { budgetState, setBudgetState, state, setState, saveBudget } = useContext(BudgetToolContext)

	const infoMessage = `This will clear any changes you have already made to the budget. Are you sure you wish to continue?`

	const handleAlert = (show) => {
		setState({ ...state, showWarning: show })
	}
	const isEqual = (...objects) => objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]))

	/** Clears or loads the budget template */
	const clearBudget = () => {
		let newBudgetState
		const blankBudgetState = {
			finiteAmountBudgeted: 0,
			overallTotalPercent: 0,
			overallRemainingPercent: 0,
			nextPriority: 4,

			basics: [
				{
					name: '',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: []
				}
			],
			wants: [
				{
					name: '',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: []
				}
			],
			savings: [
				{
					name: '',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 2,
					goal: 0
				}
			]
		}
		const templateBudgetState = {
			finiteAmountBudgeted: 0,
			overallTotalPercent: 0,
			overallRemainingPercent: 0,
			nextPriority: 4,

			basics: [
				{
					name: 'Rent',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Utilities',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [
						['', 'Power'],
						['', 'Water'],
						['', 'Internet']
					],
					showToolBar: false
				},
				{
					name: 'Groceries',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Phone Service',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Gas',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Car Insurance',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 1,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Giving',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'percent',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 0,
					subCategories: [],
					showToolBar: false
				}
			],
			wants: [
				{
					name: 'Restaurants',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Shopping',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Entertainment',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: [],
					showToolBar: false
				},
				{
					name: 'Subscriptions',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: [
						['', 'Netflix'],
						['', 'Spotify'],
						['', 'Amazon Prime']
					],
					showToolBar: false
				},
				{
					name: 'Gifts',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 3,
					subCategories: [],
					showToolBar: false
				}
			],
			savings: [
				{
					name: 'Emergency Fund',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'percent',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 0,
					goal: 0,
					showToolBar: false
				},
				{
					name: 'New Car',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 2,
					goal: 0,
					showToolBar: false
				},
				{
					name: 'Vacation Fund',
					accountID: '',
					spent: 0,
					accountBalance: 0,
					amountType: 'finite',
					amount: 0,
					amountFunded: 0,
					percentType: 'total',
					percentage: 0,
					priority: 2,
					goal: 0,
					showToolBar: false
				}
			]
		}

		if (state.useTemplate) {
			if (state.templateWarning) {
				//Warning that this will clear the budget has not yet been shown and it HAS been edited
				setState({ ...state, showWarning: true, templateWarning: false })
				return //Dont continue to clear the budget
			}

			//Clear budget and load a blank budget
			newBudgetState = blankBudgetState
		} else {
			if (state.templateWarning && !isEqual(budgetState, blankBudgetState)) {
				//Warning that this will clear the budget has not yet been shown and it HAS been edited
				setState({ ...state, showWarning: true, templateWarning: false })
				return //Dont continue to clear the budget
			}

			//Clear budget and load the template
			newBudgetState = templateBudgetState
			GA4.event(useGA, 'budget_tool_use_template')
		}
		if (state.showWarning) {
			setState({ ...state, showWarning: false })
		}
		setBudgetState(newBudgetState) //Clear and set the new budget
		setTimeout(() => {
			setState({ ...state, showWarning: false, useTemplate: !state.useTemplate }) //Toggle between 'Clear' and 'Load Template'
		}, 120) //After the alert disappearing animation finishes toggle useTemplate
		//This prevents different words showing on the alert as it disappears
	}

	/**
	 * Checks for a balanced budget, presents any errors, saves the budget to the database and in allBudgets locally,
	 * and triggers onboarding transition (if applicable)
	 *
	 * ToDo: TypeRatio and Tools version
	 */
	const handleSaveBudget = async () => {
		const budgetName = budgetContext.budgetState.onboarding
			? `${parseInt(new Date().getMonth()) + 1}-${new Date().getFullYear()}`
			: activeBudget.name
		const budgetType = budgetContext.budgetState.onboarding ? 'current' : activeBudget.type.toLowerCase()
		let result = await saveBudget(budgetName, budgetType, budgetContext.budgetState.onboarding) //If Onboarding is true/false
		if (!result.errors) {
			//Adds back the spent, amountFunded, accountBalance properties
			Object.keys(result.data).forEach((key) => {
				if (key !== 'date') {
					if (!result.data[key].type) {
						//Then the category was deleted so remove it from here as well
						delete result.data[key]
					} else {
						budgetState[result.data[key].type].every((oldCategory) => {
							if (oldCategory.accountID === key) {
								result = {
									data: {
										...result.data,
										[key]: {
											...result.data[key],
											spent: oldCategory.spent,
											accountBalance: oldCategory.accountBalance,
											amountFunded: oldCategory.amountFunded
										}
									}
								}
								if (result.data[key].type !== 'savings') {
									Object.keys(result.data[key].subcategories).forEach((subKey) => {
										if (!result.data[key].subcategories[subKey].name) {
											//Then the category was deleted so remove it form here as well
											delete result.data[key].subcategories[subKey]
										} else {
											oldCategory.subCategories.every((oldSubCategory) => {
												if (oldSubCategory[0] === subKey) {
													result = {
														data: {
															...result.data,
															[key]: {
																...result.data[key],
																subcategories: {
																	...result.data[key].subcategories,
																	[subKey]: {
																		...result.data[key].subcategories[subKey],
																		spent: oldSubCategory[2]
																	}
																}
															}
														}
													}
													return false //Break out of the loop
												}
												return true //Continue searching for oldSubCategory
											})
										}
									})
								}
								return false //Break out of the loop
							}
							return true //Continue searching for oldCategory
						})
					}
				}
			})

			setAllBudgets({
				...allBudgets,
				months: {
					...allBudgets.months,
					[budgetName]: result.data
				}
				//  what about typeRatio too ???
			})

			if (webConsole) {
				setState({ ...state, saving: false, errors: {} })
				if (budgetContext.budgetState.onboarding) {
					budgetContext.setBudgetState({
						...budgetContext.budgetState,
						onboarding: false
					})
					setTimeout(() => {
						budgetContext.setBudgetState({
							...budgetContext.budgetState,
							onboarding: false,
							onboardingTransition: false //Stop onboarding and switch to the budget console normal view
						})
					}, 350) //After animation finishes
				} else {
					//Done Editing in EditBudgetView
					budgetContext.setBudgetState({
						...budgetContext.budgetState,
						editingTransition: false,
						showTransitions: false,
						mainViewTransition: false
					})

					//Waits for the transition to finish, then sets the Main view back to the static (no Slide) view
					setTimeout(() => {
						budgetContext.setBudgetState({
							...budgetContext.budgetState,
							editingTransition: false,
							showTransitions: true,
							mainViewTransition: false,
							editing: false
						})
					}, 825)
				}
			}
		}
	}

	return (
		<Root className={classes.root}>
			<Alert
				open={state.showWarning}
				title='Budget Template'
				description={infoMessage}
				handleAlert={() => handleAlert(false)}
				actionBtn={state.useTemplate ? 'Clear Budget' : 'Use Template'}
				handleActionBtn={clearBudget}
			/>

			<Card className={classes.card}>
				<Grid container direction='column' alignItems='center' justifyContent='center' className={classes.grid}>
					<Grid item>
						<Typography variant='h3' sx={{ textAlign: 'center', paddingBottom: '5px' }}>
							Start Here
						</Typography>
						{!state.saving && Object.entries(state.errors).length === 0 && state.errors.constructor === Object && (
							<Typography variant='body1' className={classes.description}>
								Now its time to budget! You can start by using the template by clicking below, or just dive in and start listing
								out your expenses.
							</Typography>
						)}

						{!state.saving && Object.entries(state.errors).length !== 0 && state.errors.constructor === Object && (
							<Typography variant='body1' className={classes.description} sx={{ color: 'error.main' }}>
								{state.errors.general}
							</Typography>
						)}
					</Grid>
					{state.saving && (
						<Grid item sx={{ padding: '10px' }}>
							<CircularProgress size={24} />
						</Grid>
					)}
					<Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
						{webConsole && (
							<Grid item>
								<Button
									color='primary'
									variant='contained'
									style={{ marginTop: '15px', marginRight: '5px', color: '#FFF' }}
									disabled={state.saving}
									onClick={handleSaveBudget}
								>
									Save Budget
								</Button>
							</Grid>
						)}

						{!webConsole && (
							<Grid item>
								<Button
									color='primary'
									variant='contained'
									style={{ marginTop: '15px', marginRight: '5px', color: '#FFF' }}
									onClick={clearBudget}
								>
									{state.useTemplate ? 'Clear Budget' : 'Use Template'}
								</Button>
							</Grid>
						)}
					</Grid>

					<Grid item sx={{ paddingTop: '25px' }}>
						<Typography variant='h3' sx={{ textAlign: 'center', paddingBottom: '8px' }}>
							Tools
						</Typography>

						<Grid container direction='row' alignItems='center' justifyContent='space-between' spacing={1}>
							<Grid item sx={{ paddingLeft: '10px' }}>
								<Typography variant='h4'>Amount Type</Typography>
							</Grid>
							<Grid item>
								<ToggleButtonGroup size='small' value={'finite'} exclusive disabled={true}>
									<ToggleButton name='amountType' value='finite'>
										Finite
									</ToggleButton>
									<ToggleButton name='amountType' value='percent'>
										Percent
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						<Typography variant='body1' className={classes.description} sx={{ paddingTop: '8px' }}>
							You can assign a category a finite amount of money or a percentage.
						</Typography>

						<Grid
							container
							direction='row'
							alignItems='center'
							justifyContent='space-between'
							spacing={1}
							sx={{ paddingTop: '25px' }}
						>
							<Grid item sx={{ paddingLeft: '10px' }}>
								<Typography variant='h4'>Percent Type</Typography>
							</Grid>
							<Grid item>
								<ToggleButtonGroup size='small' value={'total'} exclusive disabled={true}>
									<ToggleButton name='percentType' value='total'>
										Total
									</ToggleButton>
									<ToggleButton name='percentType' value='remaining'>
										Remaining
									</ToggleButton>
								</ToggleButtonGroup>
							</Grid>
						</Grid>
						<Typography variant='body1' className={classes.description} sx={{ paddingTop: '8px' }}>
							When you assign a category a percentage, you can either designate it as a percentage of your total monthly income or
							a percentage of the remaining amount still remaining after every other category has been filled.
						</Typography>
						<Typography variant='body1' className={classes.description} sx={{ paddingTop: '10px' }}>
							Then any category assigned a finite amount of a percent of the total will be filled first, and any categories
							designated with a percentage of whats remaining will be filled last.
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</Root>
	)
}

export default InfoTile
