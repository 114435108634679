import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Button, Grid, useMediaQuery } from '@mui/material'
import { AccountTile } from './'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'
import { AccountsSummary, useAccounts } from '@mikeint0sh/the-keeton-cloud-components/finance'
import { getAccounts as apiGetAccounts } from '../../../helpers/api'

const GridStyled = styled(Grid)(() => ({ display: 'flex' }))

const Overview = () => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { accounts } = useContext(GlobalContext)
	const { changeActiveView } = useContext(AccountsContext)
	const { accountsSummary } = useAccounts('accountsSummary', apiGetAccounts, GlobalContext)

	return (
		<Fragment>
			<GridStyled item xs={12}>
				<AccountsSummary accountsSummary={accountsSummary} />
			</GridStyled>

			{/* Grid of Accounts*/}
			{accounts.map((physicalAccountArr, pArrIndex) => {
				if (!(physicalAccountArr.length === 1 && physicalAccountArr[0].physicalAccountID === 'unassigned')) {
					//Map each physical account array with it's own grid of virtual accounts
					return (
						<Grid
							item
							container
							spacing={isDesktop ? 3 : 1}
							justifyContent='flex-start'
							style={{ paddingTop: 20, paddingBottom: 20 }}
							key={pArrIndex}
						>
							{physicalAccountArr.map((accountObject, vIndex) => {
								if (vIndex === 0) {
									//Then its the actual physical account
									return (
										<GridStyled item xs={isDesktop ? 3 : 12} key={accountObject.physicalAccountID}>
											<Button
												//Change view to activeView, physicalAccountID, virtualAccountID (which does not exist)
												onClick={() => changeActiveView('AccountView', pArrIndex, undefined)}
												style={{ backgroundColor: 'transparent', flex: 1 }}
											>
												<AccountTile
													physicalAccountID={accountObject.physicalAccountID}
													type={accountObject.type ? accountObject.type : 'unassigned'}
													name={accountObject.name}
													balance={accountObject.balance}
												/>
											</Button>
										</GridStyled>
									)
								} else {
									//It's a virtual account
									return (
										<GridStyled item xs={isDesktop ? 3 : 6} key={accountObject.virtualAccountID}>
											<Button
												onClick={() => {
													if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
														changeActiveView('AccountView', pArrIndex, vIndex)
													}
												}}
												style={{ backgroundColor: 'transparent', flex: 1 }}
											>
												<AccountTile
													physicalAccountID={physicalAccountArr[0].physicalAccountID}
													virtualAccountID={accountObject.virtualAccountID}
													name={accountObject.name}
													balance={accountObject.balance}
												/>
											</Button>
										</GridStyled>
									)
								}
							})}
						</Grid>
					)
				} else {
					return null //Don't show the 'unassigned' account tile if it contains no virtual accounts
				}
			})}
		</Fragment>
	)
}

export default Overview
