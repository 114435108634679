import React, { useContext } from 'react'
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const ExpenseAccountsDropDown = () => {
	const { accounts } = useContext(GlobalContext)
	const { transactionFormState: state, handleExpenseDropDownChange } = useContext(NewTransactionFormContext)

	return (
		<FormControl variant='outlined' error={state.errors.source ? true : false}>
			<InputLabel>{'Expense From'}</InputLabel>
			<Select
				native
				name={'source'} //Either source or destination
				//Combines the id with the name (and optional sub-category ID) to pass to the handler
				value={`${state.source.id}__#&&#__${state.source.name}__#&&#__${
					state.subCategoryID !== undefined ? state.subCategoryID : ''
				}`}
				onChange={(e) => handleExpenseDropDownChange(e, 'source')}
				label={'Expense From'}
				inputProps={{ name: 'source' }}
			>
				{/* Title */}
				<option value=''></option>
				{accounts.map((physicalAccountArr) => {
					if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
						//Show all virtual accounts except for those unassigned
						return physicalAccountArr.map((account, accountIndex) => {
							if (accountIndex !== 0) {
								//If there are no subcategories
								if (account.subcategories === undefined || Object.entries(account.subcategories).length === 0) {
									return (
										<option value={`${account.virtualAccountID}__#&&#__${account.name}__#&&#__`} key={account.virtualAccountID}>
											{account.name}
										</option>
									)
								} else {
									//List the subcategories and disable the main category
									return Object.entries(account.subcategories).map((subCategory) => {
										return (
											<option
												value={`${account.virtualAccountID}__#&&#__${account.name}: ${subCategory[1].name}__#&&#__${subCategory[0]}`}
												key={`${subCategory[0]}`}
											>
												{`${account.name}: ${subCategory[1].name}`}
											</option>
										)
									})
								}
							} else {
								return (
									<option
										disabled
										value={`${account.physicalAccountID}__#&&#__${account.name}__#&&#__`}
										key={account.physicalAccountID}
									>
										{account.name}
									</option>
								)
							}
						})
					}
					return null
				})}
			</Select>

			{/* Shows relevant From source errors to the user */}
			{state.errors.source && <FormHelperText>{state.errors.source}</FormHelperText>}
		</FormControl>
	)
}
export default ExpenseAccountsDropDown
