import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, IconButton, Switch, useMediaQuery } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'ControlItem'
const classes = {
	controlRow: `${PREFIX}-controlRow`,
	controlTitle: `${PREFIX}-controlTitle`
}

const GridStyled = styled(Grid)(({ theme: { breakpoints } }) => ({
	[`&.${classes.controlRow}`]: {
		margin: 0,
		paddingTop: 5,
		paddingBottom: 5
	},
	[`&. ${classes.controlTitle}`]: {
		fontSize: 18,
		fontWeight: 300,
		[breakpoints.down('sm')]: {
			fontSize: 20
		}
	}
}))

/** Displays each item in the control panel */
const ControlItem = ({ name, property }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { budgetViewControlPanel, setBudgetViewControlPanel } = useContext(GlobalContext)

	const toggleSwitch = () => {
		setBudgetViewControlPanel({
			...budgetViewControlPanel,
			[property]: !budgetViewControlPanel[property]
		})
	}

	return (
		<GridStyled
			item
			container
			direction='row'
			alignItems='center'
			justifyContent={isDesktop ? 'space-between' : 'flex-start'}
			className={classes.controlRow}
		>
			{isDesktop && (
				<Grid container direction='column' alignItems='center' sx={{ width: '90px' }}>
					{name.map((word) => {
						return (
							<Typography key={word} className={classes.controlTitle}>
								{word}
							</Typography>
						)
					})}
				</Grid>
			)}

			{!isDesktop && <Typography className={classes.controlTitle}>{name.join(' ')}</Typography>}

			<IconButton sx={{ padding: 0 }} size='large'>
				<Switch color='secondary' checked={budgetViewControlPanel[property]} onChange={toggleSwitch} />
			</IconButton>
		</GridStyled>
	)
}

export default ControlItem
