import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { NewTransactionForm } from './NewTransactionForm'
import { TransactionTableController } from './'
import NewTransactionFormContextProvider from './NewTransactionForm/NewTransactionFormContext'

const Root = styled('div')(() => ({
	//Content: Place in all minimal layouts
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1, //Pushes Footer to bottom
	padding: 5
}))

const Transactions = () => {
	const [searchParams] = useSearchParams()
	const [showForm, setShowForm] = useState(false) // temporary

	/** Opens NewTransactionForm popup with query string: ...?create=true - used on The Books page on Client site */
	useEffect(() => {
		if (searchParams.get('create') === 'true') {
			setShowForm(true)
		}
		// eslint-disable-next-line
	}, [])

	return (
		<Root>
			<Grid container direction='column' spacing={4} justifyContent='flex-start'>
				<NewTransactionFormContextProvider>
					<NewTransactionForm open={showForm} handleClose={() => setShowForm(false)} />
				</NewTransactionFormContextProvider>

				<TransactionTableController handelCreateTransaction={() => setShowForm(true)} />
			</Grid>
		</Root>
	)
}

export default Transactions
