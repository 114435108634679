import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { AddCircleSharp, RemoveCircleRounded, Build } from '@mui/icons-material'
import { BudgetToolContext } from '../BudgetToolContext'
import { CategoryToolBar } from './'
import MKCurrencyTextFelidA from '../../../../components/MKCurrencyTextFieldA'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'Category'
const classes = {
	root: `${PREFIX}-root`,
	grid: `${PREFIX}-grid`,
	item: `${PREFIX}-item`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		flex: 1,
		height: '100%'
	},
	[`& .${classes.grid}`]: {
		display: 'flex',
		padding: 0
	},
	[`& .${classes.item}`]: {
		paddingLeft: 3,
		paddingRight: 3
	}
}))

const Category = ({
	type,
	console: webConsole,
	index,
	name,
	amountType,
	amount,
	percentType,
	percentage,
	subCategories,
	goal,
	showToolBar,
	priority
}) => {
	const { budgetState, setBudgetState, getPercentage, didChange, percentChanged, toggleToolBar } = useContext(BudgetToolContext)

	/** Updates the state when the text felid changes */
	const handleChange = (event, newChoice) => {
		let value = event.currentTarget.value

		if (newChoice !== null) {
			setBudgetState({
				...budgetState,
				[type]: [
					...budgetState[type].slice(0, index),
					{
						...budgetState[type][index],
						[event.currentTarget.name]: value //Set the new value
					},
					...budgetState[type].slice(index + 1)
				]
			})
		}
	}

	const editSubCategory = (event) => {
		const subIndex = parseInt(event.currentTarget.name)
		const value = event.currentTarget.value
		setBudgetState({
			...budgetState,
			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					subCategories: [
						...budgetState[type][index].subCategories.slice(0, subIndex),
						//Set the new value for name == [[accountID, name, spent]]
						[budgetState[type][index].subCategories[subIndex][0], value, budgetState[type][index].subCategories[subIndex][2]],
						...budgetState[type][index].subCategories.slice(subIndex + 1)
					]
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	/** Adds a blank category to budget state when plus is pressed */
	const addSubCategory = () => {
		setBudgetState({
			...budgetState,
			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					//Add a blank subcategory formatted as: [[accountID, name, spent]]
					subCategories: [...budgetState[type][index].subCategories, ['', '', 0]]
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	/** Removes the category from budget state when delete is pressed */
	const removeSubCategory = (event) => {
		const subIndex = parseInt(event.currentTarget.name)
		let newSubCategories
		if (budgetState[type][index].subCategories[subIndex][0] === '') {
			//If accountID is blank then it is not saved in the db: [[accountID, name, spent]]
			newSubCategories = [
				...budgetState[type][index].subCategories.slice(0, subIndex),
				//Just remove from array
				...budgetState[type][index].subCategories.slice(subIndex + 1)
			]
		} else {
			//If accountID is not blank then it needs to be flagged to be removed from the budget's category in the db
			newSubCategories = [
				...budgetState[type][index].subCategories.slice(0, subIndex),
				//Set the name to '' [[accountID, name, spent]]
				[budgetState[type][index].subCategories[subIndex][0], undefined, budgetState[type][index].subCategories[subIndex][2]],
				...budgetState[type][index].subCategories.slice(subIndex + 1)
			]
		}
		setBudgetState({
			...budgetState,
			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					subCategories: newSubCategories
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	const updateGoal = (event, newValue) => {
		const lowercaseType = type.toLowerCase()
		let integerValue
		if (typeof event.currentTarget.value !== 'number') {
			integerValue = accounting.unformat(event.currentTarget.value)
		} else {
			integerValue = event.currentTarget.value
		}
		setBudgetState({
			...budgetState,
			[lowercaseType]: [
				...budgetState[lowercaseType].slice(0, index),
				{
					...budgetState[lowercaseType][index],
					goal: integerValue
				},
				...budgetState[lowercaseType].slice(index + 1)
			]
		})
	}

	const getSavingsContribution = () => {
		if (amountType === 'finite') {
			return amount
		} else if (amountType === 'percent') {
			return getPercentage(percentType, percentage)
		}
	}

	const getCompletionDate = (goal) => {
		let goalIntegerValue
		if (typeof goal !== 'number') {
			goalIntegerValue = accounting.unformat(goal)
		} else {
			goalIntegerValue = goal
		}

		const contributionAmount = getSavingsContribution()
		const monthsTillComplete = Math.ceil(goalIntegerValue / contributionAmount)
		const today = new Date()
		const complete = new Date(today.setMonth(today.getMonth() + monthsTillComplete))
		return `${complete.toLocaleString('en-us', { month: 'long' })} ${complete.getFullYear()}`
	}

	return (
		<Root className={classes.root}>
			<Grid container direction='column' alignItems='center'>
				{/* ToolBar */}
				{showToolBar && (
					<CategoryToolBar
						type={type}
						console={webConsole}
						index={index}
						amountType={amountType}
						amount={amount}
						percentType={percentType}
						percentage={percentage}
						showToolBar={showToolBar}
						priority={priority}
					/>
				)}

				{/* Category details row */}
				<Grid item container direction='row' alignItems='center' className={classes.grid}>
					{/* Show the wrench btn if the ToolBar is not showing */}
					{!showToolBar && (
						<Grid item>
							<IconButton
								sx={{ color: 'text.secondary', padding: '0px', paddingRight: '10px' }}
								onClick={() => toggleToolBar(type, index, showToolBar)}
								size='large'
							>
								<Build sx={{ width: '20px', height: '20px' }} />
							</IconButton>
						</Grid>
					)}
					{/* Show the add sub-category btn if the ToolBar is showing */}
					{showToolBar && (
						<Grid item>
							<IconButton sx={{ padding: '0px', paddingRight: '6px' }} onClick={addSubCategory} size='large'>
								<AddCircleSharp color='secondary' />
							</IconButton>
						</Grid>
					)}

					{/* Only show the percentage text felid if the user has chosen percent */}
					{amountType === 'percent' && (
						<Grid item className={classes.item} sx={{ paddingLeft: '0px', paddingRight: '5px', maxWidth: '50px' }}>
							<TextField
								variant='standard'
								autoComplete='off'
								fullWidth
								InputProps={{
									disableUnderline: true,
									endAdornment: (
										<InputAdornment sx={{ margin: '1px' }} position='end'>
											<Typography sx={{ fontSize: 17 }}>%</Typography>
										</InputAdornment>
									)
								}}
								sx={{ padding: '0px' }}
								inputProps={{ sx: { fontSize: 17, textAlign: 'right' } }}
								size='medium'
								inputMode='numeric'
								placeholder='10%'
								onChange={(e) => percentChanged('percentage', e.currentTarget.value, type, index, percentType, percentage)}
								value={percentage}
							/>
						</Grid>
					)}

					{/* Currency input TextFelid */}
					<Grid item className={classes.item} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
						<MKCurrencyTextFelidA
							maskOptions={{ placeholder: '$0.00' }}
							onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
							value={amountType === 'percent' ? getPercentage(percentType, percentage) : amount}
							disabled={amountType === 'percent'}
							placeholder='$0.00'
							name='amount'
							textAlign={'right'}
							maxWidth={amountType === 'percent' ? (getPercentage(percentType, percentage).toString().length > 6 ? 90 : 78) : 128}
						/>
					</Grid>

					{/* Category name input TextFelid */}
					<form noValidate sx={{ flexGrow: 1 }}>
						<Grid item>
							<TextField
								fullWidth
								variant='standard'
								sx={{
									paddingLeft:
										amountType === 'percent'
											? getPercentage(percentType, percentage).toString().length > 6
												? '8px'
												: '20px'
											: '20px'
								}}
								size='medium'
								placeholder='Category Name'
								InputProps={{
									style: { fontSize: 17 },
									disableUnderline: true
								}}
								inputProps={{ autoComplete: 'off' }}
								name='name'
								value={name}
								onChange={handleChange}
							/>
						</Grid>
					</form>

					{/* If it is the savings type: show goal details */}
					{type.toLowerCase() === 'savings' && (
						<Grid item container direction='row' alignItems='center' className={classes.grid} sx={{ paddingBottom: '15px' }}>
							<Grid item>
								<Typography sx={{ fontSize: '17px', marginLeft: '-5px' }}>Goal:</Typography>
							</Grid>

							{/* Goal currency input */}
							<Grid item sx={{ paddingRight: '8px' }}>
								<MKCurrencyTextFelidA
									maskOptions={{ placeholder: '$0.00' }}
									maxWidth={114}
									sx={{ paddingLeft: '10px' }}
									textAlign={'right'}
									InputProps={{
										disableUnderline: true
									}} //onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
									onChange={updateGoal}
									value={goal} // budgetState[type.toLowerCase()][index]  amountType === 'percent' ? getPercentage(percentType, percentage) :
									placeholder='$0.00'
									//name='amount'
								/>
							</Grid>

							{/* Goal Completion Projection */}
							{goal !== 0 && getSavingsContribution() !== 0 && (
								<Grid item>
									<Typography sx={{ fontSize: 17, paddingLeft: '13px', textAlign: 'right', color: 'text.secondary' }}>
										{getCompletionDate(goal)}
									</Typography>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>

				{/* Subcategories if the type is not savings */}
				{type.toLowerCase() !== 'savings' &&
					budgetState[type.toLowerCase()][index].subCategories.map((data, subIndex) => {
						if (data[1] !== undefined) {
							return (
								<Grid item container direction='row' alignItems='center' key={subIndex} className={classes.grid}>
									{/* Delete sub-category btn */}
									<Grid item>
										<IconButton
											sx={{
												paddingLeft: '177px',
												paddingRight: '15px',
												paddingTop: '0px',
												paddingBottom: '0px',
												color: 'error.main'
											}}
											name={`${subIndex}`}
											onClick={removeSubCategory}
											size='large'
										>
											<RemoveCircleRounded />
										</IconButton>
									</Grid>

									{/* Sub-category TextFelid input */}
									<Grid item sx={{ flexGrow: 1 }}>
										<TextField
											fullWidth
											variant='standard'
											size='medium'
											autoComplete='off'
											placeholder='Subcategory Name'
											InputProps={{ sx: { fontSize: 17 }, disableUnderline: true }}
											name={`${subIndex}`}
											//subCategories == [[accountID, name, spent]]
											value={subCategories[subIndex][1]}
											onChange={editSubCategory}
										/>
									</Grid>
								</Grid>
							)
						}
						return <div key={subIndex} /> // untested... will this work ?
					})}
			</Grid>
		</Root>
	)
}

export default Category
