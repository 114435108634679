import React, { useContext } from 'react'
import { Button, Typography, Grid, Link } from '@mui/material'
import { GlobalContext } from '../../helpers/GlobalContext'
import { GA4 } from '../../helpers'

const Signup = ({ closePopup }) => {
	const { useGA } = useContext(GlobalContext)

	return (
		<Grid container direction='column' alignItems='flex-end' spacing={2}>
			<Grid item>
				<Typography variant='body1' style={{ paddingTop: 10 }}>
					We are not currently accepting new users while the site is under development, but thank you for your interest!
				</Typography>
			</Grid>

			<Grid item>
				<Typography variant='body1'>
					Feel free to use any of our (beta) features until then, and{' '}
					<Link
						href='https://forms.gle/Uyp8oCcQAXmnX2aR6'
						target='_blank'
						rel='noopener'
						component='a'
						onClick={GA4.event(useGA, 'feedback_form', 'Sign up')}
						underline='hover'
					>
						let us know what you think!
					</Link>
				</Typography>
			</Grid>
			<Grid item style={{ flexGrow: 1, marginBottom: -10 }}>
				<Button variant='standard' color='grey' onClick={closePopup}>
					Close
				</Button>
			</Grid>
		</Grid>
	)
}

export default Signup
