import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, Typography, IconButton, Button, InputLabel, FormControl, Select } from '@mui/material'
import Category from './Category'
import { BudgetToolContext } from '../BudgetToolContext'
import { AddCircleSharp } from '@mui/icons-material'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'BudgetTypeTile'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	title: `${PREFIX}-title`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		height: '100%'
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		paddingLeft: 10,
		paddingRight: 5
	},
	[`& .${classes.grid}`]: {
		flex: 1,
		paddingTop: 5,
		paddingBottom: 20,
		paddingLeft: 5,
		paddingRight: 0
	},
	[`& .${classes.title}`]: {
		fontSize: 26,
		fontWeight: 400,
		paddingBottom: 0
	}
}))

const BudgetTypeTile = ({ type, console: webConsole }) => {
	const { accounts } = useContext(GlobalContext)
	const { budgetState, setBudgetState, incomeState, calculateTotalBudgetedAmount, calculatePercentage, state, setState } =
		useContext(BudgetToolContext)

	const addCategory = (category) => {
		if (category === 'new') {
			const lastArguments =
				type.toLowerCase() === 'savings'
					? { priority: 2, goal: 0 }
					: { priority: type.toLowerCase() === 'wants' ? 3 : 1, subCategories: [] }

			setBudgetState({
				...budgetState,
				[type.toLowerCase()]: [
					...budgetState[type.toLowerCase()],
					{
						name: '',
						accountID: '',
						spent: 0,
						accountBalance: 0,
						amountType: 'finite',
						amount: 0,
						percentType: 'total',
						percentage: 0, //Only store locally
						showToolBar: false,
						...lastArguments
					}
				]
			})
		} else {
			//Add an existing account/category
			const categoryComponents = category.split('__#&&#__')
			const virtualAccountID = categoryComponents[0]
			const name = categoryComponents[1]
			let balance = 0
			let subcategories = []

			let found = false
			accounts.every((physicalAccountArr) => {
				physicalAccountArr.every((account) => {
					if (account.virtualAccountID === virtualAccountID) {
						balance = account.balance
						if (account.subcategories !== undefined) {
							Object.keys(account.subcategories).forEach((key) => {
								subcategories.push([key, account.subcategories[key].name, 0])
							})
						} else {
							subcategories = []
						}
						found = true
						return false //Break out of the loop
					} else {
						return true //Keep searching for the virtual account
					}
				})

				return !found //If found stop searching, if not found: keep searching
			})

			if (!found) {
				console.error(`${category} not found`)
			}

			const lastArguments =
				type.toLowerCase() === 'savings'
					? { priority: 2, goal: 0 }
					: { priority: type.toLowerCase() === 'wants' ? 3 : 1, subCategories: subcategories }

			const newCategory = {
				name: name,
				accountID: virtualAccountID,
				spent: 0,
				accountBalance: balance,
				amountFunded: 0,
				amountType: 'finite',
				amount: 0,
				percentType: 'total',
				percentage: 0, //Only stored locally
				showToolBar: false,
				existingAccount: true, //Signals that this category was an existing category added to the budget but isn't saved to the db
				...lastArguments
			}

			setBudgetState({
				...budgetState,
				[type.toLowerCase()]: [...budgetState[type.toLowerCase()], newCategory]
			})
		}
		setState({ ...state, showAddCategoryButtons: '', categoryToAdd: '' }) //Hide Add category buttons
	}

	/** Returns if the active budget already contains the virtual account (given it's ID) */
	const containsAccount = (virtualAccountID) => {
		for (let i = 0; i < budgetState.basics.length; i++) {
			if (budgetState.basics[i].accountID === virtualAccountID) {
				return true
			}
		}
		for (let i = 0; i < budgetState.wants.length; i++) {
			if (budgetState.wants[i].accountID === virtualAccountID) {
				return true
			}
		}
		for (let i = 0; i < budgetState.savings.length; i++) {
			if (budgetState.savings[i].accountID === virtualAccountID) {
				return true
			}
		}

		return false
	}

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='row' className={classes.grid}>
					<Grid item>
						<Typography
							variant='h1'
							className={classes.title}
							sx={
								type === 'Basics' ? { color: 'error.main' } : type === 'Wants' ? { color: '#8536f5' } : { color: 'primary.main' }
							}
						>
							{`${type}${
								incomeState.income - calculateTotalBudgetedAmount() === 0 ? `:  ${calculatePercentage(type.toLowerCase())}%` : ''
							}`}
						</Typography>
					</Grid>

					{/* Displays and manages each type's categories */}
					{budgetState[type.toLowerCase()].map((data, index) => {
						if (data.name !== undefined) {
							return (
								<Grid item xs={12} key={index}>
									<Category type={type.toLowerCase()} console={webConsole} index={index} {...data} />
								</Grid>
							)
						}
						return <div key={index} />
					})}
					<Grid item container variant='row' justifyContent='center'>
						{!webConsole && (
							<IconButton onClick={() => addCategory('new')} sx={{ padding: '0px', paddingTop: '10px' }} size='large'>
								<AddCircleSharp color='secondary' />
							</IconButton>
						)}

						{webConsole && state.showAddCategoryButtons !== type.toLowerCase() && (
							<IconButton
								onClick={() => setState({ ...state, showAddCategoryButtons: type.toLowerCase() })}
								sx={{ padding: '0px', paddingTop: '10px' }}
								size='large'
							>
								<AddCircleSharp color='secondary' />
							</IconButton>
						)}

						{webConsole && state.showAddCategoryButtons === type.toLowerCase() && (
							<Grid container direction='row' alignItems='center' justifyContent='center' spacing={1}>
								<Grid item sx={{ paddingBottom: '0px', paddingRight: '5px' }}>
									<FormControl variant='standard'>
										<InputLabel>{'Account'}</InputLabel>
										<Select
											native
											name={'addExistingAccount'} //Either source or destination or addExistingAccount
											value={state.categoryToAdd} //The id with the name to pass to the handler
											onChange={(e) => setState({ ...state, categoryToAdd: e.currentTarget.value })}
											label={'Account'}
											inputProps={{ name: 'addExistingAccount' }}
										>
											{/* Title */}
											<option value='Choose Account'></option>
											{accounts.map((physicalAccountArr) => {
												if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
													//Show all virtual accounts except for those unassigned
													return physicalAccountArr.map((account, accountIndex) => {
														if (accountIndex !== 0) {
															return (
																<option
																	disabled={containsAccount(account.virtualAccountID)}
																	value={`${account.virtualAccountID}__#&&#__${account.name}`}
																	key={account.virtualAccountID}
																>
																	{account.name}
																</option>
															)
														} else {
															return (
																<option
																	disabled
																	value={`${account.physicalAccountID}__#&&#__${account.name}`}
																	key={account.physicalAccountID}
																>
																	{account.name}
																</option>
															)
														}
													})
												}
												return null
											})}
										</Select>
									</FormControl>
								</Grid>

								<Grid item sx={{ paddingTop: '22px', paddingRight: '5px' }}>
									<Button
										disabled={state.categoryToAdd === ''}
										color='primary'
										variant='contained'
										sx={{ color: '#FFF' }}
										onClick={() => addCategory(state.categoryToAdd)}
									>
										Add Existing Category
									</Button>
								</Grid>

								<Grid item sx={{ paddingTop: '22px', paddingRight: '5px' }}>
									<Button color='primary' variant='contained' sx={{ color: '#FFF' }} onClick={() => addCategory('new')}>
										Create New Category
									</Button>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Card>
		</Root>
	)
}

export default BudgetTypeTile
