import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import MaskedInput from 'react-text-mask/'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const defaultMaskOptions = {
	prefix: '$',
	suffix: '',
	includeThousandsSeparator: true,
	thousandsSeparatorSymbol: ',',
	allowDecimal: true,
	decimalSymbol: '.',
	decimalLimit: 2, //How many digits allowed after the decimal
	integerLimit: 8, //Limit length of integer numbers
	allowNegative: true,
	allowLeadingZeroes: false
}

/**
 * Under-the-hood mask formatter for the current text view
 *
 * @param {*} maskOptions are the parameters for the mask
 * @param {*} inputProps are all the other props to be included in the current text felid
 */
const CurrencyTextFelidA = ({ maskOptions, ...inputProps }) => {
	const currencyMask = createNumberMask({
		...defaultMaskOptions,
		...maskOptions
	})

	return (
		<MaskedInput
			mask={currencyMask}
			type='text'
			{...inputProps}
			render={(ref, props) => {
				return (
					<TextField
						variant='standard'
						autoComplete='off'
						fullWidth
						InputProps={{ disableUnderline: true }}
						inputProps={{ style: { fontSize: 17, textAlign: 'right' } }}
						size='medium'
						inputMode='numeric'
						inputRef={ref}
						{...props}
					/>
				)
			}}
		/>
	)
}

/**
 * The A team of currency input. just provide a value in the form of an integer, onChange function, and name (optional placeholder)
 * and it will format the rest as the user types and when they remove focus.
 *
 * @param {*} maskOptions are the parameters for the mask
 * @param {*} onChange is the function to be called in the text felids onChange
 * @param {*} inputProps are all the other props to be included in the current text felid
 */
const MKCurrencyTextFieldA = ({ maskOptions, onChange, textAlign, maxWidth = 155, fontSize = 17, ...inputProps }) => {
	const [formattedAmount, setFormattedAmount] = useState('') //Local state to hold the formatted string version of the provided amount

	/** Updates the formatted state when the text felid changes */
	const handleChange = (event, newChoice) => {
		setFormattedAmount(event.currentTarget.value) //Sets the local state
		onChange(event, newChoice) //Calls the onChange function the user provides
	}

	/** Formats the integer amount given by the user to a proper currency string and stores it locally to display */
	const formatAmount = () => {
		if (inputProps.value !== null && inputProps.value !== undefined) {
			setFormattedAmount(accounting.format(formattedAmount)) //Format the (semi)formatted amount properly and save it locally
		}
	}

	//Updates the text felid's value if the parent component gets deleted
	React.useEffect(() => {
		const didChange = inputProps.value && accounting.unformat(formattedAmount) !== inputProps.value
		if (didChange || didChange === 0) {
			//If the values no longer match (only happens if a parent component is deleted)
			setFormattedAmount(accounting.format(inputProps.value)) //Format the passed value and set the local string
		}
	}, [formattedAmount, inputProps.value]) //List dependency's to prevent infinite loops

	//Return the currency text felid given the formatted string value from local state
	return (
		<CurrencyTextFelidA
			maskOptions={maskOptions}
			inputProps={{ style: { fontSize: fontSize, textAlign: textAlign, maxWidth: maxWidth } }}
			{...inputProps}
			onChange={handleChange}
			value={formattedAmount}
			onBlur={formatAmount}
		/>
	)
}

MKCurrencyTextFieldA.defaultProps = {
	inputMode: 'numeric',
	maskOptions: {}
}

MKCurrencyTextFieldA.propTypes = {
	inputmode: PropTypes.string,
	maskOptions: PropTypes.shape({
		prefix: PropTypes.string,
		suffix: PropTypes.string,
		includeThousandsSeparator: PropTypes.bool,
		thousandsSeparatorSymbol: PropTypes.string,
		allowDecimal: PropTypes.bool,
		decimalSymbol: PropTypes.string,
		decimalLimit: PropTypes.string,
		requireDecimal: PropTypes.bool,
		allowNegative: PropTypes.bool,
		allowLeadingZeroes: PropTypes.bool,
		integerLimit: PropTypes.number
	})
}

export default MKCurrencyTextFieldA
export { CurrencyTextFelidA }
