import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Slide, useMediaQuery, useTheme } from '@mui/material'
import BudgetToolContextProvider from '../Tools/BudgetTool/BudgetToolContext'
import { BudgetTool } from '../Tools/BudgetTool'
import { Overview, Summaries } from './'
import { Footer, FixedTopBar } from '@mikeint0sh/the-keeton-cloud-components/components'
import ComingSoon from '../ComingSoon/ComingSoon'
import { MenuBar } from './components'
import { GlobalContext } from '../../helpers/GlobalContext'
import { MainContext } from '@mikeint0sh/the-keeton-cloud-components/layouts/Main/MainContext'
import { BudgetContext } from './BudgetContext'
import { updateOnboarding as apiUpdateOnboarding } from '../../helpers/api'
import EditBudgetView from './components/EditBudgetView'

const PREFIX = 'Budget'
const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`,
	slide: `${PREFIX}-slide`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		flex: 1,
		display: 'flex'
	},
	[`& .${classes.content}`]: {
		//Place in all minimal layouts
		flexGrow: 1,
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	[`& .${classes.slide}`]: {
		position: 'absolute',
		height: '100%'
		// width: '100%'
	}
}))

const Budget = () => {
	const { transitions, spacing } = useTheme()
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { credentials, addCredentials } = useContext(GlobalContext)
	const { openMenu } = useContext(MainContext)
	const { budgetState, setBudgetState } = useContext(BudgetContext)
	const [show, setShow] = useState(true)

	//Sets the proper values according to the global onboarding state
	useEffect(() => {
		if (!credentials.onboarding) {
			setBudgetState({
				...budgetState,
				onboarding: false,
				onboardingTransition: false,
				showTransitions: true
			})
		}
		// eslint-disable-next-line
	}, [credentials])

	//Shows Menu transitions once the Slide animation finishes and switches to the non-slide
	//version so that it doesn't slide in overtime the user switches tabs or views
	useEffect(() => {
		if (!budgetState.onboardingTransition) {
			setTimeout(() => {
				if (!budgetState.showTransitions) {
					setBudgetState({
						...budgetState,
						showTransitions: true
					})
				}

				if (credentials.onboarding) {
					//The user just completed the onboarding process, update this in the database
					apiUpdateOnboarding()
					// only set if no error
					addCredentials({ onboarding: false })
				}
			}, 800)
		}
		//Smoothly transitions to and from the main view and edit view
		if (budgetState.editing && budgetState.mainViewTransition) {
			setTimeout(() => {
				setShow(false) //Set to false once the MainView is hidden and the EditBudgetView is done animating
			}, 1000)
		} else if (budgetState.editing && !budgetState.mainViewTransition) {
			setTimeout(() => {
				//Show the Main view (with slide) a little bit after the EditBudgetView has started moving off screen so they don't overlap
				setShow(true)
			}, 10)
		}
		// eslint-disable-next-line
	}, [budgetState])

	return (
		<Root className={classes.root}>
			<BudgetToolContextProvider>
				<FixedTopBar side='top' height={50} GlobalContext={GlobalContext}>
					<MenuBar height={50} />
					{/* Height of built into the slide css if console === true */}
					{/* set up a different variable to trigger animation like with page and time delay */}
					{/* Onboarding is true in the database -> the user hasn't set up a budget yet: display budget onboarding */}
					{((credentials.onboarding && budgetState.onboardingTransition) || budgetState.editing) && (
						<Slide
							className={classes.slide}
							//Margin to adjust for the menubar/ the topBar in the console
							//0, 0
							style={{ flex: 1, zIndex: 15, marginTop: -134, paddingTop: 134 }} //, marginTop: -114, paddingTop: 114
							direction={credentials.onboarding ? (budgetState.onboarding ? 'left' : 'up') : 'left'}
							in={credentials.onboarding ? budgetState.onboarding : budgetState.editingTransition}
							timeout={800}
							mountOnEnter
							unmountOnExit
						>
							<div
								className={classes.content}
								style={{
									flex: 1,
									width: isDesktop
										? openMenu
											? `calc(100% - 240px - 20px)`
											: `calc(100% - ${spacing(8)} - 20px)` //-20px for Desktop margin?
										: 'calc(100% - 16px)', //Mobile margin?
									transition: transitions.create('all', {
										easing: transitions.easing.sharp,
										duration: transitions.duration[openMenu ? 'enteringScreen' : 'leavingScreen']
									})
								}}
							>
								{credentials.onboarding && <BudgetTool console={true} showPrompt editMode={false} />}
								{!credentials.onboarding && <EditBudgetView />}
							</div>
						</Slide>
					)}

					{/* The user just complected Budget onboarding OR is in EditBudgetView*/}
					{((credentials.onboarding && !budgetState.onboardingTransition) || (budgetState.editing && show)) && (
						<Slide
							className={classes.slide}
							//Margin to adjust for the menubar/ the topBar in the console
							sx={{
								flex: 1,
								zIndex: 15,
								marginTop: '-134px', //-114,
								paddingTop: '134px' //114
							}}
							direction='right'
							in={credentials.onboarding ? !budgetState.onboardingTransition : !budgetState.editingTransition}
							timeout={800}
							mountOnEnter
							unmountOnExit
						>
							<div
								// className={classes.content}

								style={{
									flex: 1,
									// width 40px for the 20px margin on both sides
									width: isDesktop ? (openMenu ? `calc(100% - ${240 + 40}px)` : `calc(100% - ${spacing(8) + 40}px)`) : '100%',
									transition: budgetState.showTransitions
										? transitions.create('all', {
												easing: transitions.easing.sharp,
												duration: transitions.duration[openMenu ? 'enteringScreen' : 'leavingScreen']
										  })
										: null
								}}
							>
								{budgetState.tab === 'Overview' && <Overview />}
								{budgetState.tab === 'Budget Summaries' && <Summaries />}
								{budgetState.tab === 'What If' && <ComingSoon />}
								<Footer />
							</div>
						</Slide>
					)}

					{/* The user has already made a budget and onboarding is false in the database */}
					{!credentials.onboarding && !budgetState.editing && (
						<div className={classes.content}>
							{budgetState.tab === 'Overview' && <Overview />}
							{budgetState.tab === 'Budget Summaries' && <Summaries />}
							{budgetState.tab === 'What If' && <ComingSoon />}
							<Footer />
						</div>
					)}
				</FixedTopBar>
			</BudgetToolContextProvider>
		</Root>
	)
}

export default Budget
