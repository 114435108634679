import React, { useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Card, useMediaQuery } from '@mui/material'
import { TransactionTable } from '@mikeint0sh/the-keeton-cloud-components/finance'
import { GlobalContext } from '../../helpers/GlobalContext'
import { getRecentTransactions as apiGetRecentTransactions } from '../../helpers/api'

const PREFIX = 'TransactionTableController'
const classes = {
	gridItem: `${PREFIX}-gridItem`,
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	controlRow: `${PREFIX}-controlRow`
}

const Root = styled('div')(() => ({
	[`& .${classes.gridItem}`]: {
		margin: 0,
		width: '100%'
		//display: 'flex'
	},
	[`&.${classes.root}`]: {
		height: '100%'
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		padding: 10,
		width: '100%'
	},
	[`& .${classes.controlRow}`]: {
		margin: 0,
		paddingTop: 5,
		paddingBottom: 5
	}
}))

const TransactionTableController = ({ handelCreateTransaction }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { setRecentTransactions } = useContext(GlobalContext)

	useEffect(() => {
		const getTransactions = async () => {
			const result = await apiGetRecentTransactions()

			if (!result.errors) {
				setRecentTransactions(result.data)
			} else {
				// TODO: do something with the error
				console.error(result.errors)
			}
		}

		getTransactions()
		// eslint-disable-next-line
	}, [])

	return (
		<Root className={classes.root}>
			<Grid item container direction='row' spacing={4} xs={12} className={classes.gridItem}>
				{/* MainBudget View */}
				<Grid item className={classes.gridItem} style={{ display: 'flex', width: isDesktop ? 'calc(100% - 210px)' : '100%' }}>
					<TransactionTable GlobalContext={GlobalContext} handelCreateTransaction={handelCreateTransaction} />
				</Grid>

				{/* Control Panel */}
				<Grid item className={classes.gridItem} style={isDesktop ? { width: '210px' } : { width: 'calc(100% - 10px)' }}>
					<Card className={classes.card}>
						<Grid
							container
							direction='column'
							alignItems='center'
							sx={{ width: '100%', paddingRight: '5px', paddingTop: '15px', paddingBottom: '15px' }}
						>
							<Grid item className={classes.controlRow}>
								<Typography variant='h3'>Controls</Typography>
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
		</Root>
	)
}

export default TransactionTableController
