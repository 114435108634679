import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { NewTransactionFormContext } from '../NewTransactionFormContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const TypographyTitleStyled = styled(Typography)(({ theme: { palette } }) => ({
	textAlign: 'center',
	fontSize: '18px',
	color: palette.text.primary
}))

const TypographyToFromStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '18px',
	color: palette.text.secondary,
	paddingRight: 5
}))

//transferID will be undefined for the main transaction and be the transactionID for individual transactions
const TransferView = ({ transferID }) => {
	const { accounts } = useContext(GlobalContext)
	const { transactionFormState: state } = useContext(NewTransactionFormContext)

	/** Gets the Physical Account name that the source V-Account is in */
	const getSourcePhyAcc = () => {
		let pAccountName = ' - '

		accounts.every((physicalAccountArr) => {
			//Find the virtual account that is the transfer transaction's source
			physicalAccountArr.every((account) => {
				if (account.virtualAccountID === state.transaction.source.id) {
					pAccountName = physicalAccountArr[0].name
					return false //Break out of the loop
				}
				return true //Keep searching for the virtual account
			})
			if (pAccountName !== ' - ') {
				return false //Break out of the loop
			} else {
				return true //Keep searching for the virtual account
			}
		})

		return pAccountName
	}

	return (
		<Grid container direction='row' spacing={0} alignItems='center' justifyContent='flex-start' sx={{ paddingTop: '10px' }}>
			{/* Amount */}
			<Grid item sx={{ paddingRight: '5px' }}>
				<TypographyTitleStyled variant='body1' gutterBottom>
					{accounting.format(state.transfers[transferID].amount)}
				</TypographyTitleStyled>
			</Grid>

			{/* from Account Name */}
			<Grid item sx={{ paddingRight: '5px' }}>
				<Grid container direction='row' sx={{ flex: 1, paddingLeft: '0px' }}>
					<TypographyToFromStyled variant='body1' gutterBottom>
						{'from'}
					</TypographyToFromStyled>
					<TypographyTitleStyled variant='body1' gutterBottom>
						{state.type === 'transfer' ? getSourcePhyAcc() : state.destination.name}
					</TypographyTitleStyled>
				</Grid>
			</Grid>

			{/* to Account Name */}
			<Grid item sx={{ paddingRight: '5px' }}>
				<Grid container direction='row' sx={{ flex: 1, paddingLeft: '0px' }}>
					<TypographyToFromStyled variant='body1' gutterBottom>
						{'to'}
					</TypographyToFromStyled>

					<TypographyTitleStyled variant='body1' gutterBottom>
						{state.transfers[transferID].name}
					</TypographyTitleStyled>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TransferView
