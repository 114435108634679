import React, { useCallback } from 'react'
import { TextField } from '@mui/material'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

/**
 * Untested Robinhood style Currency text felid
 *
 * @param {function} onValueChange runs whenever a key is pressed inside the text felid
 * @param value for the Text field (unformatted)
 */
const MKCurrencyTextFelidB = ({
	className = '',
	max = Number.MAX_SAFE_INTEGER,
	onValueChange,
	style = {},
	value,
	name = '',
	error = false,
	helperText = ''
}) => {
	const valueAbsTrunc = Math.trunc(Math.abs(value))
	if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
		throw new Error(`invalid value property`)
	}

	const handleKeyDown = useCallback(
		(e) => {
			const { key, keyCode } = e
			if ((value === 0 && !VALID_FIRST.test(key)) || (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)) {
				return
			}
			const valueString = value.toString()
			let nextValue
			if (keyCode !== DELETE_KEY_CODE) {
				const nextValueString = value === 0 ? key : `${valueString}${key}`
				nextValue = Number.parseInt(nextValueString, 10)
			} else {
				const nextValueString = valueString.slice(0, -1)
				nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
			}
			if (nextValue > max) {
				return
			}
			//onValueChange(nextValue)
			onValueChange({ currentTarget: { name: name, value: nextValue } })
		},
		[max, onValueChange, name, value]
	)

	const handleChange = useCallback(() => {
		// DUMMY TO AVOID REACT WARNING
	}, [])

	const valueDisplay = (value / 100).toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD'
	})

	return (
		<TextField
			variant='standard'
			//fullWidth
			//placeholder='Category Name'
			inputProps={{ inputMode: 'numeric', style: { fontSize: 22, textAlign: 'left', maxWidth: 167 } }}
			size='medium'
			className={className}
			inputMode='numeric'
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			style={style}
			name={name}
			value={valueDisplay}
			error={error}
			helperText={helperText}
		/>
	)
}

export default MKCurrencyTextFelidB
