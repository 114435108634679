import React, { useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Card, Button, ToggleButton, ToggleButtonGroup } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { AccountsContext } from '../AccountsContext'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { getAccountBudgets as apiGetAccountBudgets } from '../../../helpers/api'

const PREFIX = 'AccountGraphView'
const classes = {
	gridItem: `${PREFIX}-gridItem`,
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	controlRow: `${PREFIX}-controlRow`,
	controlButton: `${PREFIX}-controlButton`,
	chart: `${PREFIX}-chart`
}

const Root = styled('div')(({ theme: { palette, spacing } }) => ({
	[`& .${classes.gridItem}`]: {
		// display: 'flex'
	},
	[`&.${classes.root}`]: {
		height: '100%',
		width: '100%'
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		marginTop: spacing(4),
		// paddingLeft: 10,
		// paddingRight: 5,
		width: '100%'
	},
	[`& .${classes.controlRow}`]: {
		margin: 0,
		paddingTop: 5,
		paddingBottom: 5
	},
	[`& .${classes.controlButton}`]: {
		color: palette.text.primary,
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: `${palette.text.primary}11`
		}
	},
	[`& .${classes.chart}`]: {
		flex: 1,
		paddingTop: 5,
		paddingBottom: 10,
		paddingLeft: 5
	}
}))

const AccountGraphView = () => {
	const { accountState, setAccountState, changeActiveView, chartData, setChartData } = useContext(AccountsContext)
	const { accounts, allBudgets, setAllBudgets } = useContext(GlobalContext)
	//const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })

	useEffect(() => {
		//Get the last 6 budgets with this v-account in it
		const fetch = async () => {
			const activeAccount =
				accounts[accountState.activeAccount.pIndex][
					accountState.activeAccount.vIndex === undefined ? 0 : accountState.activeAccount.vIndex
				]

			const result = await apiGetAccountBudgets(activeAccount.virtualAccountID) //  need virtualAccountID

			if (!result.errors) {
				//Set the new data from Firestore if it exists; else an empty obj:
				//Signifying that we have already fetched the data and it's not there

				let budgets = {}
				let balances = []
				let income = []
				let expenses = []
				let incomeTotal = 0
				let expenseTotal = 0
				result.data.forEach((budget) => {
					const time = new Date(budget.date._seconds * 1000 + 60000 * 60 * 4).getTime() //Convert timestamp from central to get the fist of the month
					budgets[budget.budgetID] = budget
					balances.push([time, budget[activeAccount.virtualAccountID].accountBalance])
					income.push([time, budget[activeAccount.virtualAccountID].amountFunded])
					expenses.push([time, budget[activeAccount.virtualAccountID].spent])
					incomeTotal += budget[activeAccount.virtualAccountID].amountFunded
					expenseTotal += budget[activeAccount.virtualAccountID].spent
				})

				setAccountState({
					...accountState,
					accountSummary: { in: incomeTotal, out: expenseTotal }
				})

				setAllBudgets({
					...allBudgets,
					months: {
						...allBudgets.months,
						...budgets
					}
				})

				setChartData({
					...chartData,
					series: [
						{
							name: 'Balance',
							data: balances
						},
						{
							name: 'Income',
							data: income
						},
						{
							name: 'Expense',
							data: expenses
						}
					]
				})
			} else {
				console.error('Get Account Budgets Errors', result.errors)
			}
		}

		//If the active account is a virtual account - fixes imbalance popup disappearing when data is fetched
		if (accountState.activeAccount.vIndex !== undefined) {
			fetch()
		}
		// eslint-disable-next-line
	}, [])

	const toggleTimePeriod = (newTimePeriod) => {
		let minMax = {}
		const today = new Date()
		const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1)
		let startMonth

		//  to-do: recalculate accountSummary in and out, within the selected time period

		switch (newTimePeriod) {
			case '3M':
				startMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth() - 2, 0)
				minMax = { min: startMonth.getTime(), max: thisMonth.getTime() }
				break
			case '6M':
				startMonth = new Date(thisMonth.getFullYear(), thisMonth.getMonth() - 5, 0)
				minMax = { min: startMonth.getTime(), max: thisMonth.getTime() }
				break
			case '1Y':
				startMonth = new Date(thisMonth.getFullYear() - 1, thisMonth.getMonth(), 0)
				minMax = { min: startMonth.getTime(), max: thisMonth.getTime() }
				break

			case 'all':
				minMax = { min: undefined, max: undefined }
				break
			default:
		}

		setChartData({
			...chartData,
			options: {
				...chartData.options,
				xaxis: {
					type: 'datetime',
					...minMax
				},
				selection: newTimePeriod
			}
		})
	}

	return (
		<Root className={classes.root}>
			<Grid item container direction='row' xs={12} className={classes.gridItem}>
				{/* MainBudget View */}
				<Grid item className={classes.gridItem} style={{ width: 'calc(100% - 210px)', paddingRight: 32 }}>
					<Card className={classes.card}>
						<Grid container direction='column' style={{ flex: 1, display: 'flex', paddingRight: 10 }} className={classes.chart}>
							{/* amountType Toggle */}
							<ToggleButtonGroup
								size='small'
								name='timePeriod'
								value={chartData.options.selection}
								exclusive
								onChange={(e) => toggleTimePeriod(e.currentTarget.value)}
								style={{ width: 300, height: 50, paddingTop: 15, paddingLeft: 30 }}
							>
								<ToggleButton name='timePeriod' value='3M'>
									3M
								</ToggleButton>
								<ToggleButton name='timePeriod' value='6M'>
									6M
								</ToggleButton>
								<ToggleButton name='timePeriod' value='1Y'>
									1Y
								</ToggleButton>
								<ToggleButton name='timePeriod' value='all'>
									All
								</ToggleButton>
							</ToggleButtonGroup>
							<ReactApexChart options={chartData.options} series={chartData.series} type='line' width='100%' />
						</Grid>
					</Card>
				</Grid>

				{/* Control Panel */}
				<Grid item className={classes.gridItem} style={{ width: 210 }}>
					<Card className={classes.card}>
						<Grid
							container
							direction='column'
							alignItems='center'
							style={{ width: '100%', paddingRight: 5, paddingTop: 15, paddingBottom: 15 }}
						>
							<Grid item className={classes.controlRow}>
								<Typography variant='h3'>Controls</Typography>
							</Grid>
							<Grid item className={classes.controlRow}>
								<Button className={classes.controlButton} onClick={() => changeActiveView('Overview')}>
									Go Back
								</Button>
							</Grid>
							{/* 
							<ControlItem name={['Section', 'Progress']} property='sectionProgress' />
							<ControlItem name={['Category', 'Progress']} property='categoryProgress' />

							<Grid item className={classes.controlRow} style={{ paddingTop: 30 }}>
								<Typography style={{ fontWeight: 300, fontSize: 22 }}>{'Basics & Wants'}</Typography>
							</Grid>

							<ControlItem name={['Budgeted']} property='budgetedBW' />
							<ControlItem name={['Spent']} property='spentBW' />
							<ControlItem name={['Surplus/', 'Deficit']} property='surplusDeficitBW' />
							<ControlItem name={['Account', 'Balance']} property='accountBalanceBW' />

							<Grid item className={classes.controlRow} style={{ paddingTop: 30 }}>
								<Typography style={{ fontWeight: 300, fontSize: 22 }}>{'Savings'}</Typography>
							</Grid>

							<ControlItem name={['Budgeted']} property='budgetedS' />
							<ControlItem name={['Saved']} property='savedS' />
							<ControlItem name={['Spent']} property='spentS' />
							<ControlItem name={['Goal']} property='goalS' />
							<ControlItem name={['Account', 'Balance']} property='accountBalanceS' /> */}
						</Grid>
					</Card>
				</Grid>
			</Grid>
		</Root>
	)
}

export default AccountGraphView
