import React, { useContext } from 'react'
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'
import { Main, Minimal } from './layouts'
import { setupApi } from './helpers/api'

//Components
import { MKRoute } from '@mikeint0sh/the-keeton-cloud-components/components'

//Pages
import { Home, Dashboard, Accounts, Bills, Budget, Transactions, Goals, IOUs, Settings } from './views'
import { BudgetTool, InvestTool } from './views/Tools'
import { PageNotFound } from '@mikeint0sh/the-keeton-cloud-components/views'

//Contexts
import { GlobalContext } from './helpers/GlobalContext'
import { MainContextProvider } from '@mikeint0sh/the-keeton-cloud-components/layouts/Main'

import BudgetToolCP from './views/Tools/BudgetTool/BudgetToolContext'
import BudgetCP from './views/Budget/BudgetContext'
import AccountsCP from './views/Accounts/AccountsContext'

const Routes = ({ theme, tDM }) => {
	const MainLayout = { layout: Main, layoutContext: MainContextProvider, toggle: tDM }
	//For some reason the Minimal Layout needs MainContextProvider - because it has a component using GlobalContext?
	const MinimalLayout = { layout: Minimal, layoutContext: MainContextProvider, toggle: tDM }
	const { setLoggedIn } = useContext(GlobalContext)

	document.documentElement.style.setProperty('--viewport-bg', theme.palette.background)

	setupApi(setLoggedIn)

	return (
		<ReactRoutes>
			<Route path='/' element={<MKRoute component={Home} {...MinimalLayout} />} />
			<Route
				path='/tools/budget'
				element={<MKRoute component={BudgetTool} {...MinimalLayout} componentContext={BudgetToolCP} />}
			/>
			<Route path='/tools/invest' element={<MKRoute component={InvestTool} {...MinimalLayout} />} />
			<Route path='/dashboard' element={<MKRoute component={Dashboard} {...MainLayout} />} />
			<Route path='/accounts' element={<MKRoute component={Accounts} {...MainLayout} componentContext={AccountsCP} />} />
			<Route path='/bills' element={<MKRoute component={Bills} {...MainLayout} />} />
			<Route path='/budget' element={<MKRoute component={Budget} {...MainLayout} componentContext={BudgetCP} noFooter />} />
			<Route path='/transactions' element={<MKRoute component={Transactions} {...MainLayout} />} />
			<Route path='/goals' element={<MKRoute component={Goals} {...MainLayout} />} />
			<Route path='/iou' element={<MKRoute component={IOUs} {...MainLayout} />} />
			<Route path='/settings' element={<MKRoute component={Settings} {...MainLayout} />} />
			<Route
				path='/404'
				element={<MKRoute component={PageNotFound} componentProps={{ colors: '#02E8BB, #10A0FF, #F22822' }} {...MinimalLayout} />}
			/>
			<Route path='*' element={<Navigate replace to='/404' />} />
		</ReactRoutes>
	)
}

export default Routes
