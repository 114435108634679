import { globalPalette } from './globalPalette'

export const darkPalette = {
	black: globalPalette.black,
	white: globalPalette.white,
	mode: 'dark',
	primary: {
		contrastText: globalPalette.primary.contrastText, //black
		main: globalPalette.primary.dark,
		dark: globalPalette.primary.dark,
		light: globalPalette.primary.light
	},
	secondary: {
		contrastText: globalPalette.secondary.contrastText,
		dark: globalPalette.secondary.dark,
		main: globalPalette.secondary.dark,
		light: globalPalette.secondary.light
	},
	success: {
		contrastText: globalPalette.success.contrastText,
		dark: globalPalette.success.dark,
		main: globalPalette.success.dark,
		light: globalPalette.success.light
	},
	info: {
		contrastText: globalPalette.info.contrastText,
		dark: globalPalette.info.dark,
		main: globalPalette.info.dark,
		light: globalPalette.info.light
	},
	warning: {
		contrastText: globalPalette.warning.contrastText,
		dark: globalPalette.warning.dark,
		main: globalPalette.warning.dark,
		light: globalPalette.warning.light
	},
	error: {
		contrastText: globalPalette.error.contrastText,
		dark: globalPalette.error.dark,
		main: globalPalette.error.dark,
		light: globalPalette.error.light
	},
	text: {
		primary: globalPalette.text.primaryDark,
		secondary: globalPalette.text.secondaryDark,
		tertiary: globalPalette.text.primaryLight,
		link: globalPalette.text.light
	},
	tile: globalPalette.tile.dark,
	background: globalPalette.background.dark,
	menu: globalPalette.menu.dark
}
