import axios from 'axios'

/** Prefixes all api calls with the base ural and tells the browser to send along the cookie with the token */
const api = axios.create({
	withCredentials: true,
	baseURL: 'https://finance.keeton.cloud/api/'
})

export const setupApi = (setLoggedIn) => {
	axios.defaults.headers.post['cache-control'] = 'private'

	api.interceptors.response.use(
		(response) => {
			setLoggedIn(true)
			return response
		},
		(error) => {
			//Run after every api call to check for authorization
			if (error.response && error.response.status === 401) {
				setLoggedIn(false)
			} else {
				setLoggedIn(true)
			}
			return Promise.reject(error)
		}
	)
}

/** Logs in a user or sends errors */
export const login = async (email, password, rememberMe) => {
	const result = {}
	try {
		const res = await api.post('login', { email, password, rememberMe })
		result.data = res.data
	} catch (err) {
		if (err) {
			result.errors = err.response ? err.response.data : err //Sends along errors to UI (email not valid, can not be blank, wrong credentials, etc.)
		} else {
			result.errors = { general: 'Something went wrong, please try again' }
		}
	}
	return result
}

export const logout = async () => {
	const result = {}
	try {
		const res = await api.post('logout')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get user details */
export const getUserDetails = async () => {
	const result = {}
	try {
		const res = await api.get('user')
		const { userID, firstName, lastName, email, username, image, birthday, onboarding } = res.data.details
		result.data = { userID, firstName, lastName, email, username, image, birthday, onboarding } //Sent back username, profile photo, email and user id
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Saves a new or existing budget to the database or sends errors
 * @param name: The name of the budget ie. Q1-2021
 * @param type: The type of budget being saved ie. 'current' current month's budget
 * @param budget: The local state of the budget
 * @param typeRatios: The percentages of money allocated to each type
 */
export const saveBudget = async (name, type, budget, typeRatios, onboarding) => {
	const result = {}
	try {
		const res = await api.post('budget/save', { name, type, budget, typeRatios: typeRatios, onboarding: onboarding })
		result.data = res.data
	} catch (err) {
		if (err) {
			result.errors = err.response ? err.response.data : err //Sends along errors to UI
		} else {
			result.errors = { general: 'Something went wrong, please try again' }
		}
	}
	return result
}

/** Get Budget Overview data */
export const getBudgetOverview = async () => {
	const result = {}
	try {
		const res = await api.get('budget/overview')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get Accounts data */
export const getAccounts = async () => {
	const result = {}
	try {
		const res = await api.get('accounts')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Re-assign a virtual account to a new physical account */
export const reassignAccount = async ({ accountID, from, to }) => {
	const result = {}
	try {
		const res = await api.post('accounts/reassign', { accountID, from, to })
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Create/Update a physical account */
export const updatePhysicalAccount = async ({ name, type }) => {
	const result = {}
	try {
		const res = await api.post('accounts/update/physical', { name, type })
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Changes a user's onboarding status to false */
export const updateOnboarding = async () => {
	try {
		const res = await api.post('user/update/onboarding')
		console.log('Onboarding successful', res.status)
	} catch (err) {
		const errors = err.response ? err.response : err
		console.error('Onboarding unsuccessful', errors)
	}
}

/** Record a new transaction */
export const createTransaction = async (transaction, accounts) => {
	const result = {}
	try {
		const res = await api.post('transactions/create', { transaction, accounts })
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Record a follow-up transaction for leftover income */
export const assignLeftoverIncome = async (transaction, individualAccounts, accounts) => {
	const result = {}
	try {
		const res = await api.post('transactions/create/leftover', { transaction, individualAccounts, accounts })
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Record a follow-up transaction for leftover income */
export const fixAccountImbalance = async ({ amount, source, destination }) => {
	const result = {}
	try {
		const res = await api.post('transactions/create/fix-imbalance', { amount: amount, source, destination })
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get Recent Transaction data */
export const getRecentTransactions = async () => {
	const result = {}
	try {
		const res = await api.get('transactions')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Search for an Expense with a given name */
export const searchExpenseNames = async (indexType, searchTerm) => {
	const result = {}
	try {
		const res = await api.get(`transactions/search/${indexType}/${searchTerm}`)
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get all budgets with virtual account */
export const getAccountBudgets = async (virtualAccountID) => {
	const result = {}
	try {
		const res = await api.get(`budget/account/${virtualAccountID}`)
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}
