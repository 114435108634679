import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Card, Paper, FormControl, Select } from '@mui/material'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'AccountTile'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	balanceFont: `${PREFIX}-balanceFont`,
	error: `${PREFIX}-error`
}

const GridStyled = styled(Grid)(({ theme: { breakpoints, palette } }) => ({
	[`& .${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.card}`]: {
		paddingTop: 10,
		paddingLeft: 10,
		paddingRight: 10,
		paddingBottom: 0,
		[breakpoints.up('xs')]: {
			height: 100
		},
		[breakpoints.up('sm')]: {
			height: 120
		},
		[breakpoints.up('md')]: {
			height: 110
		},
		[breakpoints.up('lg')]: {
			height: 130
		},
		[breakpoints.up('xl')]: {
			height: 150
		}
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		textAlign: 'left'
	},
	//Defines the font of the typography inside the progress circle
	[`& .${classes.balanceFont}`]: {
		flex: 1,
		fontWeight: 400,
		[breakpoints.up('xs')]: {
			fontSize: 22
		},
		[breakpoints.up('sm')]: {
			fontSize: 24
		},
		[breakpoints.up('md')]: {
			fontSize: 22
		},
		[breakpoints.up('lg')]: {
			fontSize: 26
		},
		[breakpoints.up('xl')]: {
			fontSize: 30
		}
	},
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center',
		marginTop: '5px'
	}
}))

function AccountContent(props) {
	const { accounts, reassignAccount } = useContext(GlobalContext)
	const { accountState, setAccountState } = useContext(AccountsContext)
	const [phyAccountBallance, setPhyAccountBallance] = useState('')

	/** Reassigns virtual account to the new selected physical account */
	const handleAccountAssignment = async (event) => {
		const newPhysicalAccountID = event.currentTarget.value //Gets the P-Acc. ID of for the V-Acc. that was selected
		if (newPhysicalAccountID === 'addAccount') {
			//Trigger AddAccountPopup with the current V-Acc.'s ID and current location
			setAccountState({
				...accountState,
				popupTriggeredData: { accountID: props.virtualAccountID, from: props.physicalAccountID },
				showAccountPopup: true
			})
		} else {
			//Reassign account, from physical account, to an existing physical account
			const result = await reassignAccount(props.virtualAccountID, props.physicalAccountID, newPhysicalAccountID)
			if (result.errors) {
				console.error('Errors:', result.errors)

				// do something present site error ??
			}
		}
	}

	/** Calculate each Physical Account's total */
	const getPhysicalAccountTotal = (physicalAccountID) => {
		let accountTotal = 0
		accounts.every((physicalAccountArr) => {
			if (physicalAccountArr[0].physicalAccountID === physicalAccountID) {
				physicalAccountArr.forEach((account) => {
					accountTotal += account.balance
				})
				return false
			}
			return true
		})
		return accounting.format(accountTotal)
	}

	useEffect(() => {
		const totalStr = getPhysicalAccountTotal(props.physicalAccountID)
		setPhyAccountBallance(totalStr)
		// eslint-disable-next-line
	}, [accounts])

	return (
		<Grid container direction='column' justifyContent='center' sx={{ height: '100%' }}>
			{/* Name of Account */}
			<Grid item sx={{ width: '100%' }}>
				<Typography variant='body1' className={classes.title}>
					{props.name}
				</Typography>
			</Grid>

			<Grid item sx={{ flex: 2 }} />
			{/* Balance of Account */}
			<Typography
				variant='h3'
				className={classes.balanceFont}
				sx={{
					color: props.type !== undefined && props.balance !== undefined && props.balance !== 0 ? 'warning.main' : 'text.primary'
				}}
			>
				{/* Show total of Virtual Account */}
				{props.type === undefined && accounting.format(props.balance)}
				{/* balance is undefined when type is unassigned */}
				{/* Show total of just the unbalance (props.balance) in physical account */}
				{props.type !== undefined && props.balance !== undefined && props.balance !== 0 && accounting.format(props.balance)}
				{/* Get total of all virtual accounts */}
				{props.type !== undefined && props.balance !== undefined && props.balance === 0 && phyAccountBallance}
			</Typography>

			{/* Reassignment drop down if the Virtual Account is unassigned */}
			{!props.type && props.physicalAccountID === 'unassigned' && <Grid item className={classes.root} />}
			<Grid item sx={{ flex: 3, paddingBottom: '5px' }}>
				{!props.type && props.physicalAccountID === 'unassigned' && (
					<FormControl variant='standard'>
						<Select
							native
							name='assignAccount'
							value={'none'}
							onChange={handleAccountAssignment}
							label='Assign Physical Account'
							inputProps={{
								name: 'assignAccount',
								id: 'outlined-age-native-simple'
							}}
						>
							{/* Title */}
							<option disabled value={'none'}>
								Assign Account
							</option>

							{/* Shows all possible physical accounts */}
							{accounts.map((physicalAccountArr, i) => {
								//If the physical account is not the current one ie. unassigned:
								if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
									return (
										<option value={physicalAccountArr[0].physicalAccountID} key={i}>
											{physicalAccountArr[0].name}
										</option>
									)
								}
								return null
							})}

							<option value={'addAccount'}>+ New Physical Account</option>
						</Select>
					</FormControl>
				)}
			</Grid>
		</Grid>
	)
}

const AccountTile = (props) => {
	return (
		<GridStyled container justifyContent='center' alignItems='center' className={classes.root}>
			<Grid item className={classes.root}>
				<Card className={!props.type ? classes.card : ''}>
					{props.type && (
						// Shows a colored border for the physical accounts
						<Paper
							className={classes.card}
							variant='outlined'
							sx={{
								borderRadius: '8px',
								borderWidth: '2px',
								borderColor:
									props.type === 'checking'
										? 'secondary.main'
										: props.type === 'unassigned'
										? 'text.secondary.main'
										: 'primary.main'
							}}
						>
							<AccountContent {...props} />
						</Paper>
					)}

					{!props.type && <AccountContent {...props} />}
				</Card>
			</Grid>
		</GridStyled>
	)
}

export default AccountTile
