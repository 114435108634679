import React, { useContext, useEffect, useState } from 'react'
import { TextField, RadioGroup, FormControlLabel, FormControl, Radio } from '@mui/material'

import { NewTransactionFormContext } from './NewTransactionFormContext'
import { GlobalContext } from '../../../helpers/GlobalContext'

const IncomeSourceSelection = () => {
	const { incomeSources, accounts } = useContext(GlobalContext)
	const { transactionFormState: state, setTransactionFormState: setState } = useContext(NewTransactionFormContext)
	const [newSource, setNewSource] = useState('') //Holds the name of a new Income source
	const [numSources, setNumSources] = useState(0) //Holds the number Income sources to tell when they populate ie. 0 -> n

	/** Updating the source selection */
	const handleSourceChange = (event) => {
		const newValue = event.currentTarget.value

		if (incomeSources[newValue] === undefined) {
			setState({ ...state, source: { id: '', name: newValue } }) //New income source
		} else {
			//Existing: new value is the id
			setState({ ...state, source: { id: newValue, name: incomeSources[newValue].name } })
		}
	}

	//Sets the first source as active when the income sources first load
	useEffect(() => {
		//Set the first source as active when the income sources first load
		if (Object.entries(incomeSources).length !== numSources) {
			setNumSources(Object.entries(incomeSources).length) //Update the number of sources
			//Set the first source to be active
			setState({
				...state,
				source: { id: Object.keys(incomeSources)[0], name: incomeSources[Object.keys(incomeSources)[0]].name }
			})
		}

		// eslint-disable-next-line
	}, [incomeSources])

	//Sets the default account when source changes
	useEffect(() => {
		//Set the default account for the newly chosen income source, if it exists
		let destinationState = {}
		if (Object.entries(incomeSources).length !== 0) {
			accounts.forEach((physicalAccountArr) => {
				//If the default account for this source exists in local state:
				if (physicalAccountArr[0].physicalAccountID === incomeSources[Object.keys(incomeSources)[0]].defaultAccount) {
					//Set it as the active destination account
					destinationState = {
						destination: {
							virtualAccountID: incomeSources[Object.keys(incomeSources)[0]].defaultAccount,
							name: physicalAccountArr[0].name
						}
					}
					return false //Stop looping
				}
				return true //Keep looping
			})
		}

		//Set the updated account selection
		setState({
			...state,
			...destinationState
		})
		// eslint-disable-next-line
	}, [state.source.id])

	/** Updates the new Income source text felid value */
	const handleSourceTextFelidChange = (event) => {
		setNewSource(event.currentTarget.value)
		setState({ ...state, source: { id: '', name: event.currentTarget.value } })
	}

	return (
		<FormControl component='fieldset'>
			<RadioGroup aria-label='Source' name='source' value={state.source.id} onChange={handleSourceChange}>
				{/* List out all existing income sources */}
				{Object.keys(incomeSources).map((id) => {
					return (
						<FormControlLabel
							value={id}
							name={incomeSources[id].name}
							control={<Radio color='primary' />}
							label={incomeSources[id].name}
							key={id}
							sx={{ paddingLeft: '11px' }}
						/>
					)
				})}

				{/* New Income source */}
				<FormControlLabel
					value={newSource}
					onChange={handleSourceChange}
					checked={state.source.id === ''}
					control={<Radio name='source' color='primary' />}
					label={
						<TextField
							variant='standard'
							autoComplete='off'
							placeholder='New Source'
							margin='normal'
							onChange={handleSourceTextFelidChange}
							error={state.errors.source !== undefined}
							helperText={state.errors.source}
							sx={{ margin: 0 }}
						/>
					}
					sx={{ margin: 0 }}
				/>
			</RadioGroup>
		</FormControl>
	)
}
export default IncomeSourceSelection
