import React, { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { BudgetTool } from '../../Tools/BudgetTool'
import { BudgetToolContext } from '../../Tools/BudgetTool/BudgetToolContext'
import { GlobalContext } from '../../../helpers/GlobalContext'

const Root = styled('div')(() => ({
	flex: 1,
	display: 'flex'
}))

/**
 * Takes the Firebase formatted data and converts it to local Budget Tool state so that it can be edited
 * @param activeBudget: is the budget from Firestore, saved in allBudgets that needs to be formatted for localState editing
 */
const loadEditableBudget = (activeBudget) => {
	let basics = []
	let wants = []
	let savings = []

	let finiteAmountBudgeted = 0
	let overallTotalPercent = 0
	let overallRemainingPercent = 0
	let nextPriority = 0

	Object.keys(activeBudget).forEach((key) => {
		if (key !== 'date') {
			const type = activeBudget[key].type
			let category = {
				name: activeBudget[key].name,
				accountID: key,
				spent: activeBudget[key].spent,
				accountBalance: activeBudget[key].accountBalance,
				amountFunded: activeBudget[key].amountFunded,
				amountType: activeBudget[key].amountType,
				amount: activeBudget[key].amountType === 'finite' ? activeBudget[key].amount : 0,
				percentType: activeBudget[key].amountType === 'finite' ? 'total' : activeBudget[key].percentType,
				percentage: activeBudget[key].amountType === 'finite' ? 0 : activeBudget[key].amount,
				priority: activeBudget[key].priority
			}

			if (type !== 'savings') {
				let lastProperties = {
					subCategories: [] //Store as [[accountID, name]]; and a map of maps in Firestore with accountID: name
				}

				Object.keys(activeBudget[key].subcategories).forEach((subKey) => {
					lastProperties.subCategories.push([
						subKey,
						activeBudget[key].subcategories[subKey].name,
						activeBudget[key].subcategories[subKey].spent
					])
				})

				category = {
					...category,
					...lastProperties
				}
				if (type === 'basics') {
					basics.push(category)
				} else {
					//type === wants
					wants.push(category)
				}
			} else {
				category = {
					...category,
					goal: activeBudget[key].goal
				}
				savings.push(category)
			}

			if (activeBudget[key].amountType === 'finite') {
				finiteAmountBudgeted += activeBudget[key].amount
			} else if (activeBudget[key].percentType === 'total') {
				overallTotalPercent += activeBudget[key].amount
			} else {
				//percentType === remaining
				overallRemainingPercent += activeBudget[key].amount
			}

			if (activeBudget[key].priority >= nextPriority) {
				nextPriority = activeBudget[key].priority + 1
			}
		}
	})

	return {
		finiteAmountBudgeted: finiteAmountBudgeted,
		overallTotalPercent: overallTotalPercent,
		overallRemainingPercent: overallRemainingPercent,
		nextPriority: nextPriority,

		basics: basics,
		wants: wants,
		savings: savings
	}
}

const EditBudgetView = () => {
	const { state, setState, setIncomeState, setBudgetState } = useContext(BudgetToolContext)
	const { activeBudget } = useContext(GlobalContext)
	const [show, setShow] = useState(false)
	useEffect(() => {
		setState({
			...state,
			onboarding: false
		})
		setBudgetState(loadEditableBudget(activeBudget.budget))
		setIncomeState({
			incomeType: 'net',
			income: 0,
			showAlert: false
		})
		setTimeout(() => {
			setShow(true)
		}, 150)
		// eslint-disable-next-line
	}, [])

	return <Root>{show && <BudgetTool console={true} showPrompt editMode={true} />}</Root>
}

export default EditBudgetView
