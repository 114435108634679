import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { ChevronLeft, DashboardRounded, AccountBalance, DonutLarge, AttachMoney, Settings } from '@mui/icons-material'
import { Main as MKMain } from '@mikeint0sh/the-keeton-cloud-components/layouts/Main'
import { GlobalContext } from '../helpers/GlobalContext'
import { getUserDetails as apiGetUserDetails, logout as apiLogout } from '../helpers/api'

const Main = (props) => {
	const { palette } = useTheme()
	const { setLoggedIn, clearContext } = useContext(GlobalContext)
	const navigate = useNavigate() //Allows to push to different routes

	const pages = [
		{
			title: 'Dashboard',
			href: '/Dashboard',
			icon: <DashboardRounded style={{ width: 28, height: 28 }} />
		},

		{
			title: 'Accounts',
			href: '/Accounts',
			icon: <AccountBalance style={{ width: 28, height: 28 }} />
		},
		// {
		// 	title: 'Goals',
		// 	href: '/Goals',
		// 	icon: <Adjust style={{ width: 28, height: 28 }} />
		// },
		{
			title: 'Budget',
			href: '/Budget',
			icon: <DonutLarge style={{ width: 28, height: 28 }} />
		},
		{
			title: 'Transactions',
			href: '/Transactions',
			icon: <AttachMoney style={{ width: 28, height: 28 }} />
		},
		// {
		// 	title: 'Bills',
		// 	href: '/Bills',
		// 	icon: <Receipt style={{ width: 28, height: 28 }} />
		// },
		// {
		// 	title: 'IOUs',
		// 	href: '/IOU',
		// 	icon: <Group style={{ width: 28, height: 28 }} />
		// },
		{
			title: 'Settings',
			href: '/Settings',
			icon: <Settings style={{ width: 28, height: 28 }} />
		}
	]

	const logout = async () => {
		await apiLogout()
		setLoggedIn(false)
		navigate('/')
		clearContext()
	}

	return (
		<MKMain
			GlobalContext={GlobalContext}
			apiGetUserDetails={apiGetUserDetails}
			AuthorizingProps={{ image: '/images/cloud-logo.png', title: 'The Keeton Cloud', titleTwo: 'Finance' }}
			SideBarProps={{ pages: pages, BackIcon: <ChevronLeft color='secondary' /> }}
			TopBarProps={{
				menuImage: `/images/${palette.mode === 'dark' ? 'menuDark' : 'menuLight'}.svg`,
				logoImage: '/images/cloud-logo.png',
				title: 'The Keeton Cloud',
				titleTwo: 'Finance',
				titleLink: '/',
				toggleDarkMode: props.toggleDarkMode,
				logout: logout
			}}
			noFooter={props.noFooter}
		>
			{props.children}
		</MKMain>
	)
}

export default Main
