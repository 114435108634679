import React, { useContext } from 'react'
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const AccountsDropDown = ({ sourceDest, label, type }) => {
	const { accounts } = useContext(GlobalContext)
	const { transactionFormState: state, handleDropDownChange } = useContext(NewTransactionFormContext)

	return (
		<FormControl variant='outlined' error={state.errors[sourceDest] ? true : false}>
			<InputLabel>{label}</InputLabel>
			<Select
				native
				name={sourceDest} //Either source or destination
				value={`${state[sourceDest][sourceDest === 'source' ? 'id' : 'virtualAccountID']}__#&&#__${state[sourceDest].name}`} //combines the id with the name to pass to the handler
				onChange={(e) => handleDropDownChange(e, sourceDest)}
				label={label}
				inputProps={{ name: sourceDest }}
			>
				{/* Title */}
				<option value=''></option>
				{accounts.map((physicalAccountArr) => {
					if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
						if (type !== 'physical') {
							//Show all virtual accounts except for those unassigned
							return physicalAccountArr.map((account, accountIndex) => {
								if (accountIndex !== 0) {
									return (
										<option value={`${account.virtualAccountID}__#&&#__${account.name}`} key={account.virtualAccountID}>
											{account.name}
										</option>
									)
								} else {
									return (
										<option
											disabled
											value={`${account.physicalAccountID}__#&&#__${account.name}`}
											key={account.physicalAccountID}
										>
											{account.name}
										</option>
									)
								}
							})
						} else {
							//Show all physical accounts
							return (
								<option
									value={`${physicalAccountArr[0].physicalAccountID}__#&&#__${physicalAccountArr[0].name}`}
									key={physicalAccountArr[0].physicalAccountID}
								>
									{physicalAccountArr[0].name}
								</option>
							)
						}
					}
					return null
				})}
			</Select>

			{/* Shows relevant To/From errors to the user */}
			{state.errors[sourceDest] && <FormHelperText>{state.errors[sourceDest]}</FormHelperText>}
		</FormControl>
	)
}
export default AccountsDropDown
