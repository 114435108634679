import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	InputAdornment,
	IconButton,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { Alert } from '../../../components'
import MKCurrencyTextFieldA from '../../../components/MKCurrencyTextFieldA'
import { BudgetToolContext } from './BudgetToolContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'Income'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	error: `${PREFIX}-error`,
	message: `${PREFIX}-message`,
	submitRow: `${PREFIX}-submitRow`,
	btnProgress: `${PREFIX}-btnProgress`,
	toggleButton: `${PREFIX}-toggleButton`
}

const Root = styled('div')(({ theme: { palette } }) => ({
	[`&.${classes.root}`]: {
		height: '100%'
	},
	[`& .${classes.card}`]: {
		maxWidth: 400,
		paddingLeft: 15,
		paddingRight: 15,
		margin: 10,
		borderRadius: 8,
		transition: '0.3s',
		boxShadow: '5px 15px 40px 2px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0px 20px 50px 3px rgba(0,0,0,0.3)'
		}
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '24px',
		marginBottom: 0
	},
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center'
	},
	[`& .${classes.message}`]: {
		fontSize: '15px',
		color: palette.secondary.main,
		textAlign: 'center'
	},
	[`& .${classes.submitRow}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	},
	[`& .${classes.toggleButton}`]: {
		fontSize: '14px',
		lineHeight: '24px',
		color: palette.text.secondaryDark,
		textTransform: 'none',
		width: '100%',
		'&.Mui-selected': {
			backgroundColor: palette.primary.main,
			color: palette.white,
			'&:hover': {
				backgroundColor: palette.primary.main,
				color: palette.white
			}
		},
		'&:hover': {
			backgroundColor: 'rgba(33, 137, 214, 0.14)'
		},

		padding: '7px 17px'
	}
}))

const Income = ({ showPrompt }) => {
	const { state, setState, incomeState, setIncomeState } = useContext(BudgetToolContext)

	let incomePrompt
	if (incomeState.incomeType === 'net') {
		incomePrompt = 'Enter your monthly take home income (Net)'
	} else {
		incomePrompt = 'Enter your annual income before taxes & deductions (Gross)'
	}

	const infoMessage = `Monthly Net: The amount of money that actually goes into your bank account after taxes and other deductions (such as taxes and retirement) are taken out. \n
   Annual Gross: The amount of mony that you where promised when you were hired. The amount that you get before taxes, insurance, and retirement are taken out.`

	const handleAlert = (show) => {
		setIncomeState({ ...incomeState, showAlert: show })
	}

	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		//Check for valid input
		if (incomeState.income > 0) {
			setState({ ...state, errors: {}, page: state.page + 1 }) //Increment step in onboarding (and trigger slide animation)

			// this may be incorrect for handling gross income - How many pages will there be fore gross and isn't it handled by the parent not the Income component ??

			if ((incomeState.incomeType === 'net' && state.page === 1) || (incomeState.incomeType === 'gross' && state.page === 3)) {
				setTimeout(() => {
					setState({ ...state, errors: {}, onboarding: false }) //Stop onboarding and switch to the budget view
				}, 400) //After animation finishes
			}
		} else {
			//Set errors in local state
			setState({ ...state, errors: { general: 'Please enter a value for income, to build a budget' } })
		}
	}

	/** Updates the state when the text felids or toggleButton change */
	const handleToggleChange = (event) => {
		if (event.currentTarget.value === 'gross') {
			// temporary until gross functionality is developed

			setState({
				...state,
				errors: { general: 'Gross income calculations are coming soon, please use your net income for now. Thank you!' }
			})
		} else {
			setState({ ...state, errors: {} })
		}

		setIncomeState({ ...incomeState, incomeType: event.currentTarget.value })
	}

	/** Updates the state when the text felids or toggleButton change */
	const handleIncomeChange = (event) => {
		setIncomeState({ ...incomeState, income: accounting.unformat(event.currentTarget.value) })
	}

	return (
		<Root className={classes.root}>
			<Alert open={incomeState.showAlert} title='Income' description={infoMessage} handleAlert={() => handleAlert(false)} />

			<Grid container direction='column' alignItems='center' justifyContent='center' sx={{ height: '85%' }}>
				<Grid item>
					<Card className={classes.card}>
						<CardContent>
							<form noValidate onSubmit={handleSubmit}>
								{showPrompt && (
									<Typography variant='h3' sx={{ textAlign: 'center', paddingBottom: '10px' }}>
										Start Budgeting
									</Typography>
								)}

								<MKCurrencyTextFieldA
									maskOptions={{ placeholder: '$0.00', prefix: '' }}
									maxWidth={9999}
									sx={{ paddingBottom: '10px' }}
									inputProps={{ sx: { fontSize: 54, textAlign: 'center' } }}
									InputProps={{
										sx: { textAlign: 'center', fontSize: 54 },
										startAdornment: (
											<InputAdornment position='start'>
												<Typography sx={{ fontSize: 45 }}>$</Typography>
											</InputAdornment>
										)
									}} //onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
									onChange={handleIncomeChange}
									value={incomeState.income} // budgetState[type.toLowerCase()][index]  amountType === 'percent' ? getPercentage(percentType, percentage) :
									placeholder='$0.00'
								/>

								<Typography
									sx={{ fontSize: 16, textAlign: 'center', paddingBottom: incomeState.incomeType === 'net' ? '31px' : '10px' }}
								>
									{incomePrompt}
								</Typography>

								{state.errors.general && (
									<Typography
										variant='body2'
										className={incomeState.incomeType === 'gross' ? classes.message : classes.error} // until gross functionality comes
										align='center'
									>
										{state.errors.general}
									</Typography>
								)}

								<Grid container direction='row' alignItems='center' justifyContent='space-around' className={classes.submitRow}>
									<Grid item sx={{ paddingRight: '10px', marginLeft: '-5px' }}>
										<IconButton color='primary' onClick={() => handleAlert(true)} sx={{ padding: '0px' }} size='large'>
											<InfoOutlinedIcon />
										</IconButton>
									</Grid>
									<Grid item sx={{ paddingRight: '7px' }}>
										<ToggleButtonGroup value={incomeState.incomeType} exclusive onChange={handleToggleChange}>
											<ToggleButton name='incomeType' size='small' value='net' className={classes.toggleButton}>
												Net
											</ToggleButton>
											<ToggleButton name='incomeType' size='small' value='gross' className={classes.toggleButton}>
												Gross
											</ToggleButton>
										</ToggleButtonGroup>
									</Grid>
									<Grid item md sx={{ paddingLeft: '7px' }}>
										<Button
											fullWidth
											type='submit'
											variant='contained'
											color='primary'
											sx={{ color: '#FFF', fontSize: 16 }}
											disabled={incomeState.incomeType === 'gross'} // temporary until feature is available
										>
											Next
										</Button>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Root>
	)
}

export default Income
