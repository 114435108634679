import React, { createContext, useState } from 'react'
import { createTransaction as apiCreateTransaction, assignLeftoverIncome as apiAssignLeftoverIncome } from '../../../helpers/api'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

export const NewTransactionFormContext = createContext()

const NewTransactionFormContextProvider = (props) => {
	//Initial state for Form
	const [transactionFormState, setTransactionFormState] = useState({
		type: 'expense',
		source: { id: '', name: '' },
		subCategoryID: '', //Only used for expenses assigned to a subcategory
		destination: { virtualAccountID: '', name: '' },
		name: '',
		details: '',
		amount: 0,
		date: new Date(),
		distribution: 'fillBudget',
		individualAccounts: [{ amount: 0, virtualAccountID: '', name: '' }], //[{amount, virtualAccountID, name}]
		showWarnings: false, //Shown if the user tries to set a date in the future or with an amount of $0.00
		loading: false,
		warning: false,
		showResult: false,
		errors: {}
	})

	/** General function for setting the form state */
	const handleFormChange = (event) => {
		const name = event.currentTarget.name
		const newValue = event.currentTarget.value

		//If switching transaction types: Reset all values to default
		if (name === 'type') {
			setTransactionFormState({
				type: newValue,
				source: { id: '', name: '' },
				subCategoryID: '',
				destination: { virtualAccountID: '', name: '' },
				name: '',
				details: '',
				amount: 0,
				date: new Date(),
				distribution: 'fillBudget',
				individualAccounts: [{ amount: 0, virtualAccountID: '', name: '' }], //[{amount, virtualAccountID, name}]
				showWarnings: false, //Shown if the user tries to set a date in the future or with an amount of $0.00
				loading: false,
				warning: false,
				showResult: false,
				errors: {}
			})
		} else {
			//Set the property's new state
			setTransactionFormState({
				...transactionFormState,
				[name]: newValue
			})
		}
	}

	/** Parse the Source/Destination id and name from the value passed and then set the new dropdown state */
	const handleDropDownChange = (event, srcDst) => {
		const name = event.currentTarget.name
		const newValue = event.currentTarget.value
		const idNameKey = srcDst === 'source' ? 'id' : 'virtualAccountID'

		const newStatComponents = newValue.split('__#&&#__') //[id, name]
		const newState = { [idNameKey]: newStatComponents[0], name: newStatComponents[1] }

		setTransactionFormState({
			...transactionFormState,
			[name]: newState
		})
	}

	/** Parse the Expense Source id and name (and subcategory ID if applicable) from the value passed and then set the new dropdown state */
	const handleExpenseDropDownChange = (event, srcDst) => {
		const name = event.currentTarget.name
		const newValue = event.currentTarget.value
		const idNameKey = srcDst === 'source' ? 'id' : 'virtualAccountID'

		const newStatComponents = newValue.split('__#&&#__') //[id, name, subCategoryID (optional: '')]
		const newState = { [idNameKey]: newStatComponents[0], name: newStatComponents[1] }

		setTransactionFormState({
			...transactionFormState,
			[name]: newState,
			subCategoryID: newStatComponents[2]
		})
	}

	/** Adds an object to the individualAccounts array in local state */
	const addIndividualAccount = () => {
		setTransactionFormState({
			...transactionFormState,
			individualAccounts: [
				...transactionFormState.individualAccounts,
				{ amount: 0, virtualAccountID: '', name: '' } //Add another Individual Account
			]
		})
	}

	/** Removes an object in the individualAccounts array at index in local state */
	const removeIndividualAccount = (index) => {
		setTransactionFormState({
			...transactionFormState,
			individualAccounts: [
				...transactionFormState.individualAccounts.slice(0, index),
				//Remove the Individual Account at index
				...transactionFormState.individualAccounts.slice(index + 1)
			]
		})
	}

	/** Validates transaction form and returns if it is valid and any errors*/
	const validateTransactionForm = (incomeSources) => {
		let errors = {}

		//Amount Errors
		if (transactionFormState.amount === 0) {
			errors = { ...errors, amount: 'Please enter an amount ' }
			if (transactionFormState.type === 'expense') {
				errors = {
					...errors,
					amountWarning: 'Please enter an amount or press Confirm and Create to record the the expense with an amount of $0.00'
				}
			}
		}
		//Date Errors
		const now = new Date()
		if (isNaN(transactionFormState.date.getTime())) {
			//If the date is not a valid date object
			errors = { ...errors, date: 'Please enter a valid date' }
		} else if (
			//If the new date is set in the Future
			transactionFormState.date.getTime() > new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0).getTime()
		) {
			errors = { ...errors, date: 'Please enter a date that has not happened yet' }
		}
		//Time Errors
		if (
			transactionFormState.date.getTime() < new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0).getTime() &&
			transactionFormState.date.getTime() > now.getTime()
		) {
			//If the date is current but the time is in the future
			errors = {
				...errors,
				time: true, //Just signify that there is an error
				timeWarning:
					'Please enter a time that has not happened yet or press Confirm and Create to record the the transaction with the future time'
			}
		}

		if (transactionFormState.type === 'expense') {
			//Name Errors
			if (transactionFormState.name.trim() === '') {
				errors = { ...errors, name: 'Please fill in a name for the expense' }
			}
			//Source Errors
			if (transactionFormState.source.id === '') {
				errors = { ...errors, source: 'Please choose an account' }
			}
		} else if (transactionFormState.type === 'income') {
			//Source Errors
			if (transactionFormState.source.name.trim() === '') {
				errors = { ...errors, source: 'Please enter a name for the source' }
			}
			if (transactionFormState.source.id === '') {
				//Make sure the name for the new income source doesn't already exist

				Object.keys(incomeSources).forEach((id) => {
					if (transactionFormState.source.name === incomeSources[id].name) {
						errors = { ...errors, source: 'Please enter a unique name for the source' }
					}
				})
			}

			//Destination Errors
			if (transactionFormState.destination.virtualAccountID === '') {
				errors = { ...errors, destination: 'Please choose an account' }
			}

			//Custom Distribution Errors
			if (transactionFormState.distribution === 'custom') {
				validateIndividualAccounts(errors, transactionFormState.amount, 'income')
			}
		} else if (transactionFormState.type === 'transfer') {
			//Source Errors
			if (transactionFormState.source.id === '') {
				errors = { ...errors, source: 'Please choose an account' }
			}
			//Destination Errors
			if (transactionFormState.destination.virtualAccountID === '') {
				errors = { ...errors, destination: 'Please choose an account' }
			} else if (transactionFormState.destination.virtualAccountID === transactionFormState.source.id) {
				errors = { ...errors, destination: 'Please choose different accounts' }
			}
		}

		if (Object.entries(errors).length === 0 && errors.constructor === Object) {
			return { valid: true } //No errors
		} else if (
			errors.name ||
			errors.source ||
			errors.destination ||
			errors.date ||
			errors.individualAccounts ||
			(transactionFormState.type !== 'expense' && errors.amount) //$0.00 expense is permitted
		) {
			return { valid: false, errors } //Hard errors - warnings irrelevant
		} else {
			return { valid: true, errors } //No hard errors but there are warnings
		}
	}

	/** Checks for a valid selection of individual accounts that add up to the provided amount */
	const validateIndividualAccounts = (errors, amount, type) => {
		if (transactionFormState.individualAccounts.length === 0) {
			errors.general = 'Please add accounts to distribute income to'
		} else {
			let totalAmount = 0
			let accountsSelected = {}
			transactionFormState.individualAccounts.forEach((account, index) => {
				if (account.virtualAccountID === '') {
					errors.individualAccounts = { ...errors.individualAccounts, [index]: 'Please choose an account' }
				} else if (accountsSelected[account.virtualAccountID] !== undefined) {
					//Account is already chosen
					errors.individualAccounts = {
						...errors.individualAccounts,
						[index]: 'Please choose different accounts',
						[accountsSelected[account.virtualAccountID]]: 'Please choose different accounts'
					}
				} else {
					accountsSelected[account.virtualAccountID] = index //Record index of account chosen
				}
				if (account.amount === 0) {
					errors.individualAccounts = { ...errors.individualAccounts, [`amountAt${index}`]: 'Please enter an amount ' }
				}

				totalAmount += account.amount
			})
			if (amount > totalAmount) {
				errors.individualAccounts = {
					...errors.individualAccounts,
					amount: `These amounts add up to ${accounting.format(totalAmount)} which is less than the ${accounting.format(
						amount
					)} ${type}`
				}
			} else if (amount < totalAmount) {
				errors.individualAccounts = {
					...errors.individualAccounts,
					amount: `These amounts add up to ${accounting.format(totalAmount)} which is more than the ${accounting.format(
						amount
					)} ${type}`
				}
			}
		}
	}

	/**
	 * Checks for valid form input and shows errors or sends an api call to record data
	 * Presents the result when successfully saved
	 */
	const handleSubmit = async (event, accounts, incomeSources, setIncomeSources, recentTransactions, setRecentTransactions) => {
		event.preventDefault() //Prevents page from reloading on submit
		setTransactionFormState({ ...transactionFormState, loading: true }) //To display spinner
		let result = {}
		const { valid, errors } = validateTransactionForm(incomeSources) //Checks for valid input

		//If the form is valid with no errors and no warnings OR if the form is valid and the warnings have already been shown to the user
		if ((valid && !errors) || (valid && transactionFormState.showWarnings)) {
			//Record the transaction to the database
			setTransactionFormState({ ...transactionFormState, errors: {}, loading: true })
			result = await apiCreateTransaction(transactionFormState, accounts)
		} else if (!valid) {
			//Not valid there are errors -> ignore any warnings for now
			let onlyErrors = {}
			if (errors.name) {
				onlyErrors = { name: errors.name }
			}
			if (errors.source) {
				onlyErrors = { ...onlyErrors, source: errors.source }
			}
			if (errors.destination) {
				onlyErrors = { ...onlyErrors, destination: errors.destination }
			}
			if (errors.date) {
				onlyErrors = { ...onlyErrors, date: errors.date }
			}
			if (errors.individualAccounts) {
				onlyErrors = { ...onlyErrors, individualAccounts: errors.individualAccounts }
			}
			if (transactionFormState.type !== 'expense' && errors.amount) {
				onlyErrors = { ...onlyErrors, amount: errors.amount }
			}

			//Set the hard errors
			setTransactionFormState({
				...transactionFormState,
				errors: onlyErrors,
				showWarnings: false, // Only show hard errors
				loading: false //Stop spin weal
			})
			return //Don't continue
		} else if (valid && !transactionFormState.showWarnings) {
			//If we're here the form is valid and there are only warnings that have not been confirmed
			setTransactionFormState({
				...transactionFormState,
				errors: errors,
				showWarnings: true, //Show the warnings to the user
				loading: false //Stop spin weal
			})
			return //Don't continue
		} else {
			//Form is valid, only warnings that the user has confirmed and wish to continue
			//Record the transaction to the database
			setTransactionFormState({ ...transactionFormState, errors: {}, loading: true })
			result = await apiCreateTransaction(transactionFormState, accounts)
		}

		//Check for errors with the api call
		if (!result.errors) {
			//console.log('client state', transactionFormState)
			//console.log('api result', result.data)

			//Add transaction to local state
			let joined = recentTransactions.concat(result.data.transaction)
			//Add individual transaction(s) to local state
			if (result.data.individualTransactions !== undefined) {
				const newTransactions = Object.keys(result.data.individualTransactions).map((key) => {
					return { transactionID: key, ...result.data.individualTransactions[key] }
				})
				joined = joined.concat(newTransactions)
			}
			setRecentTransactions(joined)

			setTimeout(() => {
				//  to-do  or  will this be fetched in accounts after result is gotten ??
				//If a new source was created add it to client's incomeSources state
				if (result.data.source !== undefined) {
					setIncomeSources({ ...incomeSources, [result.data.source.id]: { name: result.data.source.name } })
				}
			}, 300)
			//If there is leftover funds: add back the original physical account they were stored in for the assign leftovers api call
			let addBackPhysicalAccount = {}
			if (result.data.leftover !== undefined) {
				addBackPhysicalAccount = {
					transaction: { ...result.data.transaction, destination: { ...transactionFormState.destination } }
				}
			}

			//Show the Result from the api call
			setTransactionFormState({
				...transactionFormState,
				individualAccounts: [{ amount: 0, virtualAccountID: '', name: '' }], //Reset to use for any income leftover
				errors: {},
				loading: false,
				showResult: true,
				...result.data,
				...addBackPhysicalAccount
			})
		} else {
			console.log('api errors', result.errors)
			//Set errors from api call
			setTransactionFormState({
				...transactionFormState,
				errors: result.errors,
				loading: false //Stop spin weal
			})
		}
	}

	/**
	 * Checks for valid form input and shows errors or sends an api call to assign leftover income from first transaction
	 * Presents the result when successfully saved
	 */
	const submitLeftover = async (accounts, recentTransactions, setRecentTransactions) => {
		//Validate
		let result = {}
		let errors = {}
		validateIndividualAccounts(errors, transactionFormState.leftover.amount, 'leftover')

		if (Object.entries(errors).length === 0 && errors.constructor === Object) {
			//No errors
			setTransactionFormState({ ...transactionFormState, errors: {}, loading: true })
			result = await apiAssignLeftoverIncome(transactionFormState.transaction, transactionFormState.individualAccounts, accounts)
		} else {
			//Errors
			setTransactionFormState({
				...transactionFormState,
				errors: errors,
				loading: false //Stop the spin weal
			})
			return //Don't Continue
		}

		//Check for errors with the api call
		if (!result.errors) {
			//Add individual transaction(s) to local state
			if (result.data.individualTransactions !== undefined) {
				let joined = recentTransactions.concat(
					Object.keys(result.data.individualTransactions).map((key) => {
						return { transactionID: key, ...result.data.individualTransactions[key] }
					})
				)
				setRecentTransactions(joined)
			}

			//Add any physical account transfers necessary together
			let combinedTransfers = { ...result.data.transfers } //Pre-populate with the new transfers
			Object.keys(transactionFormState.transfers).forEach((transferID) => {
				//If the old transfer also exists in the new transfers:
				if (result.data.transfers[transferID] !== undefined) {
					//Add their total together
					combinedTransfers[transferID] = transactionFormState.transfers[transferID] + result.data.transfers[transferID]
				} else {
					//Add the old transfer to the list of all transfers (combinedTransfers)
					combinedTransfers[transferID] = transactionFormState.transfers[transferID]
				}
			})

			//Show the new second result from the api call
			setTransactionFormState({
				...transactionFormState,
				individualAccounts: [],
				loading: false,
				showResult: true,
				errors: {},
				leftover: undefined, //Leftovers have been assigned - removes related warnings and prompts
				individualTransactions: { ...transactionFormState.individualTransactions, ...result.data.individualTransactions },
				transfers: { ...combinedTransfers }
			})
		} else {
			//Set errors from api call
			setTransactionFormState({
				...transactionFormState,
				errors: result.errors,
				loading: false //Stop spin weal
			})
		}
	}

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<NewTransactionFormContext.Provider
			value={{
				transactionFormState,
				setTransactionFormState,
				handleFormChange,
				handleDropDownChange,
				handleExpenseDropDownChange,
				addIndividualAccount,
				removeIndividualAccount,
				handleSubmit,
				submitLeftover
			}}
		>
			{props.children}
		</NewTransactionFormContext.Provider>
	)
}

export default NewTransactionFormContextProvider
