import { globalTypography } from './globalTypography'

export const darkTypography = {
	h1: {
		fontFamily: globalTypography.h1.fontFamily,
		color: globalTypography.h1.colorDark,
		fontWeight: globalTypography.h1.fontWeight,
		fontSize: globalTypography.h1.fontSize,
		letterSpacing: globalTypography.h1.letterSpacing,
		lineHeight: globalTypography.h1.lineHeight
	},
	h2: {
		fontFamily: globalTypography.h2.fontFamily,
		color: globalTypography.h2.colorDark,
		fontWeight: globalTypography.h2.fontWeight,
		fontSize: globalTypography.h2.fontSize,
		letterSpacing: globalTypography.h2.letterSpacing,
		lineHeight: globalTypography.h2.lineHeight
	},
	h3: {
		fontFamily: globalTypography.h3.fontFamily,
		color: globalTypography.h3.colorDark,
		fontWeight: globalTypography.h3.fontWeight,
		fontSize: globalTypography.h3.fontSize,
		letterSpacing: globalTypography.h3.letterSpacing,
		lineHeight: globalTypography.h3.lineHeight
	},
	h4: {
		fontFamily: globalTypography.h4.fontFamily,
		color: globalTypography.h4.colorDark,
		fontWeight: globalTypography.h4.fontWeight,
		fontSize: globalTypography.h4.fontSize,
		letterSpacing: globalTypography.h4.letterSpacing,
		lineHeight: globalTypography.h4.lineHeight
	},
	h5: {
		fontFamily: globalTypography.h5.fontFamily,
		color: globalTypography.h5.colorDark,
		fontWeight: globalTypography.h5.fontWeight,
		fontSize: globalTypography.h5.fontSize,
		letterSpacing: globalTypography.h5.letterSpacing,
		lineHeight: globalTypography.h5.lineHeight
	},
	h6: {
		fontFamily: globalTypography.h6.fontFamily,
		color: globalTypography.h6.colorDark,
		fontWeight: globalTypography.h6.fontWeight,
		fontSize: globalTypography.h6.fontSize,
		letterSpacing: globalTypography.h6.letterSpacing,
		lineHeight: globalTypography.h6.lineHeight
	},
	subtitle1: {
		fontFamily: globalTypography.subtitle1.fontFamily,
		color: globalTypography.subtitle1.colorDark,
		fontSize: globalTypography.subtitle1.fontSize,
		letterSpacing: globalTypography.subtitle1.letterSpacing,
		lineHeight: globalTypography.subtitle1.lineHeight
	},
	subtitle2: {
		fontFamily: globalTypography.subtitle2.fontFamily,
		color: globalTypography.subtitle2.colorDark,
		fontWeight: globalTypography.subtitle2.fontWeight,
		fontSize: globalTypography.subtitle2.fontSize,
		letterSpacing: globalTypography.subtitle2.letterSpacing,
		lineHeight: globalTypography.subtitle2.lineHeight
	},
	body1: {
		fontFamily: globalTypography.body1.fontFamily,
		color: globalTypography.body1.colorDark,
		fontSize: globalTypography.body1.fontSize,
		letterSpacing: globalTypography.body1.letterSpacing,
		lineHeight: globalTypography.body1.lineHeight
	},
	body2: {
		fontFamily: globalTypography.body2.fontFamily,
		color: globalTypography.body2.colorDark,
		fontSize: globalTypography.body2.fontSize,
		letterSpacing: globalTypography.body2.letterSpacing,
		lineHeight: globalTypography.body2.lineHeight
	},
	button: {
		fontFamily: globalTypography.button.fontFamily,
		color: globalTypography.button.colorDark,
		fontSize: globalTypography.button.fontSize,
		textTransform: 'none'
	},
	caption: {
		fontFamily: globalTypography.caption.fontFamily,
		color: globalTypography.caption.colorDark,
		fontSize: globalTypography.caption.fontSize,
		letterSpacing: globalTypography.caption.letterSpacing,
		lineHeight: globalTypography.caption.lineHeight
	},
	overline: {
		fontFamily: globalTypography.overline.fontFamily,
		color: globalTypography.overline.colorDark,
		fontSize: globalTypography.overline.fontSize,
		fontWeight: globalTypography.overline.fontWeight,
		letterSpacing: globalTypography.overline.letterSpacing,
		lineHeight: globalTypography.overline.lineHeight,
		textTransform: globalTypography.overline.textTransform
	}
}
