import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography } from '@mui/material'
import { NewTransactionFormContext } from '../NewTransactionFormContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'TransactionView'
const classes = {
	title: `${PREFIX}-title`,
	timestamp: `${PREFIX}-timestamp`
}

const GridStyled = styled(Grid)(({ theme: { palette } }) => ({
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '18px',
		color: palette.text.primary
	},
	[`& .${classes.timestamp}`]: {
		textAlign: 'center',
		fontSize: '14px',
		color: palette.text.secondary
	}
}))

//id will be undefined for the main transaction and be the transactionID for individual transactions
const TransactionView = ({ id }) => {
	const { transactionFormState: state } = useContext(NewTransactionFormContext)

	return (
		<GridStyled container direction='row' spacing={0} alignItems='center' justifyContent='flex-start'>
			{/* Timestamp */}
			{id === undefined && (
				<Grid item sx={{ paddingRight: '10px' }}>
					<Typography variant='body2' className={classes.timestamp} sx={{ paddingBottom: '3px' }}>
						{/* Time hh:mm AM/PM */}
						{`${new Date((id === undefined ? state.transaction.date : state.individualTransactions[id].date)._seconds * 1000)
							.toLocaleTimeString()
							.slice(0, -6)} ${new Date(
							(id === undefined ? state.transaction.date : state.individualTransactions[id].date)._seconds * 1000
						)
							.toLocaleTimeString()
							.slice(-2)}`}
					</Typography>

					{/* Date mm/dd/YYYY */}
					<Typography variant='body2' className={classes.timestamp}>
						{new Date(
							(id === undefined ? state.transaction.date : state.individualTransactions[id].date)._seconds * 1000
						).toLocaleDateString()}
					</Typography>
				</Grid>
			)}

			{/* Amount */}
			<Grid item sx={{ paddingRight: '10px' }}>
				<Typography variant='body1' className={classes.title} gutterBottom>
					{accounting.format(id === undefined ? state.transaction.amount : state.individualTransactions[id].amount)}
				</Typography>
			</Grid>

			{/* Leftover Amount Action Necessary */}
			{state.transaction !== undefined && state.transaction.type === 'transfer' && (
				<Grid item sx={{ paddingRight: '10px' }}>
					{/* from Account Name */}
					<Grid item sx={{ paddingRight: '5px' }}>
						<Grid container direction='row' sx={{ flex: 1, paddingLeft: '0px' }}>
							<Typography variant='body1' sx={{ fontSize: '18px', color: 'text.secondary', paddingRight: '5px' }} gutterBottom>
								{'from'}
							</Typography>
							<Typography variant='body1' className={classes.title} gutterBottom>
								{state.transaction.source.name}
							</Typography>

							<Typography
								variant='body1'
								sx={{ fontSize: '18px', color: 'text.secondary', paddingLeft: '5px', paddingRight: '5px' }}
								gutterBottom
							>
								{'to'}
							</Typography>

							<Typography variant='body1' className={classes.title} gutterBottom>
								{state.transaction.destination.name}
							</Typography>
						</Grid>
					</Grid>
					{/* to Account Name */}
					<Grid item sx={{ paddingRight: '5px' }}>
						<Grid container direction='row' sx={{ flex: 1, paddingLeft: '0px' }}></Grid>
					</Grid>
				</Grid>
			)}

			{/* from/to Transaction/Destination Name */}
			{(id !== undefined || state.transaction.type !== 'transfer') && (
				<Grid item sx={{ paddingRight: '10px' }}>
					<Grid container direction='row' sx={{ flex: 1, paddingLeft: 0 }}>
						<Typography variant='body1' sx={{ fontSize: '18px', color: 'text.secondary', paddingRight: '10px' }} gutterBottom>
							{id === undefined ? 'from ' : 'to '}
						</Typography>

						<Typography variant='body1' className={classes.title} gutterBottom>
							{id === undefined
								? state.transaction.type === 'income'
									? state.transaction.name
									: state.transaction.source.name
								: state.individualTransactions[id].destination.name}
						</Typography>
					</Grid>
				</Grid>
			)}
		</GridStyled>
	)
}

export default TransactionView
