import React, { useState, useContext, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import {
	Typography,
	Grid,
	useMediaQuery,
	CircularProgress,
	Button,
	FormControl,
	Select,
	InputLabel,
	FormHelperText
} from '@mui/material'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { AccountsContext } from '../AccountsContext'
import { fixAccountImbalance as apiFixAccountImbalance, getAccounts as apiGetAccounts } from '../../../helpers/api'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'
import { useAccounts } from '@mikeint0sh/the-keeton-cloud-components/finance'

const PREFIX = 'AccountImbalance'
const classes = {
	form: `${PREFIX}-form`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`,
	dropDown: `${PREFIX}-dropDown`,
	item: `${PREFIX}-item`,
	title: `${PREFIX}-title`,
	timestamp: `${PREFIX}-timestamp`
}

const GridStyled = styled(Grid)(({ theme: { palette } }) => ({
	[`&.${classes.item}`]: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '18px',
		color: palette.text.primary
	},
	[`& .${classes.timestamp}`]: {
		textAlign: 'center',
		fontSize: '14px',
		color: palette.text.secondary
	}
}))

const FormStyled = styled('form')(() => ({
	[`&.${classes.form}`]: {
		paddingLeft: 15,
		paddingRight: 15
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	},
	[`& .${classes.dropDown}`]: {
		paddingTop: 20,
		paddingBottom: 10
	}
}))

const TypographyErrorStyled = styled(Typography)(({ theme: { palette } }) => ({
	fontSize: '14px',
	color: palette.error.main,
	textAlign: 'center',
	marginTop: '5px',
	height: 15
}))

const AccountImbalance = ({ close }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { fetchAccounts } = useAccounts('fetchAccounts', apiGetAccounts, GlobalContext)
	const { accounts, recentTransactions, setRecentTransactions } = useContext(GlobalContext)
	const { accountState } = useContext(AccountsContext)
	const [state, setState] = useState({
		to: { virtualAccountID: '', name: '' },
		loading: false,
		errors: {}
	})

	useEffect(() => {
		//Active account is a Physical Account
		setState({
			from: accounts[accountState.activeAccount.pIndex][0].physicalAccountID,
			to: { virtualAccountID: '', name: '' },
			loading: false,
			errors: {}
		})

		// eslint-disable-next-line
	}, [])

	/** Closes Popup gracefully */
	const closePopup = () => {
		setTimeout(() => {
			setState({
				to: { virtualAccountID: '', name: '' },
				loading: false,
				errors: {}
			}) //Set state back to defaults for next time create Transfer is clicked
		}, 200) //After the alert disappearing animation finishes then clear
		//This prevents showing cleared data on the alert as it disappears

		close()
	}

	/** Updates the state when either the name or type felids change */
	const handleChange = (event) => {
		const newValue = event.currentTarget.value
		const newStateComponents = newValue.split('__#&&#__') //[virtualAccountID, name]
		const newState = { virtualAccountID: newStateComponents[0], name: newStateComponents[1] }

		setState({
			...state,
			to: newState
		})
	}

	/**
	 * Validates to and from accounts, then sends an api call to create the transfer and move the money if successful
	 * else it will set relevant errors to display to the user
	 */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, errors: {}, loading: true }) //To display spinner
		let result = {}

		if (state.to.virtualAccountID !== '') {
			result = await apiFixAccountImbalance({
				amount: accounts[accountState.activeAccount.pIndex][0].balance,
				source: {
					id: accounts[accountState.activeAccount.pIndex][0].physicalAccountID,
					name: accounts[accountState.activeAccount.pIndex][0].name
				},
				destination: { virtualAccountID: state.to.virtualAccountID, name: state.to.name }
			})
		} else {
			result.errors = { to: 'Please choose an account' }
		}

		console.log('result:', result)
		if (!result.errors) {
			fetchAccounts(true) //  get an error  //Get the updated account values
			setState({ ...state, loading: false, ...result.data })

			//Add transaction to local state
			let joined = recentTransactions.concat({
				transactionID: result.data.individualTransactions[0].transactionID,
				...result.data.individualTransactions[0]
			})
			setRecentTransactions(joined)
		} else {
			setState({
				...state,
				errors: result.errors, //Set errors in local state
				loading: false //Stop loading spin weal
			})
		}
	}

	function DropDown() {
		return (
			//To/From account selection
			<div className={classes.dropDown}>
				<FormControl variant='outlined' error={state.errors.to ? true : false}>
					<InputLabel>{'Transfer To'}</InputLabel>
					<Select
						native
						name={'to'}
						value={`${state.to.virtualAccountID}__#&&#__${state.to.name}`}
						onChange={handleChange}
						label={`Transfer to account`}
						inputProps={{ name: 'to' }}
					>
						{/* Title */}
						<option value={''}></option>
						{accounts.map((physicalAccountArr) => {
							//If the physical account is the active account:
							if (physicalAccountArr[0].physicalAccountID === accounts[accountState.activeAccount.pIndex][0].physicalAccountID) {
								return physicalAccountArr.map((account, accountIndex) => {
									if (accountIndex !== 0) {
										return (
											<option value={`${account.virtualAccountID}__#&&#__${account.name}`} key={account.virtualAccountID}>
												{account.name}
											</option>
										)
									} else {
										return (
											<option
												disabled
												value={`${account.physicalAccountID}__#&&#__${account.name}`}
												key={account.physicalAccountID}
											>
												{account.name}
											</option>
										)
									}
								})
							}
							return null
						})}
					</Select>

					{/* Shows relevant To/From errors to the user */}
					{state.errors.to && <FormHelperText>{state.errors.to}</FormHelperText>}
				</FormControl>
			</div>
		)
	}

	return (
		<Fragment>
			{state.individualTransactions === undefined && (
				<FormStyled noValidate onSubmit={handleSubmit} className={classes.form}>
					<Typography variant='body2' align='center'>
						{`This account has an extra ${accounting.format(
							accounts[accountState.activeAccount.pIndex][0].balance
						)} in it. Please select a virtual account to hold this amount.`}
					</Typography>

					{/* To/From selections */}
					<DropDown />

					{/* Shows general unknown errors to the user */}
					{state.errors.general && (
						<TypographyErrorStyled variant='body2' align='center'>
							{state.errors.general}
						</TypographyErrorStyled>
					)}

					<Grid
						container
						direction={isDesktop ? 'row' : 'column'}
						alignItems='center'
						justifyContent='center'
						spacing={1}
						sx={{ paddingTop: '30px' }}
					>
						<Grid item xs={isDesktop ? 6 : 12} sx={{ width: '100%' }} />

						<Grid item xs={isDesktop ? 6 : 12} sx={{ width: '100%' }}>
							<Button
								type='submit'
								fullWidth
								variant='contained'
								color='primary'
								disabled={state.loading}
								className={classes.submitBtn}
								sx={{ fontSize: '16px', color: 'white' }}
							>
								{!state.loading && 'Create Transfer'}
								{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
							</Button>
						</Grid>
					</Grid>
				</FormStyled>
			)}

			{state.individualTransactions !== undefined && (
				<Grid container direction='column'>
					{/* Individual Transaction */}
					<GridStyled
						item
						container
						direction='column'
						alignItems='flex-start'
						justifyContent='space-between'
						className={classes.item}
					>
						<Grid item>
							<Typography variant='h5' sx={{ fontWeight: 400 }}>
								Transaction Successful
							</Typography>
						</Grid>

						<Grid item sx={{ paddingTop: '10px' }}>
							{/* //Transaction View */}
							<Grid container direction='row' spacing={0} alignItems='center' justifyContent='flex-start'>
								{/* Timestamp */}

								<Grid item sx={{ paddingRight: '10px' }}>
									<Typography variant='body2' className={classes.timestamp} sx={{ paddingBottom: '3px' }}>
										{/* Time hh:mm AM/PM */}
										{`${new Date(state.individualTransactions[0].date._seconds * 1000)
											.toLocaleTimeString()
											.slice(0, -6)} ${new Date(state.individualTransactions[0].date._seconds * 1000)
											.toLocaleTimeString()
											.slice(-2)}`}
									</Typography>

									{/* Date mm/dd/YYYY */}
									<Typography variant='body2' className={classes.timestamp}>
										{new Date(state.individualTransactions[0].date._seconds * 1000).toLocaleDateString()}
									</Typography>
								</Grid>

								{/* Amount */}
								<Grid item sx={{ paddingRight: '10px' }}>
									<Typography variant='body1' className={classes.title} gutterBottom>
										{accounting.format(state.individualTransactions[0].amount)}
									</Typography>
								</Grid>

								{/* to Account */}
								<Grid item sx={{ paddingRight: '10px' }}>
									<Grid container direction='row' sx={{ flex: 1, paddingLeft: 0 }}>
										<Typography
											variant='body1'
											sx={{ fontSize: '18px', color: 'text.secondary', paddingRight: '10px' }}
											gutterBottom
										>
											{'to '}
										</Typography>

										<Typography variant='body1' className={classes.title} gutterBottom>
											{state.individualTransactions[0].destination.name}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</GridStyled>

					{/* Error Message */}
					{state.errors.general && (
						<TypographyErrorStyled variant='body2' align='center'>
							{state.errors.general}
						</TypographyErrorStyled>
					)}

					{/* Close Button */}
					<Grid
						item
						container
						direction='row'
						alignItems='center'
						justifyContent='flex-end'
						sx={{ paddingTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}
					>
						<Grid item>
							<Button onClick={() => closePopup()} variant={'text'} sx={{ fontSize: '16px', color: 'white' }}>
								Close
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Fragment>
	)
}

export default AccountImbalance
