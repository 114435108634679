import React from 'react'
import { styled } from '@mui/material/styles'
import { Dialog, Typography, Grid, Card, CardContent } from '@mui/material'

const PREFIX = 'Popup'
const classes = {
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`
}

const StyledCard = styled(Card)(({ theme: { breakpoints } }) => ({
	[`&.${classes.card}`]: {
		maxWidth: 400,
		// margin: 10,
		borderRadius: 8,
		transition: '0.3s',
		boxShadow: '5px 15px 40px 2px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0px 20px 50px 3px rgba(0,0,0,0.3)'
		},
		[breakpoints.up('sm')]: {
			minWidth: 400
		}
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '24px'
	}
}))

const Popup = ({ isOpen, close, title, children }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			sx={{ height: '85%', borderRadius: '8px' }}
			PaperProps={{
				sx: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					borderRadius: '8px'
				}
			}}
		>
			{/* <Grid className={classes.border}> */}
			<StyledCard className={classes.card}>
				<CardContent>
					<Grid container direction='row' spacing={1}>
						<Grid item style={{ flex: 1 }}>
							<Typography variant='h1' className={classes.title} gutterBottom>
								{title}
							</Typography>
						</Grid>
					</Grid>

					{children}
				</CardContent>
			</StyledCard>
			{/* </Grid> */}
		</Dialog>
	)
}

export default Popup
