import React, { Fragment, useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Toolbar, Grid, Typography, Hidden, IconButton, Switch, useMediaQuery, Button, Avatar } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { GA4 } from '../../../../helpers'

const PREFIX = 'TopBar'
const classes = {
	root: `${PREFIX}-root`,
	title: `${PREFIX}-title`,
	menuItemLink: `${PREFIX}-menuItemLink`,
	menuItemText: `${PREFIX}-menuItemText`,
	flexGrow: `${PREFIX}-flexGrow`,
	signOutButton: `${PREFIX}-signOutButton`,
	toolbar: `${PREFIX}-toolbar`,
	btnRoot: `${PREFIX}-btnRoot`
}

const AppBarStyled = styled(AppBar)(({ theme: { palette, breakpoints, spacing } }) => ({
	[`&.${classes.root}`]: {
		backgroundColor: palette.menu
	},
	[`& .${classes.title}`]: {
		fontWeight: 300,
		paddingLeft: 15
	},
	[`& .${classes.menuItemLink}`]: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 2
	},
	[`& .${classes.menuItemText}`]: {
		fontWeight: 300,
		paddingTop: 2,
		'&:hover': {
			borderBottom: `1px solid`,
			color: palette.secondary.main,
			borderBottomColor: palette.secondary.main,
			marginBottom: -1
		}
	},
	[`& .${classes.flexGrow}`]: {
		flexGrow: 1
	},
	[`& .${classes.signOutButton}`]: {
		color: palette.secondary,
		marginLeft: spacing(1)
	},
	[`& .${classes.toolbar}`]: {
		color: palette.secondary,
		backgroundColor: palette.menu,
		paddingLeft: 23,
		paddingTop: 10,
		paddingBottom: 10,
		[breakpoints.down('sm')]: {
			paddingLeft: 0
		}
	},
	[`& .${classes.btnRoot}`]: {
		border: 0,
		paddingRight: 20,
		paddingLeft: 10,
		fontSize: 19,
		fontWeight: 400,
		margin: 0,
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent'
		}
	}
}))

const TopBar = ({ toggleDarkMode }) => {
	const { palette } = useTheme()
	const { setShowLogin, loggedIn, credentials, useGA } = useContext(GlobalContext)
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })

	const handleLogin = async () => {
		GA4.event(useGA, 'login_popup')
		setShowLogin(true)
	}

	return (
		<AppBarStyled className={classes.root} position='fixed'>
			<Toolbar disableGutters={true} className={classes.toolbar}>
				<Grid container direction='row' justifyContent='flex-start' alignItems='center'>
					<RouterLink style={{ textDecoration: 'none' }} to='/'>
						<Grid container direction='row' justifyContent='flex-start' alignItems='center'>
							<img
								alt='Logo'
								src='/images/cloud-logo.png'
								style={{ width: 55, height: 35, objectFit: 'contain', marginLeft: !isDesktop ? 10 : 0 }}
							/>
							{isDesktop && (
								<Typography className={classes.title} variant='h2'>
									The Keeton Cloud
								</Typography>
							)}
							<Typography className={classes.title} style={{ paddingRight: 5 }} color='secondary' variant='h2'>
								Finance
							</Typography>
						</Grid>
					</RouterLink>
					{!isDesktop && (
						<Fragment>
							<Grid item style={{ flexGrow: 1 }}></Grid>

							<IconButton className={classes.signOutButton} color='secondary' style={{ marginLeft: 5, padding: 0 }} size='large'>
								<Switch
									color='secondary'
									checked={palette.mode === 'dark'}
									onChange={() => {
										const newType = palette.mode === 'dark' ? 'light' : 'dark'
										GA4.event(useGA, 'theme', newType)
										toggleDarkMode()
									}}
								/>
							</IconButton>

							{!loggedIn && (
								<Button color='secondary' onClick={handleLogin} className={classes.btnRoot}>
									Login
								</Button>
							)}
							{loggedIn && (
								//If logged in show the user's avatar btn instead
								<Avatar
									component={RouterLink}
									style={{ color: palette.menu, marginRight: 20, marginLeft: 10 }}
									src={credentials.image.indexOf('default-user-image.jpg') === -1 ? credentials.image : ''}
									to='/Dashboard'
								/>
							)}
						</Fragment>
					)}
					{isDesktop && (
						<Fragment>
							<RouterLink className={classes.menuItemLink} to='/Tools/Budget'>
								<Typography className={classes.menuItemText} variant='h3'>
									Budget
								</Typography>
							</RouterLink>
							{/* <RouterLink className={classes.menuItemLink} to='/Tools/Save'>
								<Typography className={classes.menuItemText} variant='h3'>
									Save
								</Typography>
							</RouterLink> */}
							<RouterLink className={classes.menuItemLink} to='/Tools/Invest'>
								<Typography className={classes.menuItemText} variant='h3'>
									Invest
								</Typography>
							</RouterLink>
							{/* <RouterLink className={classes.menuItemLink} to='/Tools/Retire'>
								<Typography className={classes.menuItemText} variant='h3'>
									Retire
								</Typography>
							</RouterLink> */}
						</Fragment>
					)}
					{!isDesktop && (
						<Grid container direction='row' justifyContent='center' alignItems='center' style={{ paddingTop: 4 }}>
							<RouterLink className={classes.menuItemLink} to='/Tools/Budget'>
								<Typography className={classes.menuItemText} variant='h3'>
									Budget
								</Typography>
							</RouterLink>
							{/* <RouterLink className={classes.menuItemLink} to='/Tools/Save'>
								<Typography className={classes.menuItemText} variant='h3'>
									Save
								</Typography>
							</RouterLink> */}
							<RouterLink className={classes.menuItemLink} to='/Tools/Invest'>
								<Typography className={classes.menuItemText} variant='h3'>
									Invest
								</Typography>
							</RouterLink>
							{/* <RouterLink className={classes.menuItemLink} to='/Tools/Retire'>
								<Typography className={classes.menuItemText} variant='h3'>
									Retire
								</Typography>
							</RouterLink> */}
						</Grid>
					)}
				</Grid>

				<Hidden mdDown>
					<div className={classes.flexGrow} />
					<Hidden lgDown>
						<IconButton className={classes.signOutButton} color='secondary' style={{ marginLeft: 5, padding: 0 }} size='large'>
							<Switch
								color='secondary'
								checked={palette.mode === 'dark'}
								onChange={() => {
									const newType = palette.mode === 'dark' ? 'light' : 'dark'
									GA4.event(useGA, 'theme', newType)
									toggleDarkMode()
								}}
							/>
						</IconButton>
					</Hidden>

					{!loggedIn && (
						<Button color='secondary' onClick={handleLogin} className={classes.btnRoot}>
							Login
						</Button>
					)}
					{loggedIn && (
						//If logged in show the user's avatar btn instead
						<Avatar
							component={RouterLink}
							style={{ color: palette.menu, marginRight: 20, marginLeft: 10 }}
							src={credentials.image.indexOf('default-user-image.jpg') === -1 ? credentials.image : ''}
							to='/Dashboard'
						/>
					)}
				</Hidden>
			</Toolbar>
		</AppBarStyled>
	)
}

export default TopBar
