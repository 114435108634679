import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Button, Typography, Grid, TextField, CircularProgress, useMediaQuery } from '@mui/material'
import { validator } from '@mikeint0sh/the-keeton-cloud-components/utils'
import { GA4 } from '../../helpers'
import { GlobalContext } from '../../helpers/GlobalContext'

const PREFIX = 'ForgotPassword'
const classes = {
	error: `${PREFIX}-error`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`
}

const Root = styled('form')(({ theme: { palette } }) => ({
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center',
		marginTop: '5px',
		height: 15
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	}
}))

const ForgotPassword = ({ state, setState }) => {
	const { useGA } = useContext(GlobalContext)
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })

	/** (In the future:) Validates email and sends the api call to forgot password, then sets any errors or success message */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, loading: true }) //To display spinner
		GA4.event(useGA, 'forgot_password')

		let errors = {}
		if (validator.isEmpty(state.email)) errors.email = 'Must not be empty'
		else if (!validator.isEmail(state.email)) errors.email = 'Must be a valid email address'

		if (errors.email) {
			setState({
				...state,
				errors: errors, //Set errors in local state
				loading: false
			})
		} else {
			//const result = await apiLogin(state.email, state.password, state.rememberMe)

			// fake it till you make if
			setTimeout(() => {
				setState({
					...state,
					errors: { general: "This email doesn't belong to any user" }, //Set errors in local state
					loading: false
				})
			}, 1358)
		}
	}

	/** Updates the state when the email text felid change */
	const handleTextFelidChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	return (
		<Root noValidate onSubmit={handleSubmit}>
			<TextField
				id='email'
				type='email'
				name='email'
				label='Email Address'
				value={state.email}
				onChange={handleTextFelidChange}
				helperText={state.errors.email}
				error={state.errors.email ? true : false}
				autoComplete='email'
				margin='normal'
				required
				fullWidth
				autoFocus
			/>

			{/* (Will) display error of success message */}
			<Typography variant='body2' className={classes.error} align='center'>
				{state.errors.general ? state.errors.general : ' '}
			</Typography>

			<Grid container direction={isDesktop ? 'row' : 'column'} xs={12} alignItems='center' justifyContent='center' spacing={1}>
				<Grid item xs={12} style={{ width: '100%' }}>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						disabled={state.loading}
						className={classes.submitBtn}
						style={{ fontSize: '16px', color: 'white' }}
					>
						Send Email
						{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
					</Button>
				</Grid>
			</Grid>
		</Root>
	)
}

export default ForgotPassword
