import { globalPalette } from './globalPalette'

export const lightPalette = {
	black: globalPalette.black,
	white: globalPalette.white,
	mode: 'light',
	primary: {
		contrastText: globalPalette.primary.contrastText, // white
		main: globalPalette.primary.light,
		dark: globalPalette.primary.dark,
		light: globalPalette.primary.light
	},
	secondary: {
		contrastText: globalPalette.black,
		dark: globalPalette.secondary.dark,
		main: globalPalette.secondary.light,
		light: globalPalette.secondary.light
	},
	success: {
		contrastText: globalPalette.black,
		dark: globalPalette.success.dark,
		main: globalPalette.success.light,
		light: globalPalette.success.light
	},
	info: {
		contrastText: globalPalette.black,
		dark: globalPalette.info.dark,
		main: globalPalette.info.light,
		light: globalPalette.info.light
	},
	warning: {
		contrastText: globalPalette.black,
		dark: globalPalette.warning.dark,
		main: globalPalette.warning.light,
		light: globalPalette.warning.light
	},
	error: {
		contrastText: globalPalette.black,
		dark: globalPalette.error.dark,
		main: globalPalette.error.light,
		light: globalPalette.error.light
	},
	text: {
		primary: globalPalette.text.primaryLight,
		secondary: globalPalette.text.secondaryLight,
		tertiary: globalPalette.text.primaryDark,
		link: globalPalette.text.light
	},
	tile: globalPalette.tile.light,
	background: globalPalette.background.light,
	menu: globalPalette.menu.light
}
