export const event = (useGA, action, value = 'N/A', error = 'N/A') => {
	if (useGA) {
		if (typeof window !== 'undefined') {
			window.dataLayer.push({
				event: 'CustomEvent',
				action: action,
				value: value,
				error: error
			})
		} else {
			console.error('GA4: window is undefined')
		}
	}
}
