import React, { createContext, useState } from 'react'
export const BudgetContext = createContext()

const BudgetContextProvider = (props) => {
	//Initial state for Stats
	const [budgetState, setBudgetState] = useState({
		onboarding: true,
		onboardingTransition: true, //Keeps Onboarding view shown until animation finishes
		showTransitions: false, //Hides transitions for the Budget console width while Slide is animating
		editing: false, //Shows editing view in Slide Transition
		editingTransition: false, //Hides transitions for the Budget console width while Slide is animating
		mainViewTransition: false, //Hides/Shows the main (Overview/Summaries/WhatIf) Slide view for the Budget console
		tab: 'Overview'
	})

	const [chartData, setChartData] = useState({
		series: [44, 55, 13],
		options: {
			chart: {
				type: 'pie',
				height: '100%',
				width: '100%',
				fontFamily: 'Roboto, Helvetica, Arial',
				animations: {
					enabled: false
				}
			},
			stroke: {
				show: false
			},
			colors: ['#F22838', '#8536f5', '#10A0FF'],
			labels: ['Basics', 'Wants', 'Savings'],
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			tooltip: {
				enabled: false
			},
			states: {
				hover: {
					filter: {
						type: 'none'
					}
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'none'
					}
				}
			},
			plotOptions: {
				pie: {
					expandOnClick: false
				}
			}
		}
	})

	const [activeView, setActiveView] = useState('Current') //Which Budget View is shown

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<BudgetContext.Provider
			value={{
				budgetState,
				setBudgetState,
				chartData,
				setChartData,
				activeView,
				setActiveView
			}}
		>
			{props.children}
		</BudgetContext.Provider>
	)
}

export default BudgetContextProvider
