import React, { useEffect, useContext } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { BudgetContext } from '../BudgetContext'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { Fragment } from 'react'

const PieChart = ({ tab }) => {
	const { palette } = useTheme()
	const { chartData, setChartData } = useContext(BudgetContext)
	const { allBudgets } = useContext(GlobalContext)
	const autoResize = useMediaQuery(({ breakpoints }) => breakpoints.down('lg'))

	useEffect(() => {
		setChartData({
			...chartData,
			options: {
				...chartData.options,
				chart: {
					...chartData.options.chart,
					foreColor: palette.text.primary,
					background: 'transparent'
				},
				colors: [palette.error.main, '#8536f5', palette.primary.main],
				theme: {
					mode: palette.mode
				}
			}
		})
		// eslint-disable-next-line
	}, [palette]) //Allows the effect to only run once on mount

	/** Gets the name of the tab's month */
	const getMonth = (month) => {
		const today = new Date()

		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1
		const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1

		switch (month) {
			case 'Current':
				return `${currentMonth}-${currentYear}`

			case 'Last':
				const lastYear = lastMonth === 12 ? currentYear - 1 : currentYear
				return `${lastMonth}-${lastYear}`

			case 'Next':
				const nextMonth = (currentMonth % 12) + 1
				const nextYear = nextMonth === 1 ? currentYear + 1 : currentYear
				return `${nextMonth}-${nextYear}`

			default:
				break
		}
	}

	/** Gets the name of the past month's budget */
	const getPastMonth = (last) => {
		const today = new Date()
		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1

		const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1
		const past2Month = lastMonth === 1 ? 12 : lastMonth - 1
		const past3Month = past2Month === 1 ? 12 : past2Month - 1

		switch (last) {
			case 1:
				const lastYear = lastMonth === 12 ? currentYear - 1 : currentYear
				return `${lastMonth}-${lastYear}`

			case 2:
				const past2Year = currentMonth - 2 <= 0 ? currentYear - 1 : currentYear
				return `${past2Month}-${past2Year}`

			case 3:
				const past3Year = currentMonth - 3 <= 0 ? currentYear - 1 : currentYear
				return `${past3Month}-${past3Year}`

			case 4:
				const past4Month = past3Month === 1 ? 12 : past3Month - 1
				const past4Year = currentMonth - 4 <= 0 ? currentYear - 1 : currentYear
				return `${past4Month}-${past4Year}`

			default:
				break
		}
	}

	/** Gets the number of the past budgets in the data */
	const howManyPastMonth = () => {
		let totalPastBudgets = 0
		for (let i = 1; i < 5; i++) {
			if (
				allBudgets.typeRatios.months[getPastMonth(i)] &&
				Object.entries(allBudgets.typeRatios.months[getPastMonth(i)]).length !== 0
			) {
				totalPastBudgets += 1
			}
		}

		if (totalPastBudgets === 4) {
			return 4
		} else if (totalPastBudgets > 0) {
			return 1
		} else {
			return 0
		}
	}

	return (
		<Fragment>
			{tab !== 'Past' &&
				allBudgets.typeRatios.months[getMonth(tab)] &&
				Object.entries(allBudgets.typeRatios.months[getMonth(tab)]).length !== 0 && (
					<ReactApexChart
						options={chartData.options}
						//+1 because 0s will not show data and it still keeps the proportions
						series={[
							allBudgets.typeRatios.months[getMonth(tab)].basics + 1,
							allBudgets.typeRatios.months[getMonth(tab)].wants + 1,
							allBudgets.typeRatios.months[getMonth(tab)].savings + 1
						]}
						type='pie'
						height={autoResize ? undefined : '100%'}
						width={autoResize ? undefined : '100%'}
					/>
				)}
			{tab !== 'Past' &&
				allBudgets.typeRatios.months[getMonth(tab)] &&
				Object.entries(allBudgets.typeRatios.months[getMonth(tab)]).length === 0 &&
				tab === 'Current' && (
					<ReactApexChart
						options={chartData.options}
						series={[1, 1, 3]}
						type='pie'
						height={autoResize ? undefined : '100%'}
						width={autoResize ? undefined : '100%'}
					/>
				)}
			{tab !== 'Past' &&
				allBudgets.typeRatios.months[getMonth(tab)] &&
				Object.entries(allBudgets.typeRatios.months[getMonth(tab)]).length === 0 &&
				tab !== 'Current' && (
					<Typography variant='h5' sx={{ width: '100%', paddingBottom: '20px', color: 'text.secondary' }}>
						No Budget
					</Typography>
				)}
			{tab === 'Past' && howManyPastMonth() === 4 && (
				<Grid container direction='column' sx={{ height: '100%', width: '100%' }}>
					<Grid item container direction='row'>
						<ReactApexChart
							options={chartData.options}
							series={[
								allBudgets.typeRatios.months[getPastMonth(1)].basics + 1,
								allBudgets.typeRatios.months[getPastMonth(1)].wants + 1,
								allBudgets.typeRatios.months[getPastMonth(1)].savings + 1
							]}
							type='pie'
							height='45%'
							width='50%'
						/>
						<ReactApexChart
							options={chartData.options}
							series={[
								allBudgets.typeRatios.months[getPastMonth(2)].basics + 1,
								allBudgets.typeRatios.months[getPastMonth(2)].wants + 1,
								allBudgets.typeRatios.months[getPastMonth(2)].savings + 1
							]}
							type='pie'
							height='45%'
							width='50%'
						/>
					</Grid>
					<Grid item container direction='row'>
						<ReactApexChart
							options={chartData.options}
							series={[
								allBudgets.typeRatios.months[getPastMonth(3)].basics + 1,
								allBudgets.typeRatios.months[getPastMonth(3)].wants + 1,
								allBudgets.typeRatios.months[getPastMonth(3)].savings + 1
							]}
							type='pie'
							height='45%'
							width='50%'
						/>
						<ReactApexChart
							options={chartData.options}
							series={[
								allBudgets.typeRatios.months[getPastMonth(4)].basics + 1,
								allBudgets.typeRatios.months[getPastMonth(4)].wants + 1,
								allBudgets.typeRatios.months[getPastMonth(4)].savings + 1
							]}
							type='pie'
							height='45%'
							width='50%'
						/>
					</Grid>
				</Grid>
			)}

			{/* Just Last Budget */}
			{tab === 'Past' && howManyPastMonth() === 1 && (
				<ReactApexChart
					options={chartData.options}
					//+1 because 0s will not show data and it still keeps the proportions
					series={[
						allBudgets.typeRatios.months[getPastMonth(1)].basics + 1,
						allBudgets.typeRatios.months[getPastMonth(1)].wants + 1,
						allBudgets.typeRatios.months[getPastMonth(1)].savings + 1
					]}
					type='pie'
					height={autoResize ? undefined : '100%'}
					width={autoResize ? undefined : '100%'}
				/>
			)}

			{/* No Past budgets */}
			{tab === 'Past' && howManyPastMonth() === 0 && (
				<Typography variant='h5' sx={{ width: '100%', paddingBottom: '20px', color: 'text.secondary' }}>
					No Budgets
				</Typography>
			)}
		</Fragment>
	)
}
export default PieChart
