import React, { useContext } from 'react'
import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { NewTransactionFormContext } from './NewTransactionFormContext'

const DateSelection = () => {
	const {
		transactionFormState: state,
		setTransactionFormState: setState,
		handleFormChange
	} = useContext(NewTransactionFormContext)

	/** Updating just the MM/dd/YYYY and not the time if possible */
	const handleDateChange = (date) => {
		let newDate

		if (date !== null && state.date !== null && !isNaN(state.date)) {
			//If the new and old dates are proper date objects: set the new date with the old time
			newDate = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate(),
				state.date.getHours(),
				state.date.getMinutes(),
				state.date.getSeconds()
			)
		} else if (date !== null) {
			//If the new date is defined but the old date is not: set the new date with the current time
			const now = new Date()
			newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
		} else {
			//Set the null date
			newDate = date
		}

		if (newDate !== null) {
			//If the date is not null make sure to remove the date error (when it got if it was null)
			let newErrors = { ...state.errors }
			delete newErrors.date
			setState({ ...state, date: newDate, errors: newErrors })
		} else {
			handleFormChange({ currentTarget: { name: 'date', value: newDate } })
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DatePicker
				disableFuture
				inputFormat='MM/dd/yyyy'
				value={state.date}
				PaperProps={{
					sx: { color: 'primary.main', backgroundColor: 'tile' }
				}}
				onChange={handleDateChange}
				renderInput={(props) => (
					<TextField
						{...props}
						variant='standard'
						color='primary'
						sx={{ width: '181px', color: '#10a0ff' }}
						label='Change Date'
						error={state.errors.date ? true : false}
						helperText={state.errors.date}
					/>
				)}
			/>
			{/* <DesktopDatePicker
				// disableToolbar
				// disableFuture
				inputFormat='MM/dd/yyyy'
				// margin='normal'
				renderInput={(props) => <TextField label='Change Date' helperText={state.errors.date} />}
				// value={state.date}
				onChange={handleDateChange}
				// error={state.errors.date ? true : false}
				sx={{ width: '181px' }}
				// KeyboardButtonProps={{
				// 	'aria-label': 'change date'
				// }}
				// PopoverProps={{
				// 	PaperProps: {
				// 		style: {
				// 			backgroundColor: palette.tile
				// 		}
				// 	}
				// }}
			/> */}
		</LocalizationProvider>
	)
}
export default DateSelection
