import { palette } from '@mikeint0sh/the-keeton-cloud-components/theme'

export const globalPalette = {
	black: palette.black,
	white: palette.white,
	mode: palette.mode,
	primary: {
		contrastText: palette.white,
		dark: palette.primary.dark,
		main: palette.primary.main,
		light: palette.primary.light
	},
	secondary: {
		contrastText: palette.secondary.white,
		dark: '#02E8BB',
		main: '#02E8BB',
		light: '#00A67A'
	},
	success: {
		contrastText: palette.white,
		dark: palette.success.dark,
		main: palette.success.main,
		light: palette.success.light
	},
	info: {
		contrastText: palette.white,
		dark: palette.info.dark,
		main: palette.info.main,
		light: palette.info.light
	},
	warning: {
		contrastText: palette.white,
		dark: palette.warning.dark,
		main: palette.warning.main,
		light: palette.warning.light
	},
	error: {
		contrastText: palette.white,
		dark: palette.error.dark,
		main: palette.error.main,
		light: palette.error.light
	},
	text: {
		primaryLight: palette.text.primaryLight,
		primaryDark: palette.text.primaryDark,
		secondaryLight: palette.text.secondaryLight,
		secondaryDark: palette.text.secondaryDark,
		link: palette.text.link
	},
	tile: {
		dark: palette.tile.dark, //'#242526'
		light: palette.tile.light
	},
	background: {
		dark: palette.background.dark,
		light: palette.background.light
	},
	menu: {
		dark: palette.menu.dark, //'#151516',
		light: palette.menu.light
	}
}
