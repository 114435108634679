import React from 'react'
import { styled } from '@mui/material/styles'
import ComingSoon from '../../ComingSoon'

const PREFIX = 'InvestTool'
const classes = {
	content: `${PREFIX}-content`
}

const Root = styled('div')(() => ({
	[`&.${classes.content}`]: {
		//Place in all minimal layouts
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	}
}))

export default function InvestTool() {
	return (
		<Root className={classes.content}>
			<ComingSoon />
		</Root>
	)
}
