import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Button, Typography, Grid, TextField, FormControlLabel, Checkbox, CircularProgress, useMediaQuery } from '@mui/material'
import { login as apiLogin } from '../../helpers/api'
import { validator } from '@mikeint0sh/the-keeton-cloud-components/utils'
import { GA4 } from '../../helpers'
import { GlobalContext } from '../../helpers/GlobalContext'

const PREFIX = 'Login'
const classes = {
	error: `${PREFIX}-error`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`,
	btnRoot: `${PREFIX}-btnRoot`
}

const Root = styled('form')(({ theme: { palette } }) => ({
	[`& .${classes.error}`]: {
		fontSize: '14px',
		color: palette.error.main,
		textAlign: 'center',
		marginTop: '5px'
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	},
	[`& .${classes.btnRoot}`]: {
		margin: 0,
		border: 0,
		paddingRight: 0,
		paddingLeft: 10,
		fontSize: 16,
		fontWeight: 400,
		textDecoration: 'underline',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent',
			textDecoration: 'underline'
		}
	}
}))

const Login = ({ state, setState, closePopup }) => {
	const navigate = useNavigate()
	const { setLoggedIn, addCredentials, useGA } = useContext(GlobalContext)
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })

	/**
	 * Validates email and password and sends api call to validate login and get user credentials if successful
	 * else it will set relevant errors to display to the user
	 */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, loading: true }) //To display spinner
		let result = {}

		const { valid, errors } = validator.validLogin({ email: state.email, password: state.password }) //Validates the user's info
		if (valid) {
			result = await apiLogin(state.email, state.password, state.rememberMe)
		} else {
			result.errors = errors
		}

		if (!result.errors) {
			addCredentials(result.data.details)
			setLoggedIn(true)
			closePopup()
			GA4.event(useGA, 'login', 'successful')
			navigate('/Dashboard') //Login successful
		} else {
			GA4.event(useGA, 'login', 'error', JSON.stringify(result.errors))
			setState({
				...state,
				errors: result.errors, //Set errors in local state
				loading: false //Stop loading spin weal
			})
		}
	}

	/** Updates the state when either email or password text felids change */
	const handleTextFelidChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	/** Updates the state when the rememberMe checkbox change */
	const handleCheckboxChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked
		})
	}

	/** Switches to create account view */
	const handleSignup = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		GA4.event(useGA, 'sign_up', 'unavailable')
		setState({ ...state, signup: true }) //To display the signup view and hide login
	}

	/** Switches to create account view */
	const forgotPassword = () => {
		setState({
			...state,
			login: false,
			forgotPassword: true
		})
	}

	return (
		<Root noValidate onSubmit={handleSubmit}>
			<TextField
				id='email'
				type='email'
				name='email'
				label='Email Address'
				value={state.email}
				onChange={handleTextFelidChange}
				helperText={state.errors.email}
				error={state.errors.email ? true : false}
				autoComplete='email'
				margin='normal'
				required
				fullWidth
				autoFocus
			/>
			<TextField
				id='password'
				type='password'
				name='password'
				label='Password'
				value={state.password}
				onChange={handleTextFelidChange}
				helperText={state.errors.password}
				error={state.errors.password ? true : false}
				autoComplete='current-password'
				margin='normal'
				required
				fullWidth
			/>
			<Grid container direction='row' alignItems='center' justifyContent='space-between'>
				<Grid item sm>
					<FormControlLabel
						control={
							<Checkbox
								name='rememberMe'
								value='remember'
								checked={state.rememberMe}
								onChange={handleCheckboxChange}
								color='primary'
							/>
						}
						label='Remember me'
					/>
				</Grid>
				<Grid item sm style={{ textAlign: 'right' }}>
					<Button color='primary' onClick={forgotPassword} className={classes.btnRoot}>
						Forgot Password
					</Button>
				</Grid>
			</Grid>

			{state.errors.general && (
				<Typography variant='body2' className={classes.error} align='center'>
					{state.errors.general}
				</Typography>
			)}

			<Grid container direction={isDesktop ? 'row' : 'column'} alignItems='center' justifyContent='center' spacing={1}>
				<Grid item xs={isDesktop ? 6 : 12} style={{ width: '100%' }}>
					<Button
						fullWidth
						variant='outlined'
						color='secondary'
						className={classes.submitBtn}
						style={{ fontSize: '16px' }}
						onClick={handleSignup}
					>
						Create Account
					</Button>
				</Grid>
				<Grid item xs={isDesktop ? 6 : 12} style={{ width: '100%' }}>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						disabled={state.loading}
						className={classes.submitBtn}
						style={{ fontSize: '16px', color: 'white' }}
					>
						Sign In
						{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
					</Button>
				</Grid>
			</Grid>
		</Root>
	)
}

export default Login
