import React from 'react'
import { Typography } from '@mui/material'

const Bills = () => {
	return (
		<Typography variant='h1' gutterBottom>
			Bills
		</Typography>
	)
}

export default Bills
