import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, IconButton, FormControl, Select, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Delete, Build } from '@mui/icons-material'
import { BudgetToolContext } from '../BudgetToolContext'

const PREFIX = 'CategoryToolBar'
const classes = {
	grid: `${PREFIX}-grid`,
	item: `${PREFIX}-item`
}

const GridStyled = styled(Grid)(() => ({
	[`&.${classes.grid}`]: {
		display: 'flex',
		padding: 0
	},
	[`& .${classes.item}`]: {
		paddingLeft: 3,
		paddingRight: 3
	}
}))

const CategoryToolBar = ({ type, console, index, amountType, amount, percentType, percentage, showToolBar, priority }) => {
	const { budgetState, removeCategory, didChange, toggleToolBar } = useContext(BudgetToolContext)

	return (
		<GridStyled item container direction='row' alignItems='center' className={classes.grid}>
			<Grid item>
				{/* Show/Hide ToolBar Button */}
				<IconButton
					sx={{ color: 'text.secondary', padding: '0px', paddingRight: '12px' }}
					onClick={() => toggleToolBar(type, index, showToolBar)}
					size='large'
				>
					<Build sx={{ width: '20px', height: '20px' }} />
				</IconButton>
			</Grid>

			<Grid item className={classes.item} sx={{ paddingLeft: '0px' }}>
				{/* amountType Toggle */}
				<ToggleButtonGroup
					size='small'
					name='amountType'
					value={amountType}
					exclusive
					onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
				>
					<ToggleButton name='amountType' value='finite'>
						Finite
					</ToggleButton>
					<ToggleButton name='amountType' value='percent'>
						Percent
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>

			{/* Only show the percentage type toggle if the user has chosen percent */}
			{amountType === 'percent' && (
				<Grid item className={classes.item} sx={{ paddingLeft: '19px' }}>
					<ToggleButtonGroup
						size='small'
						name='percentType'
						value={percentType}
						exclusive
						onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
					>
						<ToggleButton name='percentType' value='total'>
							Total
						</ToggleButton>
						<ToggleButton name='percentType' value='remaining'>
							Remaining
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			)}

			{/* Priority Drop Down */}
			{console && (
				<Grid item className={classes.item} sx={{ paddingLeft: '19px' }}>
					<FormControl variant='standard'>
						<Select
							native
							name='priority'
							value={priority === 0 ? 'First' : priority !== -1 ? priority : 'Last'}
							onChange={(e) => didChange(e, type, index, amountType, amount, percentType, percentage)}
							label='Priority'
							inputProps={{
								name: 'priority',
								id: 'outlined-age-native-simple'
							}}
						>
							{/* Priority title */}
							<option disabled value={99}>
								Priority
							</option>

							{/* Only shows First or Last if it is a percentage */}
							{amountType === 'percent' && (
								<option value={percentType === 'total' ? 0 : -1}>{percentType === 'total' ? 'First' : 'Last'}</option>
							)}

							{/* Shows all possible priority levels up to and including the next priority */}
							{amountType === 'finite' &&
								Array.apply(null, { length: budgetState.nextPriority }).map((data, i) => {
									return (
										<option value={i + 1} key={i}>
											{i + 1}
										</option>
									)
								})}
						</Select>
					</FormControl>
				</Grid>
			)}

			{/* Delete the Category */}
			<Grid item className={classes.item} sx={{ paddingLeft: '15px' }}>
				<IconButton
					aria-label='delete'
					onClick={(e) => removeCategory(type, index, amountType, percentType, amount, percentage)}
					name={amountType}
					sx={{ color: 'text.secondary', paddingLeft: '0px' }}
					size='large'
				>
					<Delete />
				</IconButton>
			</Grid>
		</GridStyled>
	)
}

export default CategoryToolBar
