import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Slide, useMediaQuery } from '@mui/material'
import { Footer } from '@mikeint0sh/the-keeton-cloud-components/components'
import { MKStepper } from '../../../components'
import Income from './Income'
import { BudgetToolContext } from './BudgetToolContext'

const PREFIX = 'Onboarding'
const classes = {
	content: `${PREFIX}-content`,
	root: `${PREFIX}-root`,
	rootFill: `${PREFIX}-rootFill`,
	slide: `${PREFIX}-slide`
}

const Root = styled('div')(() => ({
	[`& .${classes.content}`]: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.rootFill}`]: {
		height: '100%'
	},
	[`& .${classes.slide}`]: {
		position: 'absolute',
		width: '100%',
		zIndex: 1
	}
}))

const Onboarding = ({ console, showPrompt }) => {
	const { state, incomeState } = useContext(BudgetToolContext)
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })

	const getPages = () => {
		if (incomeState.incomeType === 'net') {
			return ['Type of Income', 'Create Budget']
		} else {
			return ['Type of Income', 'Taxes', 'Retirement', 'Health & Other Deductions', 'Create Budget']
		}
	}

	let pages = getPages()

	return (
		<Root className={classes.root}>
			<Slide
				className={classes.slide}
				style={{ height: `calc(100% - ${console ? '134px' : '64px'})` }} // marginTop: console ? '-114px' : '-64px'
				direction={state.page === 1 ? 'left' : 'right'}
				in={state.page === 1}
				timeout={800}
				mountOnEnter
				unmountOnExit
			>
				<div>
					<Income showPrompt={showPrompt} />
				</div>
			</Slide>

			<Grid container direction='column' justifyContent='flex-end' alignItems='center' style={{ height: '100%' }}>
				<MKStepper
					step={state.page}
					steps={pages}
					width={!isDesktop ? '95%' : incomeState.incomeType === 'net' ? '50%' : '75%'}
				/>
				{/* Main Layout has its own footer embedded */}
				<Footer style={{ zIndex: 100 }} />
			</Grid>
		</Root>
	)
}

export default Onboarding
