import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, Typography, Button, useTheme, Link } from '@mui/material'
import { GA4 } from '../../../../helpers'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'Promo'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`
}

const Root = styled('div')(({ theme: { palette } }) => ({
	[`&.${classes.root}`]: {
		flex: 1,
		display: 'flex',
		height: '100%',
		margin: 0,
		paddingTop: 35,
		paddingBottom: 53,
		paddingLeft: 15,
		paddingRight: 20
	},
	[`& .${classes.card}`]: {
		flex: 1,
		display: 'flex',
		minHeight: '100%',
		padding: 10,
		background:
			palette.mode === 'dark'
				? `linear-gradient(-45deg,  rgba(6,63,100,0.8), rgba(2,232,187,0.8))`
				: `linear-gradient(-45deg,  #10A0FF, #02e8bb, #00A67A)`
	},
	[`& .${classes.grid}`]: {
		flex: 1,
		minHeight: '100%'
	}
}))

const Promo = () => {
	const { palette } = useTheme()
	const { useGA } = useContext(GlobalContext)

	const signUp = () => {
		GA4.event(useGA, 'feedback_form', 'BudgetTool Promo')
	}

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='column' alignItems='center' className={classes.grid}>
					<Grid item sx={{ paddingTop: '40px' }}>
						<Typography variant='h2' sx={{ color: 'black', fontWeight: 200, paddingBottom: '10px' }}>
							Budget Beta
						</Typography>
					</Grid>
					<Grid item sx={{ flex: 1 }} />

					<Grid item sx={{ paddingLeft: '5px', paddingRight: '5px', paddingBottom: '40px', width: '100%' }}>
						<Link
							href='https://forms.gle/Uyp8oCcQAXmnX2aR6'
							target='_blank'
							rel='noopener'
							component='a'
							sx={{ textDecoration: 'none' }}
							underline='hover'
						>
							<Button
								fullWidth
								color='secondary'
								variant='contained'
								sx={{ fontSize: 16, color: palette.mode === 'dark' ? palette.black : palette.white }}
								onClick={signUp}
							>
								Leave Feedback
							</Button>
						</Link>
					</Grid>
					{/* <Grid item style={{ paddingTop: 50, width: 160, height: 600, backgroundColor: 'gray' }}>
						<Typography variant='h4' style={{ textAlign: 'center', paddingBottom: 20 }}>
							Ad
						</Typography>
					</Grid> */}
				</Grid>
			</Card>
		</Root>
	)
}

export default Promo
