import React, { createContext, useState } from 'react'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'
import { saveBudget as apiSaveBudget } from '../../../helpers/api'

export const BudgetToolContext = createContext()

const BudgetToolContextProvider = (props) => {
	//Initial state for Stats
	const [state, setState] = useState({
		page: 1,
		onboarding: true,
		useTemplate: false,
		showWarning: false,
		templateWarning: true,
		showAddCategoryButtons: '', //'': Don't show, basics/wants/savings: show for that category
		categoryToAdd: '',
		saving: false, //If the Budget is currently being saved via api to an account and spin weal is showing
		errors: {}
	})

	const [incomeState, setIncomeState] = useState({
		incomeType: 'net',
		income: 0,
		showAlert: false
	})

	const [budgetState, setBudgetState] = useState({
		finiteAmountBudgeted: 0, //The total integer currency amount of all categories with 'finite'
		overallTotalPercent: 0, //The total percentage of all categories with 'percent' and 'total'
		overallRemainingPercent: 0, //The total percentage of all categories with 'percent' and 'remaining'
		nextPriority: 4, //The next level priority
		// add date obj representing the month to allow for queries > or < the date

		basics: [
			{
				//Firestore - type = 'basics'
				name: '',
				accountID: '', //Stored in firestore as the name of the map
				spent: 0, //Current time period == spent so far; Past time period == spent during time period
				accountBalance: 0, //Current time period == current balance; Past time period == ending balance
				amountType: 'finite',
				amount: 0,
				amountFunded: 0,
				percentType: 'total',
				percentage: 0,
				priority: 1,
				subCategories: [] //Store as [[accountID, name, spent]]; and a map of maps in Firestore with accountID: name
			}
		],
		wants: [
			{
				name: '',
				accountID: '',
				spent: 0,
				accountBalance: 0,
				amountType: 'finite',
				amount: 0,
				amountFunded: 0,
				percentType: 'total',
				percentage: 0,
				priority: 3,
				subCategories: []
			}
		],
		savings: [
			{
				name: '',
				accountID: '',
				spent: 0,
				accountBalance: 0,
				amountType: 'finite',
				amount: 0,
				amountFunded: 0,
				percentType: 'total',
				percentage: 0,
				priority: 2,
				goal: 0
			}
		]
	})

	/** Removes the category from budget state when the delete btn is pressed */
	const removeCategory = (type, index, amountType, percentType, amount, percentage) => {
		let newFiniteAmountBudgeted = budgetState.finiteAmountBudgeted
		let newOverallTotalPercent = budgetState.overallTotalPercent
		let newOverallRemainingPercent = budgetState.overallRemainingPercent

		//If the Category being deleted is set to 'finite':
		if (amountType === 'finite') {
			newFiniteAmountBudgeted = budgetState.finiteAmountBudgeted - amount //Remove the old finite amount
		} else if (amountType === 'percent') {
			//Else the Category being deleted is set to 'percent':
			if (percentType === 'total') {
				newOverallTotalPercent = budgetState.overallTotalPercent - percentage //Remove the old percentage from the total
			} else if (percentType === 'remaining') {
				newOverallRemainingPercent = budgetState.overallRemainingPercent - percentage //Remove the old percentage from the remaining
			} else {
				console.error(`Error: removeCategory() - percentType is incorrect: ${percentType}`)
			}
		} else {
			console.error(`Error: removeCategory() - amountType is incorrect: ${amountType}`)
		}

		let categories = []
		if (budgetState[type][index].accountID === '') {
			//The category being deleted hasn't been saved in the db
			categories = [
				...budgetState[type].slice(0, index),
				//No need to flag to delete form db
				...budgetState[type].slice(index + 1)
			]
		} else {
			//The category being deleted is saved in the db
			categories = [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					name: undefined //Flag that this category will be deleted
				},
				...budgetState[type].slice(index + 1)
			]
		}

		setBudgetState({
			...budgetState,
			finiteAmountBudgeted: newFiniteAmountBudgeted,
			overallTotalPercent: newOverallTotalPercent,
			overallRemainingPercent: newOverallRemainingPercent,
			[type]: categories
		})
	}

	/** Hides and shows the toolbar for each category */
	const toggleToolBar = (type, index, showToolBar) => {
		setBudgetState({
			...budgetState,

			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					showToolBar: !showToolBar //Toggle the tool bar
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	/** Run from Category/CategoryToolBar whenever an onChange is called*/
	const didChange = (event, type, index, amountType, amount, percentType, percentage) => {
		const eventName = event.currentTarget.name
		const eventValue = event.currentTarget.value

		if (eventName === 'amountType') {
			//amountType Toggle Button has changed
			amountTypeToggle(type, index, eventName, eventValue, amount, percentType, percentage)
		} else if (eventName === 'percentType') {
			//percentType Toggle Button has changed
			percentTypeToggle(type, index, eventName, eventValue, percentage)
		} else if (eventName === 'amount') {
			//Currency Text Felid has changed
			amountChanged(eventName, eventValue, type, index, amountType, amount)
		} else if (eventName === 'percentage') {
			//Percentage Text Felid has changed
			percentChanged(eventName, eventValue, type, index, percentType, percentage)
		} else if (eventName === 'priority') {
			//Priority drop down has changed
			priorityChanged(eventName, eventValue, type, index)
		}
	}

	const amountTypeToggle = (type, index, toggleName, value, amount, percentType, percentage) => {
		if (budgetState[type][index].amountType === value) {
			return //User clicked on the already selected tab: don't change any values
		} //Else the amount type has changed

		let totalValue //Currency value to get percentage of
		let oldAmount //The old currency value of the category
		let newAmount //The new currency value for the category
		let newFiniteAmountBudgeted //The new overall currency value of all 'finite' categories in the budget
		let overallTotalRemainingPercent //Holds either the 'overallTotalPercent' OR 'overallRemainingPercent' object to update budgetState with
		let newPriority //The new priority value base on if its changing to 'finite' or 'percent'

		if (percentType === 'total') {
			totalValue = incomeState.income
		} else if (percentType === 'remaining') {
			//Get the old currency amount remaining before the percentage was taken out
			const oldOverallPercentTotalAmount = Math.round((budgetState.overallTotalPercent / 100) * incomeState.income) //Old currency value of: the all categories with the 'total' percent type
			let oldOverallPercentRemainingAmount = Math.round(
				(incomeState.income - budgetState.finiteAmountBudgeted - oldOverallPercentTotalAmount) *
					(budgetState.overallRemainingPercent / 100)
			) //Old currency value of: the all categories with the 'remaining' percent type
			if (oldOverallPercentRemainingAmount < 0) {
				//There can't be a negative remaining amount: if00 it is negative than the budget is already full
				oldOverallPercentRemainingAmount = 0 //Set the remaining amount to 0
			}
			const oldTotalBudgetedAmount =
				budgetState.finiteAmountBudgeted + oldOverallPercentTotalAmount + oldOverallPercentRemainingAmount //Old currency value of: the total of all categories
			const oldPercentAmount = Math.round((percentage / 100) * oldOverallPercentRemainingAmount) //Old currency value of category
			const newStillAvailableAmount = incomeState.income - oldTotalBudgetedAmount + oldPercentAmount //New 'Still Available' currency value BEFORE new percentage amount taken out

			totalValue = newStillAvailableAmount
		} else {
			console.error(`Error - amountTypeToggle(): percentType is incorrect: ${percentType}`)
		}

		//Changing from percent to finite
		if (value === 'finite') {
			oldAmount = (percentage / 100) * totalValue
			newAmount = amount
			newPriority = type === 'basics' ? 1 : type === 'wants' ? 3 : 2 //Default priority values

			if (percentType === 'total') {
				const newOverallTotalPercentage = budgetState.overallTotalPercent - percentage //Get the new Overall Percentage value for 'Total'
				overallTotalRemainingPercent = { overallTotalPercent: newOverallTotalPercentage }
			} else if (percentType === 'remaining') {
				const newOverallRemainingPercentage = budgetState.overallRemainingPercent - percentage //Get the new Overall Percentage value for 'Remaining'
				overallTotalRemainingPercent = { overallRemainingPercent: newOverallRemainingPercentage }
			}
			newFiniteAmountBudgeted = budgetState.finiteAmountBudgeted + newAmount
		} else if (value === 'percent') {
			//Changing from finite to percent
			oldAmount = amount
			newAmount = (percentage / 100) * totalValue

			if (percentType === 'total') {
				const newOverallTotalPercentage = budgetState.overallTotalPercent + percentage //Get the new Overall Percentage value for 'Total'
				overallTotalRemainingPercent = { overallTotalPercent: newOverallTotalPercentage }
				newPriority = 0 //First
			} else if (percentType === 'remaining') {
				const newOverallRemainingPercentage = budgetState.overallRemainingPercent + percentage //Get the new Overall Percentage value for 'Remaining'
				overallTotalRemainingPercent = { overallRemainingPercent: newOverallRemainingPercentage }
				newPriority = -1 //Last
			}
			newFiniteAmountBudgeted = budgetState.finiteAmountBudgeted - oldAmount
		}

		setBudgetState({
			...budgetState,
			...overallTotalRemainingPercent,
			finiteAmountBudgeted: newFiniteAmountBudgeted,

			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					[toggleName]: value, //Sets the new value for the toggle button
					priority: newPriority //Sets the new priority value for the category
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	const percentTypeToggle = (type, index, toggleName, value, percentage) => {
		if (budgetState[type][index].percentType === value) {
			return //User clicked on the already selected tab: don't change any values
		} //Else the percent type has changed

		let newPercentage = {} //Holds the percentage key/value pair to update budgetState with if it needs to change
		let newOverallTotalPercentage //Holds the new overall 'total' percent
		let newOverallRemainingPercentage //Holds the new overall 'remaining' percent
		let newPriority //The new priority value base on if its changing to 'finite' or 'percent'

		//Changing from 'remaining' to 'total'
		if (value === 'total') {
			if (budgetState.overallTotalPercent + percentage > 100) {
				//If switching the percentType pushes the total % over 100:
				newPercentage = { percentage: 0 } //Set the Category to 0% to start
			} else {
				newOverallTotalPercentage = budgetState.overallTotalPercent + percentage //Get the new Overall 'Total' Percentage value for
			}
			newOverallRemainingPercentage = budgetState.overallRemainingPercent - percentage //Get the new Overall 'Remaining' Percentage value for
			newPriority = 0 //First
		} else if (value === 'remaining') {
			if (budgetState.overallRemainingPercent + percentage > 100) {
				//If switching the percentType pushes the total % over 100:
				newPercentage = { percentage: 0 } //Set the Category to 0% to start
			} else {
				newOverallRemainingPercentage = budgetState.overallRemainingPercent + percentage //Get the new Overall 'Remaining' Percentage value for
			}
			newOverallTotalPercentage = budgetState.overallTotalPercent - percentage //Get the new Overall 'Total' Percentage value for
			newPriority = -1 //Last
		} else {
			console.error(`Error - percentTypeToggle(): percentType is incorrect: ${value}`)
		}

		//Updates changed overallRemainingPercent and/or newOverallTotalPercentage values
		let newOverallTotalRemainingPercentage = {}
		if (newOverallRemainingPercentage !== undefined) {
			newOverallTotalRemainingPercentage = { overallRemainingPercent: newOverallRemainingPercentage }
		}
		if (newOverallTotalPercentage !== undefined) {
			newOverallTotalRemainingPercentage = {
				...newOverallTotalRemainingPercentage,
				overallTotalPercent: newOverallTotalPercentage
			}
		}

		setBudgetState({
			...budgetState,
			...newOverallTotalRemainingPercentage,

			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					[toggleName]: value, //Set the new value for the percentType toggle button
					...newPercentage, //Set the new percentage value if it has updated
					priority: newPriority //Sets the new priority value for the category
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	const amountChanged = (eventName, eventValue, type, index, amountType, amount) => {
		if (amountType === 'finite') {
			const newValue = accounting.unformat(eventValue) //Get the integer representation of the new amount
			const oldValue = amount //Get the previous amount before the change

			setBudgetState({
				...budgetState,
				//total = (total - oldValue) + newValue
				finiteAmountBudgeted: budgetState.finiteAmountBudgeted - oldValue + newValue, //Update the new total finite amount in the budget

				[type]: [
					...budgetState[type].slice(0, index),
					{
						...budgetState[type][index],
						[eventName]: newValue //Set the new value
					},
					...budgetState[type].slice(index + 1)
				]
			})
		} else {
			console.error(`Error - amountChanged() '${amountType}' is not finite`)
		}
	}

	const percentChanged = (eventName, eventValue, type, index, percentType, percentage) => {
		const oldPercentage = budgetState[type][index].percentage //Get the previous percentage before the change
		let newPercentage = accounting.unformat(eventValue) //Get the integer representation of the new percentage -  removes % symbol
		newPercentage = newPercentage / 100 //Percentage amount: undoes00 the 2 extra zeros from accounting.unformat() that gives integer currency

		let newOverallPercentage //Either overallTotalPercent OR overallRemainingPercent
		let overallTotalRemainingPercent //Holds either the 'overallTotalPercent' OR 'overallRemainingPercent' object to update budgetState with

		if (percentType === 'total') {
			newOverallPercentage = budgetState.overallTotalPercent - oldPercentage + newPercentage //Get the new Overall Percentage value for 'Total'
			overallTotalRemainingPercent = { overallTotalPercent: newOverallPercentage } //Object to update budgetState with the new value for overallTotalPercent
		} else if (percentType === 'remaining') {
			newOverallPercentage = budgetState.overallRemainingPercent - oldPercentage + newPercentage //Get the new Overall Percentage value for 'Remaining'
			overallTotalRemainingPercent = { overallRemainingPercent: newOverallPercentage } //Object to update budgetState with the new value for overallRemainingPercent
		} else {
			console.error(`Error - percentChanged(): percentType is incorrect: ${percentType}`)
		}
		if (newOverallPercentage > 100) {
			//Don't allow and save any values above 100%
			return
		}

		setBudgetState({
			...budgetState,
			...overallTotalRemainingPercent,

			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					[eventName]: newPercentage //Set the new value
				},
				...budgetState[type].slice(index + 1)
			]
		})
		//return newPercentage
	}

	const priorityChanged = (eventName, eventValue, type, index) => {
		let newPriority = parseInt(eventValue)

		setBudgetState({
			...budgetState,
			nextPriority: newPriority === budgetState.nextPriority ? budgetState.nextPriority + 1 : budgetState.nextPriority,

			[type]: [
				...budgetState[type].slice(0, index),
				{
					...budgetState[type][index],
					[eventName]: newPriority
				},
				...budgetState[type].slice(index + 1)
			]
		})
	}

	/**
	 * Returns the currency value for categories based on percentages
	 * Called when the amount of income or Still Available amount has changed
	 */
	const getPercentage = (percentType, percentage) => {
		let percentAmount //Currency value of the percent in this category (of either 'total' income or 'remaining' income)

		if (percentType === 'total') {
			percentAmount = Math.round((percentage / 100) * incomeState.income) //Currency value of category (% of income)
		} else if (percentType === 'remaining') {
			const overallPercentTotalAmount = Math.round((budgetState.overallTotalPercent / 100) * incomeState.income) //Old currency value of: the all categories with the 'total' percent type
			//Currency value of: the all categories with the 'remaining' percent type
			let overallPercentRemainingAmount = Math.round(
				(incomeState.income - budgetState.finiteAmountBudgeted - overallPercentTotalAmount) *
					(budgetState.overallRemainingPercent / 100)
			)
			if (overallPercentRemainingAmount < 0) {
				//There can't be a negative remaining amount: if00 it is negative than the budget is already full
				return 0 //Return 0: There is no more among remaining to take a percentage of
			}
			const totalBudgetedAmount = budgetState.finiteAmountBudgeted + overallPercentTotalAmount //Currency value of: the total of all categories EXCEPT 'percent' 'remaining' categories
			const originalTotalStillAvailableAmount = incomeState.income - totalBudgetedAmount //Currency value of: the amount 'Still available' BEFORE 'percent' 'remaining' categories are taken out
			percentAmount = Math.round((percentage / 100) * originalTotalStillAvailableAmount) //Currency value of category
		} else {
			console.error(`Error - getPercentage(): percentType is incorrect: ${percentType}`)
		}
		return percentAmount //Returns the currency value for the percentage of a category
	}

	/** Calculates the total monetary amount budgeted */
	const calculateTotalBudgetedAmount = () => {
		//Get the currency amount remaining
		const overallPercentTotalAmount = Math.round((budgetState.overallTotalPercent / 100) * incomeState.income) //Currency value of: the all categories with the 'total' percent type
		let overallPercentRemainingAmount = Math.round(
			(incomeState.income - budgetState.finiteAmountBudgeted - overallPercentTotalAmount) *
				(budgetState.overallRemainingPercent / 100)
		) // currency value of: the all categories with the 'remaining' percent type
		if (overallPercentRemainingAmount < 0) {
			//There can't be a negative remaining amount: if00 it is negative than the budget is already full
			overallPercentRemainingAmount = 0 //Set the remaining amount to 0
		}
		const totalBudgetedAmount = budgetState.finiteAmountBudgeted + overallPercentTotalAmount + overallPercentRemainingAmount //Currency value of: the total of all categories
		return totalBudgetedAmount
	}

	/** Calculates the percent of the budget a given category takes up */
	const calculatePercentage = (type) => {
		const totalBudgetedAmount = calculateTotalBudgetedAmount()
		if (totalBudgetedAmount === 0) {
			//This will happen if the income is also set to 0 as the user is editing
			return 0 //To prevent NaN - division by zero
		}

		let totalAmount = 0
		budgetState[type].forEach((category) => {
			if (category.amountType === 'finite') {
				totalAmount += category.amount
			} else if (category.amountType === 'percent') {
				totalAmount += getPercentage(category.percentType, category.percentage)
			}
		})

		return Math.round((totalAmount / totalBudgetedAmount) * 100)
	}

	/** Helps saveBudget() check that every category contains a name */
	const checkCategoryNames = () => {
		let errors = {}
		//If name is undefined it was marked for deletion
		budgetState.basics.forEach((category) => {
			if (category.name !== undefined && category.name.trim() === '') {
				errors.general = 'Please fill in a name for every category'
			}
		})
		budgetState.wants.forEach((category) => {
			if (category.name !== undefined && category.name.trim() === '') {
				errors.general = 'Please fill in a name for every category'
			}
		})
		budgetState.savings.forEach((category) => {
			if (category.name !== undefined && category.name.trim() === '') {
				errors.general = 'Please fill in a name for every category'
			}
		})
		const valid = Object.entries(errors).length === 0

		return { valid, errors }
	}

	const saveBudget = async (name, type, onboarding) => {
		if (calculateIncomeStillAvailable() !== 0) {
			setState({
				...state,
				saving: false,
				//Errors displayed to the user
				errors: {
					general:
						"You haven't budgeted all of your income yet. Add the remaining amount to the categories in your budget before saving."
				}
			})

			return { errors: { general: 'Budget not complete' } } //Errors not displayed to the user
		}

		//Check that every category contains a name
		const { valid, errors } = checkCategoryNames()
		if (!valid) {
			setState({
				...state,
				saving: false,
				//Errors displayed to the user
				errors: errors
			})

			return { errors: { general: 'Budget not complete' } } //Errors not displayed to the user
		}

		setState({ ...state, saving: true, errors: {} }) //Else set state to saving
		let result
		if (type === 'current' || type === 'next') {
			//Save budget to the database: name of budget, type, local budget state, and typeRatio percentages
			result = await apiSaveBudget(
				name,
				type,
				budgetState,
				{
					basics: calculatePercentage('basics'),
					wants: calculatePercentage('wants'),
					savings: calculatePercentage('savings')
				},
				onboarding
			)
		} else {
			//  ToDo: WhatIfs
			//  Need to edit the apiSaveBudget to work
			//result = await apiSaveBudget(name, type, budgetState) //Save budget to the database

			result = {}
		}

		if (!result.errors) {
			setState({ ...state, errors: {} }) //Else set state to saving
		} else {
			setState({ ...state, saving: false, errors: result.errors }) //Else set state to saving
		}

		return result
	}

	const calculateIncomeStillAvailable = () => {
		const overallPercentTotalAmount = Math.round((budgetState.overallTotalPercent / 100) * incomeState.income) //Currency value of: the all categories with the 'total' percent type
		let overallPercentRemainingAmount = Math.round(
			(incomeState.income - budgetState.finiteAmountBudgeted - overallPercentTotalAmount) *
				(budgetState.overallRemainingPercent / 100)
		) //Currency value of: the all categories with the 'remaining' percent type
		if (overallPercentRemainingAmount < 0) {
			//There can't be a negative remaining amount: if it is negative than the budget is already full
			overallPercentRemainingAmount = 0 //Set the remaining amount to 0
		}
		const totalBudgetedAmount = budgetState.finiteAmountBudgeted + overallPercentTotalAmount + overallPercentRemainingAmount //Currency value of: the total of all categories
		const incomeStillAvailable = incomeState.income - totalBudgetedAmount //Currency value of: the total amount still available

		return incomeStillAvailable
	}

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<BudgetToolContext.Provider
			value={{
				state,
				setState,
				incomeState,
				setIncomeState,
				budgetState,
				setBudgetState,
				removeCategory,
				getPercentage,
				didChange,
				percentChanged,
				toggleToolBar,
				calculateTotalBudgetedAmount,
				calculatePercentage,
				saveBudget
			}}
		>
			{props.children}
		</BudgetToolContext.Provider>
	)
}

export default BudgetToolContextProvider
