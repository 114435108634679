import React, { useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { Popup } from '../../components'
import { Overview, AddAccount } from './Overview'
import { AccountView } from './AccountView'
import { GlobalContext } from '../../helpers/GlobalContext'
import { AccountsContext } from './AccountsContext'
import { useAccounts } from '@mikeint0sh/the-keeton-cloud-components/finance'
import { getAccounts as apiGetAccounts } from '../../helpers/api'

const Root = styled('div')(() => ({
	//Content: Place in all minimal layouts
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1 //Pushes Footer to bottom
}))

const Accounts = () => {
	const { fetchAccounts } = useAccounts('fetchAccounts', apiGetAccounts, GlobalContext)
	const { incomeSources, setIncomeSources } = useContext(GlobalContext)
	const { accountState, setAccountState } = useContext(AccountsContext)

	//Fetches account data on first load
	useEffect(() => {
		fetchAccounts(false, incomeSources, setIncomeSources) //Fetches the user's accounts if their not in local state
		// eslint-disable-next-line
	}, [])

	return (
		<Root>
			{/* Create Physical account Popup */}
			<Popup
				isOpen={accountState.showAccountPopup}
				close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountPopup: false })}
				title='New Physical Account'
			>
				<AddAccount close={() => setAccountState({ ...accountState, popupTriggeredData: undefined, showAccountPopup: false })} />
			</Popup>

			<Grid container justifyContent='flex-start'>
				{accountState.activeView === 'Overview' && <Overview />}
				{accountState.activeView === 'AccountView' && <AccountView />}
			</Grid>
		</Root>
	)
}

export default Accounts
