import React, { useContext, Fragment, useState } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Grid, Button, Typography } from '@mui/material'
import { BudgetContext } from '../BudgetContext'
import { GlobalContext } from '../../../helpers/GlobalContext'
import { Alert } from '../../../components/Alert'

const PREFIX = 'MenuBar'
const classes = {
	appBar: `${PREFIX}-appBar`,
	toolbar: `${PREFIX}-toolbar`,
	menuBtn: `${PREFIX}-menuBtn`,
	menuText: `${PREFIX}-menuText`
}

const AppBarStyled = styled(AppBar)(({ theme: { palette, breakpoints } }) => ({
	[`&.${classes.appBar}`]: {
		boxShadow: 'none',
		zIndex: 1000, //Above the Site Alert but it will move down for it
		backgroundColor: palette.menu,
		flexGrow: 1,
		display: 'flex'
	},
	[`& .${classes.toolbar}`]: {
		color: palette.secondary,
		backgroundColor: palette.menu //Fixes root background color being the wrong color
	},
	[`& .${classes.menuBtn}`]: {
		border: 0,
		margin: 0,
		paddingLeft: 10,
		paddingRight: 10,
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent'
		}
	},
	[`& .${classes.menuText}`]: {
		fontWeight: 300,
		paddingTop: 2,
		fontSize: 19,
		'&:hover': {
			borderBottom: `1px solid`,
			color: palette.secondary.main,
			borderBottomColor: palette.secondary.main,
			marginBottom: -1
		}
	}
}))

const MenuBar = (props) => {
	const { credentials } = useContext(GlobalContext)
	const { budgetState, setBudgetState } = useContext(BudgetContext)
	const [showAlert, setShowAlert] = useState({
		shown: false,
		tab: 'Overview'
	})

	/** Updates the state when the text felid changes */
	const handleMenuChoice = (event) => {
		const name = event.currentTarget.name
		if (budgetState.editing) {
			setShowAlert({
				shown: true,
				tab: name
			})
		} else {
			setBudgetState({
				...budgetState,
				tab: name //Set the new value
			})
		}
	}

	return (
		<AppBarStyled
			component='menu'
			position='static'
			className={classes.appBar}
			sx={{ height: { xs: props.height - 10, sm: props.height } }}
		>
			<Alert
				open={showAlert.shown}
				title='Save Budget?'
				description='You are currently editing a budget, are you sure you want to exit without saving?'
				handleAlert={() =>
					setShowAlert({
						shown: false,
						tab: ''
					})
				}
				actionBtn="Don't save"
				handleActionBtn={() => {
					//Dismiss EditBudgetView and switch tabs
					setBudgetState({
						...budgetState,
						editing: false,
						editingTransition: false,
						mainViewTransition: false,
						tab: showAlert.tab //Set the new tab
					})

					setShowAlert({
						shown: false,
						tab: ''
					})
				}}
			/>
			<Toolbar
				component='div'
				variant='dense'
				disableGutters={true}
				className={classes.toolbar}
				sx={{ height: { xs: props.height - 10, sm: props.height } }}
			>
				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='Overview'>
						<Typography
							color={budgetState.tab === 'Overview' ? 'secondary' : 'textPrimary'}
							className={classes.menuText}
							variant='h4'
						>
							Overview
						</Typography>
					</Button>

					{!credentials.onboarding && (
						<Fragment>
							<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='Budget Summaries'>
								<Typography
									color={budgetState.tab === 'Budget Summaries' ? 'secondary' : 'textPrimary'}
									className={classes.menuText}
									variant='h4'
								>
									Budget Summaries
								</Typography>
							</Button>
							<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='What If'>
								<Typography
									color={budgetState.tab === 'What If' ? 'secondary' : 'textPrimary'}
									className={classes.menuText}
									variant='h4'
								>
									What If
								</Typography>
							</Button>
						</Fragment>
					)}
				</Grid>
			</Toolbar>
		</AppBarStyled>
	)
}

export default MenuBar
