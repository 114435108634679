import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, useMediaQuery } from '@mui/material'
import { BudgetTypeTile, IncomeTile, InfoTile } from './'
import { FixedSideBar } from '../../../../components'
import Promo from './Promo'

const PREFIX = 'CreateBudget'
const classes = {
	gridItem: `${PREFIX}-gridItem`,
	budget: `${PREFIX}-budget`,
	mobileFloatTop: `${PREFIX}-mobileFloatTop`
}

const GridStyledRoot = styled(Grid)(({ theme: { breakpoints } }) => ({
	[`&.${classes.budget}`]: {
		paddingRight: 10,
		[breakpoints.down('md')]: {
			paddingRight: 0,
			paddingLeft: 15,
			marginTop: 0
		}
	},
	[`&. ${classes.gridItem}`]: {
		margin: 0,
		display: 'flex'
	}
}))

const GridMobileFloatTop = styled(Grid)(({ theme: { palette } }) => ({
	margin: 0,
	display: 'flex',
	position: 'fixed',
	top: 0,
	right: 0,
	height: 100,
	paddingLeft: 15,
	paddingRight: 15,
	flexGrow: 1,
	zIndex: 10,
	backgroundColor: palette.background
}))

const GridStyled = styled(Grid)(() => ({
	margin: 0,
	display: 'flex'
}))

const CreateBudget = ({ console }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const isLg = useMediaQuery(({ breakpoints }) => breakpoints.up('lg'), { defaultMatches: true })
	const isSm = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'), { defaultMatches: true })

	const EditBudgetTool = (
		<FixedSideBar side='right' width={console ? (isLg ? 345 : 300) : '25%'}>
			<GridStyled item container direction='column' style={{ marginTop: console ? 50 + 20 : 19, paddingLeft: 5 }}>
				<GridStyled item container style={{ padding: isDesktop ? 16 : -10 }}>
					<IncomeTile />
				</GridStyled>
				<GridStyled item container style={{ padding: 16, minHeight: '100%', flex: 1 }}>
					<InfoTile console={console} />
				</GridStyled>
			</GridStyled>
			<GridStyledRoot container justifyContent='center' className={classes.budget}>
				<Grid
					item
					container
					direction='column'
					xs={12}
					className={classes.gridItem}
					style={{ paddingRight: !isDesktop ? 15 : console ? 0 : 50, marginTop: console ? 134 - 3 : 80 }} //-3 why this works?
				>
					{!isDesktop && (
						<Fragment>
							<GridMobileFloatTop item container style={{ marginTop: console ? (isSm ? 104 : 114) : 95 }}>
								<IncomeTile />
							</GridMobileFloatTop>
							<Grid
								item
								container
								className={classes.gridItem}
								style={{ marginTop: console ? (isSm ? 210 : 230) : isSm ? 240 : 250, paddingBottom: 0, flex: 1 }}
							>
								<InfoTile console={console} />
							</Grid>
						</Fragment>
					)}

					<Grid item sx={{ paddingBottom: '30px' }}>
						<BudgetTypeTile type='Basics' console={console} />
					</Grid>
					<Grid item sx={{ paddingBottom: '30px' }}>
						<BudgetTypeTile type='Wants' console={console} />
					</Grid>
					<Grid item sx={{ paddingBottom: 0 }}>
						<BudgetTypeTile type='Savings' console={console} />
					</Grid>
				</Grid>
			</GridStyledRoot>
		</FixedSideBar>
	)

	return (
		<Fragment>
			{console && EditBudgetTool}
			{!console && (
				<FixedSideBar side='left' width={220}>
					<Promo />
					{EditBudgetTool}
				</FixedSideBar>
			)}
		</Fragment>
	)
}

export default CreateBudget
