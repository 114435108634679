import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Slide, useMediaQuery } from '@mui/material'
import Onboarding from './Onboarding'
import { CreateBudget } from './CreateBudget'
import { Footer } from '@mikeint0sh/the-keeton-cloud-components/components'
import { BudgetToolContext } from './BudgetToolContext'

const PREFIX = 'BudgetTool'
const classes = {
	content: `${PREFIX}-content`,
	root: `${PREFIX}-root`,
	slide: `${PREFIX}-slide`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		//Place in all minimal layouts
		flex: 1,

		display: 'flex', //Added for console
		minHeight: '100%' //Added for console
	},
	[`&.${classes.content}`]: {
		//Place in all minimal layouts
		display: 'flex',
		height: '100%',

		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	[`& .${classes.slide}`]: {
		position: 'absolute',
		width: '100%',
		height: '100%'
	}
}))

const BudgetTool = ({ console, showPrompt, editMode }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { state } = useContext(BudgetToolContext)

	return (
		<Root className={console ? classes.root : classes.content}>
			{state.onboarding && <Onboarding console={console} showPrompt={showPrompt} />}

			{!state.onboarding && (
				<Slide
					className={classes.slide}
					//Margin to adjust for the menubar/ the topBar in the console

					// style={{ zIndex: 15, marginTop: console ? -134 : -64, paddingTop: console ? 0 : 0 }}
					style={{ zIndex: 15, marginTop: console ? -134 : -64, paddingTop: console ? 0 : 0 }}
					direction={editMode ? 'left' : 'up'}
					in={!state.onboarding}
					timeout={800}
					mountOnEnter
					unmountOnExit
				>
					<div
						className={classes.root}
						style={
							console
								? {
										flex: 1,
										// paddingLeft: isDesktop ? 20 : 10,
										paddingRight: isDesktop ? 0 : 10
								  }
								: { paddingLeft: 10, paddingRight: 10 }
						}
					>
						<CreateBudget console={console} />
						<Footer sx={{ zIndex: 100 }} />
					</div>
				</Slide>
			)}
		</Root>
	)
}

export default BudgetTool
