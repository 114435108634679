import { useState } from 'react'
import { darkPalette } from './palette/darkPalette'
import { lightPalette } from './palette/lightPalette'
import { darkTypography } from './typography/darkTypography'
import { lightTypography } from './typography/lightTypography'
import {
	darkComponentOverrides,
	lightComponentOverrides
} from '@mikeint0sh/the-keeton-cloud-components/theme/component-overrides'

const darkThemeObject = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 550,
			md: 840,
			lg: 1200,
			xl: 1920
		}
	},
	palette: darkPalette,
	typography: darkTypography,
	components: darkComponentOverrides,
	zIndex: {
		appBar: 1200,
		drawer: 1100
	}
}
const lightThemeObject = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 550,
			md: 840,
			lg: 1200,
			xl: 1920
		}
	},
	palette: lightPalette,
	typography: lightTypography,
	components: lightComponentOverrides,
	zIndex: {
		appBar: 1200,
		drawer: 1100
	}
}

/** Create a use dark mode Hook */
const useCustomTheme = () => {
	const [theme, setTheme] = useState(darkThemeObject)

	const toggleDarkMode = () => {
		if (theme.palette.mode === 'dark') {
			setTheme(lightThemeObject)
		} else {
			setTheme(darkThemeObject)
		}
	}
	return [theme, toggleDarkMode]
}

export default useCustomTheme
