import React from 'react'
import { styled } from '@mui/material/styles'
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material'
import { stepConnectorClasses } from '@mui/material/StepConnector'
import Check from '@mui/icons-material/Check'
import clsx from 'clsx'

const PREFIX = 'MKStepper'
const classes = {
	root: `${PREFIX}-root`,
	active: `${PREFIX}-active`,
	circle: `${PREFIX}-circle`,
	completed: `${PREFIX}-completed`
}

/** Styles for the circle/check mark in the Stepper */
const StyledStep = styled(Step)(({ theme: { palette } }) => ({
	[`& .${classes.root}`]: {
		color: `${palette.primary.main}`,
		display: 'flex',
		height: 22,
		alignItems: 'center'
	},
	[`& .${classes.active}`]: {
		color: `${palette.secondary.main}`
	},
	[`& .${classes.circle}`]: {
		width: 20,
		height: 20,
		borderRadius: '50%',
		backgroundColor: 'currentColor'
	},
	[`& .${classes.completed}`]: {
		width: 30,
		height: 30,
		color: `${palette.secondary.main}`,
		zIndex: 1
	}
}))

/** Determines what icon to show base on if not active/active/completed */
function StepIcon({ active, completed }) {
	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active
			})}
		>
			{completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	)
}

/** Custom MUI Stepper with on-brand color scheme, along with a custom width */
const MKStepper = ({ step, steps, width }) => {
	//Styles for the lines in between the steps
	const ColorLibraryConnector = styled(StepConnector)(({ theme: { palette } }) => ({
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundImage: `linear-gradient( -45deg, ${palette.secondary.main}, ${palette.primary.main})`
			}
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				backgroundColor: `${palette.secondary.main}`
			}
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor: `${palette.primary.main}`,
			borderRadius: 1
		}
	}))

	return (
		<Stepper alternativeLabel connector={<ColorLibraryConnector />} activeStep={step - 1} style={{ zIndex: 100, width: width }}>
			{steps.map((label) => (
				<StyledStep key={label}>
					<StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
				</StyledStep>
			))}
		</Stepper>
	)
}
export default MKStepper
